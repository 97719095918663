import React from "react";
import {Typography} from "@material-tailwind/react";
import TestimonialSection from "components/TestimonialSection";
import WhatWeOffer from "components/WhatWeOffer";
import {useNavigate} from "react-router-dom";
import TrialBanner from "components/TrialBanner";

export default function AboutUsView() {
    const navigate = useNavigate();

    async function onClickStartFreeTrial() {
        navigate("/register-for-trial");
    }
    return (
        <>
            <div>
                <TrialBanner onClickStartFreeTrial={onClickStartFreeTrial} />
            </div>
            <section className="pt-12 px-4">
                <div className="container mx-auto mb-20 text-center">
                    <Typography
                        color="yellow"
                        className="mb-2 font-bold uppercase">
                        About Us
                    </Typography>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mx-auto mb-8 w-full leading-tight lg:max-w-2xl text-center">
                        Activities for People Living With Dementia
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto mb-12 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20 text-center">
                        Australia's leading activity resource company enhancing the quality of life for people living
                        with dementia.
                    </Typography>
                </div>
                <WhatWeOffer />
                <div className="w-full lg:container lg:mx-auto">
                    <img
                        src="/img/bg-reading-sparkle-2.jpg"
                        alt="Reading sparkle newspaper"
                        className="h-auto w-full rounded-xl object-contain md:h-[28rem] lg:h-[28rem]"
                    />
                </div>

                <TestimonialSection />
            </section>
        </>
    );
}
