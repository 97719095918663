import React, {useState, useEffect, useRef} from "react";
import {useParams} from "react-router-dom";
import {Button, Chip, Typography} from "@material-tailwind/react";
import {getDatabase, ref, get, child} from "firebase/database";
import WebViewer from "@pdftron/webviewer";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {useSnackbar} from "providers/SnackbarContext";
import {SnackbarComponent} from "components/SnackbarComponent";

export default function ActivityDetail() {
    const activityIdentifier = useParams().activityIdentifier;
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [activity, setActivity] = useState();
    const [tags, setTags] = useState();
    const viewer = useRef(null);

    useEffect(() => {
        const database = getDatabase();
        const dbRef = ref(database);
        get(child(dbRef, `/activities/${activityIdentifier}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    const activityTags = Object.keys(response.tags);
                    setTags(activityTags);
                    setActivity(response);
                } else {
                    showAlert("No activities retrieved. Please try again.");
                }
            })
            .catch((error) => {
                showAlert("Error retrieving activity. Please try again.");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [activityIdentifier, setLoading, showAlert]);

    useEffect(() => {
        if (activity === null || activity === undefined) {
            return;
        }

        WebViewer(
            {
                licenseKey: "4HDnS8TDhjBVOEhUGaLF",
                path: "/webviewer/lib",
                initialDoc: activity.url,
            },
            viewer.current,
        ).then((instance) => {
            // now you can access APIs through the WebViewer instance
            const {Core} = instance;

            // adding an event listener for when a document is loaded
            Core.documentViewer.addEventListener("documentLoaded", () => {
                console.log("document loaded");
            });

            // adding an event listener for when the page number has changed
            Core.documentViewer.addEventListener("pageNumberUpdated", (pageNumber) => {
                console.log(`Page number is: ${pageNumber}`);
            });
        });
    }, [activity]);

    const onClickDownload = () => {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = activity.url;
        a.target = "_blank";
        a.download = "name"; // the filename you want
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(activity.url);
    };

    return (
        <>
            {loading && <LoadingComponent />}
            <header className="px-4 pr-4">
                {activity && (
                    <>
                        <div className="w-w-full container mx-auto pt-12 pb-8">
                            <Typography
                                variant="h1"
                                color="blue-gray"
                                className="mx-auto mb-8 w-full leading-tight lg:max-w-2xl text-center">
                                {activity.name}
                            </Typography>

                            <div className="flex flex-row mx-auto gap-2 lg:max-w-2xl mb-8 justify-center">
                                {tags.map((tag) => (
                                    <Chip
                                        color="yellow"
                                        value={tag}
                                    />
                                ))}
                            </div>
                            <div
                                className="webviewer h-600-px mb-4"
                                ref={viewer}></div>
                            <Button
                                color="yellow"
                                onClick={() => onClickDownload()}
                                fullWidth
                                size="lg">
                                Download
                            </Button>
                        </div>
                    </>
                )}
            </header>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
