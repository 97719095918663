import WelcomeText from "components/WelcomeText";
import React from "react";

export default function ThanksForSubscribing() {
    return (
        <>
            <div className="container m-auto grid grid-cols-2 gap-6 mb-4">
                <div className="">
                    <WelcomeText />
                </div>
                <div className="">
                    <img
                        alt="Lady looking at newspaper"
                        src="/img/bg-reading-sparkle.png"
                        className="hidden h-full w-full object-cover lg:block"
                    />
                </div>
            </div>
        </>
    );
}
