import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Card, CardBody, Typography, Chip, CardFooter} from "@material-tailwind/react";
import {ArrowLongRightIcon} from "@heroicons/react/24/outline";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {getQuizzes} from "services";

function QuizzesCard({key, tags, title}) {
    return (
        <Card
            key={key}
            className="border-2 border-yellow-500">
            <CardBody>
                <div className="col-span-5">
                    <Typography
                        variant="h4"
                        className="normal-case">
                        {title}
                    </Typography>
                </div>
                <div className="flex gap-2">
                    {tags.map((tag) => (
                        <Chip
                            color="yellow"
                            key={tag}
                            value={tag}
                        />
                    ))}
                </div>
            </CardBody>
            <CardFooter className="pt-0">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex justify-end items-center gap-2 hover:text-yellow-700">
                    Read Now
                    <ArrowLongRightIcon
                        strokeWidth={2}
                        className="w-4 h-4"
                    />
                </Typography>
            </CardFooter>
        </Card>
    );
}

function RequestYourOwnCard() {
    return (
        <Card
            key="requestyourown"
            className="border-2 border-yellow-500">
            <CardBody>
                <div className="col-span-5">
                    <Typography
                        variant="h4"
                        className="normal-case">
                        Request your own, custom quiz
                    </Typography>
                    <Typography
                        variant="paragraph"
                        className="normal-case">
                        Make your content even more customised by requesting your own quiz.
                    </Typography>
                </div>
            </CardBody>
            <CardFooter className="pt-0">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex justify-end items-center gap-2 hover:text-yellow-700">
                    Request Now
                    <ArrowLongRightIcon
                        strokeWidth={2}
                        className="w-4 h-4"
                    />
                </Typography>
            </CardFooter>
        </Card>
    );
}

export default function QuizzesView() {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [quizzes, setQuizzes] = useState([]);

    useEffect(() => {
        setLoading(true);
        getQuizzes()
            .then((res) => {
                setQuizzes(res);
            })
            .catch((error) => {
                console.error(error);
                showAlert(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, showAlert]);

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="py-10 px-4">
                <div className="container mx-auto mb-4 text-center">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Quizzes
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        Take a delightful trip down memory lane with our reminiscence quizzes! Rediscover the joys of
                        yesteryears as you test your knowledge on a wide range of nostalgic topics. From iconic movies
                        and music to memorable events and trends, these quizzes are designed to spark fond memories and
                        bring back the magic of the past.
                    </Typography>
                </div>
                <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-start gap-y-4 gap-x-4 px-4 pt-10 pb-16">
                    <Link to="request">
                        <RequestYourOwnCard />
                    </Link>
                    {quizzes &&
                        quizzes.map((quiz) => (
                            <Link to={quiz.id}>
                                <QuizzesCard
                                    key={quiz.id}
                                    tags={quiz.displayableTags}
                                    title={quiz.title}
                                />
                            </Link>
                        ))}
                </div>
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
