import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Chip} from "@material-tailwind/react";
import {Typography} from "@material-tailwind/react";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {useAuth} from "providers/AuthContext";
import {getArticle} from "services";

export default function ArticleView() {
    const params = useParams();
    const [tags, setTags] = useState();
    const [article, setArticle] = useState();
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const {currentUser} = useAuth();

    useEffect(() => {
        const identifier = params.identifier;

        getArticle(currentUser.uid, identifier)
            .then((res) => {
                setArticle(res);
                setTags(Object.keys(res.tags));
            })
            .catch((error) => {
                showAlert("Error retrieving articles. Please try again.");
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [params.identifier, currentUser.uid, setLoading, showAlert]);

    return (
        <>
            {loading && <LoadingComponent />}
            <header className="px-4 pr-4">
                {article && (
                    <>
                        <div className="w-w-full container mx-auto pt-12 pb-8">
                            <Typography
                                variant="h1"
                                color="blue-gray"
                                className="mx-auto mb-8 w-full leading-tight lg:max-w-2xl text-center">
                                {article.contentObject.title}
                            </Typography>

                            <div className="flex flex-row mx-auto gap-2 lg:max-w-2xl mb-8 justify-center">
                                {tags.map((tag) => (
                                    <Chip
                                        color="yellow"
                                        value={tag}
                                    />
                                ))}
                            </div>
                            {article.contentObject.paragraphs.map((para) => (
                                <Typography
                                    variant="paragrah"
                                    color="blue-gray"
                                    className="mx-auto mb-12 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20">
                                    {para}
                                </Typography>
                            ))}
                        </div>
                    </>
                )}
            </header>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
