import {Bars3Icon} from "@heroicons/react/24/outline";
import {XMarkIcon} from "@heroicons/react/24/solid";
import {Button, Collapse, IconButton, Navbar, Typography} from "@material-tailwind/react";
import React from "react";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";

function NavItem({label, path}) {
    return (
        <Link to={path}>
            <Typography
                as="li"
                variant="small"
                color="gray"
                className={`flex items-center gap-1.5 p-1 font-normal ${
                    window.location.pathname === path
                        ? "text-yellow-600 hover:text-yellow-700 font-bold"
                        : "text-blue-gray-700"
                }`}>
                {label}
            </Typography>
        </Link>
    );
}

function NavList() {
    return (
        <ul className="mb-4 mt-2 flex flex-col gap-3 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-8">
            <NavItem
                label="Home"
                path="/home"
            />
            <NavItem
                label="About Us"
                path="/about"
            />
            <NavItem
                label="How it works"
                path="/how-it-works"
            />
            <NavItem
                label="Pricing"
                path="/pricing"
            />
            <NavItem
                label="Contact Us"
                path="/contactus"
            />
        </ul>
    );
}

export default function SimpleNavbar({open, handleOpen}) {
    const navigate = useNavigate();

    async function onClickLogin() {
        navigate("/login");
    }

    async function onClickRegister() {
        navigate("/register");
    }

    return (
        <Navbar
            color="white"
            fullWidth>
            <div className="container mx-auto flex items-center justify-between text-blue-gray-900">
                <Link to="/home">
                    <Typography
                        as="a"
                        variant="h6"
                        color="blue-gray"
                        className="mr-4 cursor-pointer">
                        Daily Sparkle
                    </Typography>
                </Link>
                <div className="hidden lg:block">
                    <NavList />
                </div>
                <Button
                    onClick={() => onClickLogin()}
                    color="yellow"
                    size="sm"
                    className="hidden lg:inline-block">
                    Register/Log In
                </Button>

                <IconButton
                    size="sm"
                    variant="text"
                    color="blue-gray"
                    onClick={handleOpen}
                    className="ml-auto inline-block text-blue-gray-900 lg:hidden">
                    {open ? (
                        <XMarkIcon
                            className="h-6 w-6"
                            strokeWidth={2}
                        />
                    ) : (
                        <Bars3Icon
                            className="h-6 w-6"
                            strokeWidth={2}
                        />
                    )}
                </IconButton>
            </div>
            <Collapse open={open}>
                <NavList />
                <Button
                    onClick={() => onClickLogin()}
                    color="yellow"
                    size="sm"
                    className="mb-2"
                    fullWidth>
                    Log In
                </Button>
                <Button
                    onClick={() => onClickRegister()}
                    color="yellow"
                    size="sm"
                    className="mb-2"
                    fullWidth>
                    Register
                </Button>
            </Collapse>
        </Navbar>
    );
}
