import {ref as storageRef, uploadBytesResumable, getDownloadURL} from "firebase/storage";
import {storage} from "utils/firebase.js";

export const uploadToStorage = (path, file) => {
    return new Promise((resolve, reject) => {
        const fbStorageRef = storageRef(storage, path);
        const uploadTask = uploadBytesResumable(fbStorageRef, file);
        uploadTask.on(
            null,
            null,
            (error) => {
                reject(`Failed to upload to storage.`);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref)
                    .then((url) => {
                        resolve(url);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(`Failed to upload to storage.`);
                    });
            },
        );
    });
};
