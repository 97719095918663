import {Typography} from "@material-tailwind/react";
import React from "react";

export default function WelcomeText() {
    return (
        <>
            <div className="m-6">
                <Typography
                    className="mb-6"
                    variant="h2">
                    Welcome to the team!
                </Typography>
                <Typography
                    variant="paragraph"
                    className="mb-6">
                    We are thrilled to welcome you to the Daily Sparkles family! As you embark on this exciting journey
                    with us, get ready to experience a world of joy, connection, and enriching moments for your beloved
                    seniors.
                </Typography>
                <ul className="list-disc">
                    <li className="mb-6">
                        <Typography variant="paragraph">
                            With Daily Sparkles, you'll unlock the magic of reminiscence therapy and captivating content
                            that will kindle cherished memories and bring smiles to the faces of your loved ones.
                        </Typography>
                    </li>
                    <li className="mb-6">
                        <Typography variant="paragraph">
                            Tailor-made for your seniors, our activities, quizzes, and articles cater to individual
                            interests, ensuring a heartwarming and engaging experience for each one.
                        </Typography>
                    </li>
                    <li className="mb-6">
                        <Typography variant="paragraph">
                            Join a community of like-minded individuals who share the passion for creating a positive
                            impact on seniors' lives. Share stories, insights, and laughter as we journey together.
                        </Typography>
                    </li>
                    <li className="mb-6">
                        <Typography variant="paragraph">
                            Starting from today, expect a weekly dose of inspiration delivered right to your inbox,
                            keeping you connected and informed about the latest in aged care and senior well-being.
                        </Typography>
                    </li>
                </ul>

                <Typography
                    className="mb-6"
                    variant="lead">
                    Have questions or need personalized assistance?
                </Typography>
                <Typography
                    className="mb-6"
                    variant="paragraph">
                    Reach out to our friendly customer support team by email at info@dailysparkle.com.au. Experience the
                    power of articles with Daily Sparkles today!
                </Typography>
            </div>
        </>
    );
}
