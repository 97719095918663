import React, {useState} from "react";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {useSnackbar} from "providers/SnackbarContext";
import {SnackbarComponent} from "components/SnackbarComponent";
import {Button, Input} from "@material-tailwind/react";
import {addPublicationToDataStore, uploadPublicationToStorage} from "services";
import DatePicker from "components/DatePicker";

export default function AddPublicationView({publicationType}) {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [file, setFile] = useState(null);
    const [publicationDate, setPublicationDate] = useState();

    function handleFileSelectionChange(event) {
        setFile(event.target.files[0]);
    }

    const onClickSubmit = () => {
        if (!file || file === null) {
            showAlert("You must select a file for upload");
            return;
        }

        if (!publicationDate || publicationDate === null) {
            showAlert("You must select a publication date");
            return;
        }

        if (!publicationType || publicationType === null) {
            showAlert("You must select a publication type");
            return;
        }

        setLoading(true);
        uploadPublicationToStorage(file, publicationDate, publicationType, "pdf")
            .then((response) => {
                console.log(response);
                return addPublicationToDataStore(publicationDate, publicationType, "pdf", response.url, response.path);
            })
            .then((res) => {
                console.log("Success");
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="pt-12 px-4 space-y-5">
                <DatePicker
                    label="Select publication date"
                    date={publicationDate}
                    setDate={setPublicationDate}
                />
                <Input
                    type="file"
                    onChange={handleFileSelectionChange}
                    size="lg"
                    label="Select publication PDF file"
                    accept="/pdf/*"
                />
                <Button
                    onClick={() => onClickSubmit()}
                    fullWidth
                    variant="gradient"
                    color="yellow">
                    SUBMIT
                </Button>
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
