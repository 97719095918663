import {SYSTEM_ERROR} from "../config/CONSTANTS";
import {getDatabase, ref, get, child} from "firebase/database";
import {VIDEOS_URL} from "./CONSTANTS";

export const getAllVideos = () => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${VIDEOS_URL}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    const keys = Object.keys(response);
                    const retrievedVideos = keys.map((key) => {
                        const video = response[key];
                        return video;
                    });
                    resolve(retrievedVideos);
                } else {
                    reject("No videos retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
