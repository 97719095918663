import React, {useState} from "react";
import {Button, Dialog, Card, CardBody, CardFooter, Typography, Textarea} from "@material-tailwind/react";
import {functions} from "utils/firebase";
import {httpsCallable} from "firebase/functions";
import {useAuth} from "providers/AuthContext";

export default function FeedbackPopover() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen((cur) => !cur);
    const {currentUser} = useAuth();
    const [feedbackText, setFeedbackText] = useState("");

    async function onSubmitFeedback() {
        const writeFeedback = httpsCallable(functions, "writeFeedback");
        const payload = {
            user: currentUser.uid,
            feedback: feedbackText,
        };
        console.log(payload);
        await writeFeedback(payload);
        handleOpen();
    }

    return (
        <>
            <Button
                className="rounded-full"
                color="yellow"
                onClick={handleOpen}>
                Feedback?
            </Button>
            <Dialog
                size="xs"
                open={open}
                handler={handleOpen}
                className="bg-transparent shadow-none">
                <Card className="mx-auto w-full max-w-[36rem]">
                    <CardBody className="flex flex-col gap-4">
                        <Typography
                            variant="h4"
                            color="blue-gray">
                            Submit Feedback
                        </Typography>
                        <Typography
                            className="mb-3 font-normal"
                            variant="paragraph"
                            color="gray">
                            Please provide as much detail as possible.
                        </Typography>
                        <Textarea
                            onChange={(e) => setFeedbackText(e.target.value)}
                            variant="outlined"
                            color="yellow"
                            label="Feedback"
                        />
                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button
                            color="yellow"
                            onClick={() => onSubmitFeedback()}
                            fullWidth>
                            Submit
                        </Button>
                    </CardFooter>
                </Card>
            </Dialog>
        </>
    );
}
