import {Typography} from "@material-tailwind/react";
import React from "react";

export default function ContactUsThanksView() {
    return (
        <>
            <section className="grid h-screen items-center lg:grid-cols-2">
                <div className="mx-auto p-10 lg:max-w-xl">
                    <Typography
                        variant="h1"
                        className="mb-8">
                        Thank You for Reaching Out!
                    </Typography>
                    <Typography
                        variant="paragraph"
                        className="mb-8">
                        Your message has been successfully received, and we want to express our sincere gratitude for
                        taking the time to get in touch with us. We value your trust in Daily Sparkle and appreciate the
                        opportunity to assist you.
                    </Typography>
                    <Typography
                        variant="paragraph"
                        className="mb-8">
                        Our dedicated team is already hard at work, carefully reviewing your message and gathering all
                        the necessary information to provide you with the best possible response. We aim to get back to
                        you promptly, typically within 24-48 hours, but rest assured, we're working diligently to
                        address your inquiry as soon as possible.
                    </Typography>
                    <Typography
                        variant="paragraph"
                        className="mb-8">
                        In the meantime, if you have any urgent concerns or additional information to share, feel free
                        to reach out to us directly via email at info@dailysparkle.com.au. Our support staff is always
                        ready to assist you.
                    </Typography>
                </div>
                <img
                    alt="Lady looking at newspaper"
                    src="/img/bg-reading-sparkle.png"
                    className="hidden h-screen w-full object-cover lg:block"
                />
            </section>
        </>
    );
}
