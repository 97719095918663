import React, {useEffect, useState} from "react";
import {Typography} from "@material-tailwind/react";
import {useParams} from "react-router-dom";
import VimeoPlayer from "components/VimeoPlayer";
import {getDatabase, ref, get, child} from "firebase/database";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";

export default function VideoView() {
    const database = getDatabase();
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const params = useParams();
    const identifier = params.identifier;
    const [video, setVideo] = useState();

    useEffect(() => {
        async function fetchData() {
            const dbRef = ref(database);
            setLoading(true);

            try {
                const snapshot = await get(child(dbRef, `/videos/${identifier}`));
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    setVideo(response);
                } else {
                    showAlert("No video retrieved. Please try again.");
                }
            } catch (error) {
                showAlert("Error retrieving video. Please try again.");
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [database, identifier, setLoading, showAlert]);

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="py-10 px-4">
                {video && (
                    <>
                        <div className="container mx-auto mb-4 text-center">
                            <Typography
                                variant="h1"
                                color="blue-gray"
                                className="mx-auto mb-8 w-full leading-tight lg:max-w-2xl">
                                {video.name}
                            </Typography>
                            <Typography
                                variant="lead"
                                className="mx-auto mb-12 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20">
                                {video.blurb}
                            </Typography>
                        </div>
                        <div className="w-full mx-auto items-center">
                            <VimeoPlayer videoId={video.videoId} />
                        </div>
                    </>
                )}
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
