import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Typography} from "@material-tailwind/react";
import QuizCard from "components/quiz/QuizCard";

export default function QuizFullScreenView() {
    const {state} = useLocation();
    const [quiz, setQuiz] = useState();

    const [questionsAndAnswers, setQuestionsAndAnswers] = useState();
    const [currentQuestionAndAnswer, setCurrentQuestionAndAnswer] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        setQuiz(state.quiz);
        setQuestionsAndAnswers(state.questionsAndAnswers);
    }, [state.questionsAndAnswers, state.quiz]);

    useEffect(() => {
        if (questionsAndAnswers !== undefined) {
            setCurrentQuestionAndAnswer(questionsAndAnswers[0]);
        }
    }, [questionsAndAnswers]);

    async function onNext() {
        const nextQuestionAndAnswer = questionsAndAnswers[currentQuestionAndAnswer.index + 1];
        setCurrentQuestionAndAnswer(nextQuestionAndAnswer);
    }

    async function onPrev() {
        const previousQuestionAndAnswer = questionsAndAnswers[currentQuestionAndAnswer.index - 1];
        setCurrentQuestionAndAnswer(previousQuestionAndAnswer);
    }

    async function onExpand() {
        navigate("fullscreen", {state: {questionsAndAnswers: questionsAndAnswers}});
    }

    return (
        <>
            <header className="px-4 pr-4">
                {quiz && (
                    <>
                        <div className="w-w-full container mx-auto pt-12 pb-8">
                            <Typography
                                variant="h1"
                                color="blue-gray"
                                className="mx-auto mb-8 w-full leading-tight lg:max-w-2xl text-center">
                                {quiz.title}
                            </Typography>
                            <Typography
                                variant="paragrah"
                                color="blue-gray"
                                className="mx-auto mb-12 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20">
                                {quiz.blurb}
                            </Typography>
                            <div className="mx-auto mb-12 w-full lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20">
                                {currentQuestionAndAnswer && questionsAndAnswers && (
                                    <QuizCard
                                        index={currentQuestionAndAnswer.index}
                                        question={currentQuestionAndAnswer.question}
                                        answer={currentQuestionAndAnswer.answer}
                                        onNext={onNext}
                                        onPrev={onPrev}
                                        onExpand={onExpand}
                                        numberOfQuestions={questionsAndAnswers.length}
                                    />
                                )}
                            </div>
                        </div>
                    </>
                )}
            </header>
        </>
    );
}
