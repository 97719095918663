import React from "react";
import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <>
            <footer className="block py-4">
                <div className="container mx-auto px-4">
                    <hr className="mb-4 border-b-1 border-blueGray-200" />
                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                        <div className="w-full md:w-4/12 px-4">
                            <div className="text-sm text-blueGray-500 font-semibold py-1 text-left md:text-left">
                                <Link to="/member/termsandconditions">
                                    <p className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1">
                                        Copyright © {new Date().getFullYear()} Daily Sparkle Australia
                                    </p>
                                </Link>
                            </div>
                        </div>
                        {/* <div className="w-full md:w-8/12 px-4">
                            <ul className="flex flex-wrap list-none md:justify-end  justify-center">
                                <li>
                                    <Link to="/member/aboutus">
                                        <p className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1 px-3">
                                            Daily Sparkle Australia
                                        </p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/member/aboutus">
                                        <p className="text-blueGray-600 hover:text-blueGray-800 text-sm font-semibold block py-1 px-3">
                                            About Us
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </footer>
        </>
    );
}
