import React, {useState, useEffect, useRef} from "react";
import {useParams} from "react-router-dom";
import {Button, Typography} from "@material-tailwind/react";
import {ref as storageRef, getDownloadURL, getStorage} from "firebase/storage";
import WebViewer from "@pdftron/webviewer";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {getGuideForCategory} from "services";

export default function GuideView() {
    const params = useParams();
    const [guide, setGuide] = useState();
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [guideURL, setGuideURL] = useState();
    const viewer = useRef(null);

    useEffect(() => {
        const category = params.category;
        const identifier = params.identifier;

        getGuideForCategory(category, identifier)
            .then((response) => {
                setGuide(response);
                setGuideURL(response.url);
            })
            .catch((error) => {
                console.error(error);
                showAlert(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [params.category, params.identifier, setLoading, showAlert]);

    useEffect(() => {
        if (guide === undefined) {
            return;
        }
        if (guide.filePath !== undefined) {
            const storage = getStorage();
            getDownloadURL(storageRef(storage, guide.filePath))
                .then((url) => {
                    setGuideURL(url);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [guide]);

    useEffect(() => {
        WebViewer(
            {
                licenseKey: "4HDnS8TDhjBVOEhUGaLF",
                path: "/webviewer/lib",
                initialDoc: guideURL,
            },
            viewer.current,
        ).then((instance) => {
            // now you can access APIs through the WebViewer instance
            const {Core} = instance;

            // adding an event listener for when a document is loaded
            Core.documentViewer.addEventListener("documentLoaded", () => {
                console.log("document loaded");
            });

            // adding an event listener for when the page number has changed
            Core.documentViewer.addEventListener("pageNumberUpdated", (pageNumber) => {
                console.log(`Page number is: ${pageNumber}`);
            });
        });
    }, [guideURL]);

    const onClickDownload = () => {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = guideURL;
        a.target = "_blank";
        a.download = "name"; // the filename you want
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(guideURL);
    };

    return (
        <>
            {loading && <LoadingComponent />}
            <header className="px-4 pr-4">
                {guide && (
                    <>
                        <div className="w-w-full container mx-auto pt-12 pb-8">
                            <Typography
                                variant="h1"
                                color="blue-gray"
                                className="mx-auto mb-8 w-full leading-tight lg:max-w-2xl text-center">
                                {guide.fileName}
                            </Typography>
                            <div
                                className="webviewer h-600-px mb-4"
                                ref={viewer}></div>
                            <Button
                                color="yellow"
                                onClick={() => onClickDownload()}
                                fullWidth
                                size="lg">
                                Download
                            </Button>
                        </div>
                    </>
                )}
            </header>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
