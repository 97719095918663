import React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "providers/AuthContext";
import validator from "validator";
import {Input, Button, Typography} from "@material-tailwind/react";
import logCustomAnalyticsEvent from "utils/DSAnalytics";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {useSnackbar} from "providers/SnackbarContext";
import {SnackbarComponent} from "components/SnackbarComponent";

export default function LoginView() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const {login, getRole} = useAuth();

    async function onLogin() {
        try {
            if (!email | (email.length === 0) | !password | (password.length === 0)) {
                showAlert("Please complete email and password before proceeding.", "error");
                return;
            }

            if (validator.isEmail(email) === false) {
                showAlert("Invalid email. Please try again.", "error");
                return;
            }

            setLoading(true);
            await login(email, password);
            const role = await getRole();
            const url = role === "Admin" ? "/admin/landing" : "/member/landing";
            logCustomAnalyticsEvent("login", {});
            navigate(url, {replace: true});
        } catch (error) {
            console.log(error);
            if (error.code === "auth/wrong-password") {
                showAlert("Password incorrect. Please update and try again.", "error");
            }
        } finally {
            setLoading(false);
        }
    }

    const onClickForgottenPassword = async () => {
        navigate("/resetPassword", {replace: true});
    };

    const onClickRegister = async () => {
        navigate("/register", {replace: true});
    };

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="grid h-screen items-center lg:grid-cols-2">
                <div className="my-auto p-8 text-center sm:p-10 md:p-20 xl:px-32 xl:py-24">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-2">
                        Log In
                    </Typography>
                    <div className="mx-auto max-w-[24rem] text-left">
                        <div className="mb-8">
                            <Input
                                color="yellow"
                                required
                                size="lg"
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-8">
                            <Input
                                color="yellow"
                                required
                                size="lg"
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-wrap items-center justify-between mb-8">
                            <Typography
                                onClick={() => onClickForgottenPassword()}
                                color="blue"
                                className="font-medium text-center min-w-full hover:text-yellow-500">
                                Forgotten password? Click here to reset.
                            </Typography>
                        </div>
                        <Button
                            onClick={() => onLogin()}
                            color="yellow"
                            size="lg"
                            disabled={!email || !password}
                            className="mt-6 mb-8"
                            fullWidth>
                            Log In
                        </Button>
                        <div className="flex flex-wrap items-center justify-between gap-2">
                            <Typography
                                onClick={() => onClickRegister()}
                                color="blue"
                                className="font-medium text-center min-w-full hover:text-yellow-500">
                                Need an account? Click here to register.
                            </Typography>
                        </div>
                    </div>
                </div>

                <img
                    alt="Lady looking at newspaper"
                    src="/img/bg-reading-sparkle.png"
                    className="hidden h-screen w-full object-cover lg:block"
                />
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
