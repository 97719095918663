import React, {useEffect, useState, useMemo} from "react";
import {getDatabase, ref, get, child} from "firebase/database";
import MaterialReactTable from "material-react-table";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {Input, Typography, Button} from "@material-tailwind/react";
import {InformationCircleIcon} from "@heroicons/react/24/solid";
import {functions} from "utils/firebase.js";
import {httpsCallable} from "firebase/functions";
import {useAuth} from "providers/AuthContext";
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
        />
    );
});

export default function ArticleManage() {
    const navigate = useNavigate();
    const {currentUser} = useAuth();
    const [requestedArticles, setRequestedArticles] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [selectedArticleForGeneration, setSelectedArticleForGeneration] = useState();
    const [articleDescription, setArticleDescription] = useState();
    const [articleTitle, setArticleTitle] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState();
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const columns = useMemo(
        () => [
            {
                accessorKey: "date", //access nested data with dot notation
                header: "Date of request",
            },
            {
                accessorKey: "description",
                header: "Article Description",
            },
            {
                accessorKey: "requestedBy", //normal accessorKey
                header: "User UID",
            },
        ],
        [],
    );

    useEffect(() => {
        getRequests(currentUser.uid);
    }, [currentUser.uid]);

    async function getRequests(userId) {
        console.log("getRequests");
        const dbRef = ref(getDatabase());
        setOpenBackdrop(true);
        try {
            console.log(`/requestedArticles/${userId}`);
            const snapshot = await get(child(dbRef, `/requestedArticles`));
            console.log(JSON.stringify(snapshot.val()));
            if (snapshot.exists()) {
                const returnedVal = snapshot.val();
                const keys = Object.keys(returnedVal);
                const retrievedArticleRequests = keys.map(function (key) {
                    const retrievedArticleRequest = returnedVal[key];
                    return {
                        id: key,
                        date: retrievedArticleRequest.date,
                        description: retrievedArticleRequest.description,
                        title: retrievedArticleRequest.title,
                        requestedBy: retrievedArticleRequest.requestedBy,
                        content: retrievedArticleRequest.content,
                        wysiwygRaw: retrievedArticleRequest.wysiwygRaw,
                    };
                });

                setRequestedArticles(retrievedArticleRequests);
            }
        } catch (error) {
            console.error(error);
            setSnackbarText("Failed to retrieve requested articles. Please try again");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
        } finally {
            setOpenBackdrop(false);
        }
    }

    useEffect(() => {
        const keys = Object.keys(rowSelection);
        if (keys && keys.length === 1) {
            const indexFromKey = keys[0];
            const article = requestedArticles[indexFromKey];
            if (!article.content) {
                setSelectedArticleForGeneration(article);
            }
        }
    }, [rowSelection, requestedArticles]);

    useEffect(() => {
        if (selectedArticleForGeneration && selectedArticleForGeneration !== null) {
            setArticleDescription(selectedArticleForGeneration.description);
        }
    }, [selectedArticleForGeneration]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const onSubmitRequestedArticle = async () => {
        if ((articleDescription == null) | (articleTitle == null)) {
            setSnackbarSeverity("error");
            setSnackbarText("Fill in both title and description.");
            setOpenSnackbar(true);
            return;
        }

        const response = httpsCallable(functions, "generateRequestedArticle");
        const payload = {
            id: selectedArticleForGeneration.id,
            title: articleTitle,
            description: articleDescription,
            requestedBy: selectedArticleForGeneration.requestedBy,
            createdBy: currentUser.uid,
        };
        setOpenBackdrop(true);
        try {
            await response(payload);
            setArticleDescription("");
            setArticleTitle("");
            setSelectedArticleForGeneration(null);
            getRequests(currentUser.uid);
        } catch (error) {
            setSnackbarSeverity("error");
            setSnackbarText("There was an error submitting your request. Please try again later.");
            setOpenSnackbar(true);
            console.log({error});
        } finally {
            setOpenBackdrop(false);
        }
    };

    const onChangeArticleDescription = ({target}) => setArticleDescription(target.value);

    const onChangeArticleTitle = ({target}) => setArticleTitle(target.value);

    const onClickEdit = async (row) => {
        navigate(`edit/${row.id}?edit=true`, {
            state: {
                content: row.content,
                raw: row.wysiwygRaw,
            },
        });
    };

    const onClickPreview = async (row) => {
        navigate(`preview/${row.id}`);
    };

    const onClickPublish = async (row) => {
        console.log(row);
        setOpenBackdrop(true);
        try {
            row.approvedBy = currentUser.uid;
            const approveRequestedArticle = httpsCallable(functions, "approveRequestedArticle");
            await approveRequestedArticle(row);
            setRequestedArticles([]);
            await getRequests(currentUser.uid);
        } catch (error) {
            setSnackbarSeverity("error");
            setSnackbarText("There was an error approving the request. Please try again later.");
            setOpenSnackbar(true);
            console.log({error});
        } finally {
            setOpenBackdrop(false);
        }
    };

    return (
        <>
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="flex flex-wrap">
                <div className="w-full mb-12 xl:mb-12 px-8 pt-8 pb-8">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">Article Requests</h6>
                            </div>
                        </div>
                        <hr className="mt-1 border-b-1 border-blueGray-300" />
                        <div className="flex-auto px-4 lg:px-4 py-4 pt-0">
                            <div className="overflow-x-auto py-4">
                                <MaterialReactTable
                                    columns={columns}
                                    data={requestedArticles}
                                    enableRowSelection
                                    enableMultiRowSelection={false} //shows radio buttons instead of checkboxes
                                    onRowSelectionChange={setRowSelection}
                                    state={{rowSelection}}
                                    enableTopToolbar={true}
                                    enableColumnActions={false}
                                    enableRowActions
                                    positionActionsColumn="last"
                                    renderRowActions={({row, table}) => (
                                        <>
                                            <Box sx={{display: "flex", flexWrap: "nowrap", gap: "8px"}}>
                                                {row.original.content && (
                                                    <>
                                                        <Button onClick={() => onClickEdit(row.original)}>Edit</Button>
                                                        <Button onClick={() => onClickPreview(row.original)}>
                                                            Preview
                                                        </Button>
                                                        <Button onClick={() => onClickPublish(row.original)}>
                                                            Publish
                                                        </Button>
                                                    </>
                                                )}
                                            </Box>
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {selectedArticleForGeneration && (
                        <>
                            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                                <div className="rounded-t mb-0 px-6 py-6">
                                    <div className="text-center flex justify-between">
                                        <h6 className="text-blueGray-700 text-xl font-bold">
                                            Generate the requested article
                                        </h6>
                                        <button
                                            onClick={(e) => onSubmitRequestedArticle()}
                                            className="bg-yellow-500 text-white active:bg-yellow-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                            type="button">
                                            SUBMIT
                                        </button>
                                    </div>
                                </div>
                                <hr className="mt-1 border-b-1 border-blueGray-300" />
                                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                    <form>
                                        <div className="flex flex-wrap">
                                            <div className="w-full pt-8 px-4">
                                                <div className="relative w-full mb-3">
                                                    <Input
                                                        type="text"
                                                        label="Article Description"
                                                        color="yellow"
                                                        value={articleDescription}
                                                        onChange={onChangeArticleDescription}
                                                    />
                                                </div>
                                                <div className="relative w-full mb-3">
                                                    <Input
                                                        className="pt-4"
                                                        type="text"
                                                        label="Article Title"
                                                        color="yellow"
                                                        value={articleTitle}
                                                        onChange={onChangeArticleTitle}
                                                    />
                                                </div>
                                                <div className="mx-auto relative justify-left flex max-w-full">
                                                    <Typography
                                                        variant="small"
                                                        color="gray"
                                                        className="flex items-start gap-1 font-normal mt-2">
                                                        <InformationCircleIcon className="w-4 h-4 -mt-px" />
                                                        Feel free to amend the text to correct any spellings or make the
                                                        description more concise
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                    )}

                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}>
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={snackbarSeverity}
                            sx={{width: "100%"}}>
                            {snackbarText}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
        </>
    );
}
