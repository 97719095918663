import {Typography} from "@material-tailwind/react";

export default function LandingView() {
    return (
        <>
            <header className="px-4 pr-4">
                <div className="w-w-full container mx-auto pt-12 pb-8">
                    <img
                        alt="Daily sparkles logo"
                        class="max-w-full mx-auto pt-6 object-center h-60 content-center mb-4"
                        src={require("assets/img/dailysparklelogo-large.jpg")}
                    />
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mx-auto mb-8 w-full leading-tight lg:max-w-2xl text-center">
                        Welcome to the Daily Sparkle Portal.
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto mb-12 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20">
                        Are you searching for a way to unlock cherished memories, foster connections, and bring moments
                        of joy to those you care for? Look no further! Daily Sparkle is your trusted companion on the
                        path of reminiscence therapy, offering a world of inspiration and meaningful engagement.
                    </Typography>
                </div>
                <div className="w-full lg:container lg:mx-auto">
                    <img
                        alt="Lady looking at newspaper"
                        src="/img/bg-reading-sparkle.png"
                        className="h-96 w-full rounded-xl object-cover md:h-[48rem] lg:h-[56rem]"
                    />
                </div>
                <div className="w-w-full container mx-auto pt-12 pb-4 text-center">
                    <Typography
                        variant="lead"
                        className="mx-auto mb-12 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20">
                        Reminiscence therapy is a powerful technique that taps into the memories and experiences of
                        individuals, particularly those living with dementia or memory loss. It involves stimulating
                        conversations, sensory activities, and visual prompts that evoke nostalgia and encourage the
                        sharing of personal stories. Daily Sparkle is dedicated to harnessing the potential of
                        reminiscence therapy to enhance the well-being and quality of life of individuals and their
                        caregivers.
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto mb-12 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20">
                        Daily Sparkle provides a rich and diverse collection of reminiscence materials that inspire
                        conversations, spark memories, and create opportunities for connection. Our carefully curated
                        content includes nostalgic articles, evocative photographs, music playlists, and themed activity
                        ideas, all designed to ignite reminiscing sessions that are engaging and meaningful.
                    </Typography>
                </div>
            </header>
        </>
    );
}
