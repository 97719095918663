import {useRef, useEffect} from "react";
import WebViewer from "@pdftron/webviewer";

export default function SparklesUserGuideView() {
    const viewer = useRef(null);

    useEffect(() => {
        WebViewer(
            {
                path: "/webviewer/lib",
            },
            viewer.current,
        ).then((instance) => {
            console.log(instance);
            instance.UI.loadDocument("/files/DailySparkle-User-Guide-Web.pdf");
        });
    });

    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-10 pb-6">
                <div className="rounded-t mb-0 px-6 py-6">
                    <div>
                        <div
                            className="webviewer py-4"
                            ref={viewer}
                            style={{height: "80vh"}}></div>
                    </div>
                </div>
            </div>
        </>
    );
}
