import {Typography} from "@material-tailwind/react";
import React, {useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);

function MutlipleChoiceQuizzesTable({dates}) {
    const navigate = useNavigate();

    const columns = [
        {
            name: "Name",
            selector: (row) => row.name,
        },
    ];

    const handleRowClicked = (row) => {
        navigate(`${row.id}`);
    };

    return (
        <>
            <Typography
                variant="h6"
                className="pt-8 text-left">
                Daily Quizzes
            </Typography>
            {dates && (
                <>
                    <DataTable
                        highlightOnHover
                        pointerOnHover
                        pagination
                        selectableRowsHighlight
                        columns={columns}
                        data={dates}
                        onRowClicked={handleRowClicked}
                    />
                </>
            )}
        </>
    );
}

export default function MultipleChoiceQuizzesView() {
    const [dates, setDates] = useState();

    useEffect(() => {
        const month = dayjs().format("MMMM").toString();
        const daysInMonth = dayjs().daysInMonth();
        var builtDates = [];
        for (let i = 1; i <= daysInMonth; i++) {
            builtDates.push({id: `${month}/${i}`, name: `${dayjs().date(i).format("D MMMM YYYY")}`});
        }
        setDates(builtDates);
    }, []);

    return (
        <>
            <section className="py-10 px-4">
                <div className="container mx-auto mb-4 text-center">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Daily Multiple Choice Quizzes
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        Multiple choice quizzes
                    </Typography>

                    {dates && <MutlipleChoiceQuizzesTable dates={dates} />}
                </div>
            </section>
        </>
    );
}
