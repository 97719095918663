import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Typography} from "@material-tailwind/react";
import DataTable from "react-data-table-component";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {getGuide} from "services";

function GuidesTable({guides}) {
    const navigate = useNavigate();

    const columns = [
        {
            name: "Name",
            selector: (row) => row.fileName,
        },
    ];

    const handleRowClicked = (row) => {
        navigate(`${row.id}`);
    };

    return (
        <>
            <Typography
                variant="h6"
                className="pt-8 text-left">
                Sensory Sparkles
            </Typography>
            {guides && (
                <>
                    <DataTable
                        highlightOnHover
                        pointerOnHover
                        pagination
                        selectableRowsHighlight
                        columns={columns}
                        data={guides}
                        onRowClicked={handleRowClicked}
                    />
                </>
            )}
        </>
    );
}

export default function GuidesView() {
    const params = useParams();
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [title, setTitle] = useState();
    const [blurb, setBlurb] = useState();
    const [guides, setGuides] = useState([]);

    useEffect(() => {
        const category = params.category;
        if (category !== undefined) {
            setLoading(true);
            getGuide(category)
                .then((response) => {
                    setTitle(response.title);
                    setBlurb(response.blurb);
                    const keys = Object.keys(response.resources);
                    const retrievedGuides = keys.map((key) => {
                        const guide = response.resources[key];
                        return guide;
                    });
                    setGuides(retrievedGuides);
                })
                .catch((error) => {
                    console.error(error);
                    showAlert(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [params.category, setLoading, showAlert]);

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="py-10 px-4">
                <div className="container mx-auto mb-4 text-center">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        {title}
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        {blurb}
                    </Typography>
                    {guides && <GuidesTable guides={guides} />}
                </div>
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
