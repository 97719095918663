import React from "react";
import {Link} from "react-router-dom";
import {Card, CardBody, CardFooter, Typography} from "@material-tailwind/react";
import MenuBookIcon from "@mui/icons-material/MenuBook";

const guides = [
    {
        identifier: "best_practice_guides",
        name: "Best Practice Guides",
    },
    {
        identifier: "daily_sparkle_user_guides",
        name: "Daily Sparkle User Guides",
    },
    {
        identifier: "getting_started_guide",
        name: "Getting Started",
    },
    {
        identifier: "pal_guide",
        name: "PAL Guide for Activity Provision",
    },
];

function GuidesCategoryCard({guide}) {
    return (
        <>
            <Card className="mt-2 bg-white h-full border-yellow-500 border-2">
                <CardBody key={guide.identifier}>
                    <MenuBookIcon fontSize="large" />
                    <Typography
                        variant="h5"
                        color="blue-gray"
                        className="mb-2">
                        {guide.name}
                    </Typography>
                </CardBody>
                <CardFooter className="pt-0">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="flex justify-end items-center gap-2 hover:text-yellow-700">
                        View Now
                    </Typography>
                </CardFooter>
            </Card>
        </>
    );
}

export default function GuidesCategoryView() {
    return (
        <section className="py-10 px-4">
            <div className="container mx-auto mb-4 text-center">
                <Typography
                    variant="h1"
                    color="blue-gray"
                    className="mb-4">
                    Guides
                </Typography>
                <Typography
                    variant="lead"
                    className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                    Best practice guides, plans and user guides to help you get the very best from your Daily Sparkle
                    resources.
                </Typography>
            </div>
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-start gap-y-4 gap-x-4 px-4 pt-10 pb-16">
                {guides &&
                    guides.map((guide) => (
                        <Link to={guide.identifier}>
                            <GuidesCategoryCard guide={guide} />
                        </Link>
                    ))}
            </div>
        </section>
    );
}
