import React from "react";
import {Button, Typography} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";
import TrialBanner from "components/TrialBanner";

export default function HomeView() {
    const navigate = useNavigate();

    async function onClickStartFreeTrial() {
        navigate("/register");
    }

    async function onClickFindOut() {
        navigate("/how-it-works");
    }

    return (
        <>
            <div>
                <TrialBanner onClickStartFreeTrial={onClickStartFreeTrial} />
            </div>
            <div className="w-w-full container mx-auto pt-12 pb-8 text-center">
                <Typography
                    variant="h1"
                    color="blue-gray"
                    className="mx-auto mb-8 w-full leading-tight lg:max-w-2xl">
                    Activities for People Living With Dementia
                </Typography>
                <Typography
                    variant="lead"
                    className="mx-auto mb-8 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20">
                    Australia's leading activity resource company enhancing the quality of life for people living with
                    dementia.
                </Typography>

                <Button
                    onClick={() => onClickFindOut()}
                    size="lg"
                    variant="filled"
                    color="yellow">
                    find out how it works
                </Button>
            </div>
            <div className="w-full lg:container lg:mx-auto">
                <img
                    src="/img/bg-reading-sparkle.png"
                    alt="credit cards"
                    className="h-96 w-full rounded-xl object-cover md:h-[48rem] lg:h-[56rem]"
                />
            </div>{" "}
            */
        </>
    );
}
