import * as React from "react";
import {useState} from "react";

export default function TagCreate({handleSubmit}) {
    const [tag, setTag] = useState();

    return (
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center flex justify-between">
                    <h6 className="text-blueGray-700 text-xl font-bold">Create a Tag</h6>
                    <button
                        onClick={(e) => handleSubmit(tag, setTag)}
                        className="bg-yellow-500 text-white active:bg-yellow-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button">
                        SUBMIT
                    </button>
                </div>
            </div>
            <hr className="mt-1 border-b-1 border-blueGray-300" />
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form>
                    <div className="flex flex-wrap">
                        <div className="w-full pt-4 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password">
                                    New Tag Name
                                </label>
                                <input
                                    type="text"
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    value={tag}
                                    onChange={(e) => setTag(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
