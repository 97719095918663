import {SYSTEM_ERROR} from "../config/CONSTANTS";
import {getDatabase, ref, get, child} from "firebase/database";
import {GUIDES_URL} from "./CONSTANTS";

export const getGuide = (category) => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${GUIDES_URL}/${category}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    resolve(response);
                } else {
                    reject("No guides retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getGuideForCategory = (category, identifier) => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${GUIDES_URL}/${category}/resources/${identifier}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    resolve(response);
                } else {
                    reject("No guide retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
