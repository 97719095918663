import React, {useEffect, useState} from "react";
import {useAuth} from "providers/AuthContext";
import {Typography, Card, CardBody, Button} from "@material-tailwind/react";
import {addCheckoutSession} from "utils/firestore/subscriptionFirestore";
import {premiumProduct, standardProduct} from "data/Products";
import logCustomAnalyticsEvent from "utils/DSAnalytics";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";

function PricingCard({payload, onClickSignUp}) {
    return (
        <Card
            className={payload.packageName === "Standard" ? "border-2 border-yellow-500" : "border-0"}
            variant="gradient"
            color={payload.packageName === "Standard" ? "white" : "yellow"}>
            <CardBody className="relative z-10 text-center">
                <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-20 uppercase">
                    {payload.packageName}
                </Typography>
                <Typography
                    variant="h1"
                    color="blue-gray"
                    className="text-6xl">
                    ${payload.price}
                </Typography>
                <Typography
                    color="blue-gray"
                    className="text-xl font-normal opacity-80">
                    per month
                </Typography>
                <div className="flex justify-center pt-6 pb-20">
                    <ul className="flex flex-col place-items-center gap-2">
                        {payload.features.slice(0, 6).map((option, key) => (
                            <Typography
                                as="li"
                                key={key}
                                color="blue-gray"
                                className="flex items-center gap-2 font-normal">
                                {option}
                            </Typography>
                        ))}
                    </ul>
                </div>
                <Button
                    onClick={() => onClickSignUp(payload)}
                    variant="gradient"
                    color={payload.packageName === "Standard" ? "yellow" : "white"}>
                    Sign Up Now
                </Button>
            </CardBody>
        </Card>
    );
}

export default function UpgradeView() {
    const {currentUser, currentUserStripeSubscriptionType} = useAuth();
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [products, setProducts] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const subscription = await currentUserStripeSubscriptionType;
            if (subscription === "Standard") {
                setProducts([premiumProduct]);
            } else if (subscription === "Premium") {
                setProducts([standardProduct]);
            } else {
                setProducts([standardProduct, premiumProduct]);
            }
        }
        fetchData();
    }, [currentUserStripeSubscriptionType]);

    async function onClick(payload) {
        setLoading(true);
        addCheckoutSession(payload, currentUser.uid, assignUrl);
    }

    async function assignUrl(url, error) {
        if (url === undefined && error === undefined) {
            return;
        }
        setLoading(false);
        if (error) {
            showAlert("Something went wrong. Please try again");
            logCustomAnalyticsEvent("restriction_signup", {error: error});
            console.error("There was an error creating a checkout session", error);
        } else {
            window.location.assign(url);
        }
    }

    return (
        <>
            {loading && <LoadingComponent />}
            <div className="grid h-fit place-items-center pt-20 md:pt-8">
                <section className="container mx-auto px-4">
                    <div className="grid grid-cols-1 items-center xl:grid-cols-3">
                        <div className="col-span-1 py-2">
                            <Typography
                                className="uppercase text-yellow-500 pb-4"
                                variant="h1"
                                color="blue-gray">
                                Upgrade Now
                            </Typography>
                            <Typography
                                variant="h2"
                                color="blue-gray">
                                Unlock the Power of Daily Sparkles
                            </Typography>
                            <Typography
                                variant="lead"
                                className="mt-2 !text-gray-600">
                                Transform your aged care facility into a hub of joy and wonder with Daily Sparkles'
                                enriching articles. Let the magic of reminiscence flow through captivating storytelling,
                                shared laughter, and unforgettable experiences.
                            </Typography>
                        </div>
                        <div className="col-span-2">
                            <div className="grid gap-6 md:grid-cols-1 lg:grid-cols-2 xl:pl-32">
                                {products &&
                                    products.map((product) => (
                                        <PricingCard
                                            payload={product}
                                            onClickSignUp={onClick}
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
