export const LARGE_FONT_SPARKLES_URL = "/largeFontSparkles";
export const NEWSPAPERS_URL = "/newspapers";
export const SENSORY_SPARKLES_URL = "/sensorySparkles";
export const WEEKLY_DOWNLOADS_URL = "/weeklyDownloads";
export const CARE_PROVIDERS_URL = "/careProviders";
export const QUIZZES_URL = "/quizzes";
export const MULTIPLE_CHOICE_QUIZ_URL = "/mulitpleChoiceQuizzes";
export const ARTICLES_URL = "/articles";
export const VIDEOS_URL = "/videos";
export const GUIDES_URL = "/guides";
