import {Typography} from "@material-tailwind/react";

export default function ActivitiesDetail() {
    return (
        <>
            <div className="container mx-auto mb-20 text-center">
                <Typography
                    color="yellow"
                    className="mb-2 font-bold uppercase">
                    how it works
                </Typography>
                <Typography
                    variant="h1"
                    color="blue-gray"
                    className="mb-4">
                    Activities
                </Typography>
                <Typography
                    variant="lead"
                    className="mx-auto w-full px-4 !text-gray-500 lg:w-8/12 lg:px-8">
                    Enhancing Quality of Life: Daily Sparkle's Activities for Older People and Those Living with
                    Dementia
                </Typography>
            </div>
            <div className="container mx-auto grid items-center lg:grid-cols-2">
                <div className="row-start-2 mt-12 lg:row-auto lg:mt-0 lg:pr-12">
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        Daily Sparkle stands at the forefront of creating enriching experiences for older adults and
                        individuals living with dementia. Their suite of activities is a testament to their commitment
                        to providing top-quality, professionally designed resources that not only entertain but also
                        stimulate and foster meaningful relationships.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        Professionally Crafted Activities for Engagement and Joy
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        At the heart of Daily Sparkle’s ethos is a deep understanding of the needs of older individuals,
                        especially those with dementia. Their activities are crafted by professionals who specialize in
                        elderly care, ensuring that each activity is not only engaging but also cognitively stimulating.
                        From puzzles and word games to creative crafts and music sessions, Daily Sparkle’s range of
                        activities caters to diverse interests and ability levels, ensuring that everyone can
                        participate and find joy.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        Ease of Use: A Key Factor in Accessibility
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        One of the hallmarks of Daily Sparkle’s activities is their ease of use. Recognizing the busy
                        schedules of caregivers and the varying cognitive abilities of their audience, these resources
                        are designed to be quick and straightforward to implement. Whether it’s a group activity in a
                        community setting or a one-on-one session, caregivers can effortlessly integrate these
                        activities into their daily routines, providing stimulation, interest, and enjoyment every day.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        More Than Just Activities: Building Relationships
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        Beyond the immediate joy and engagement these activities provide, they are also highly effective
                        tools for relationship building. For care home residents, especially those with dementia,
                        forming connections can be challenging. Daily Sparkle’s activities are designed to encourage
                        interaction, conversation, and shared experiences. They become a medium through which residents,
                        caregivers, and family members can connect, fostering deeper understanding and empathy.
                    </Typography>
                </div>
                <img
                    src="/img/bg-activities.jpeg"
                    alt="team work"
                    className=" h-full min-h-[50vh] w-full rounded-xl object-cover object-center md:min-h-[75vh]"
                />
            </div>
        </>
    );
}
