import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "providers/AuthContext";
import SiderbarItem from "./SiderbarItem";

export default function SidebarAdmin() {
    const [collapseShow, setCollapseShow] = React.useState("hidden");
    const navigate = useNavigate();
    const {currentUser} = useAuth();
    const {logout} = useAuth();

    async function onLogOut() {
        await logout();
        navigate("/login", {replace: true});
    }

    return (
        <>
            <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
                <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
                    {/* Toggler */}
                    <button
                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button"
                        onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}>
                        <i className="fas fa-bars"></i>
                    </button>
                    {/* Brand */}
                    <Link to="/admin/landing">
                        <img
                            alt="Daily Sparkle Logo"
                            class="h-auto max-w-full object-contain pt-6"
                            src={require("assets/img/dailysparklelogo.png")}
                        />
                    </Link>
                    <div
                        className={
                            "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
                            collapseShow
                        }>
                        {/* Collapse header */}
                        <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                            <div className="flex flex-wrap">
                                <div className="w-6/12">
                                    <Link
                                        to="/"
                                        className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0">
                                        Daily Sparkle
                                    </Link>
                                </div>
                                <div className="w-6/12 flex justify-end">
                                    <button
                                        type="button"
                                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                        onClick={() => setCollapseShow("hidden")}>
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <>
                            {/* Divider */}
                            <hr className="my-4 md:min-w-full" />
                            {/* Heading */}
                            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                App Users
                            </h6>
                            {/* Navigation */}
                            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                                <li className="items-center">
                                    <SiderbarItem
                                        link="/admin/users"
                                        title="Manage Users"
                                    />
                                </li>
                                <li className="items-center">
                                    <SiderbarItem
                                        link="/admin/claims"
                                        title="Manage Custom Claims"
                                    />
                                </li>
                            </ul>
                        </>

                        <>
                            {/* Divider */}
                            <hr className="my-4 md:min-w-full" />
                            {/* Heading */}
                            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                CONTENT
                            </h6>
                            {/* Navigation */}
                            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                                <li className="items-center">
                                    <SiderbarItem
                                        link="/admin/newspapers/add"
                                        title="Add Newspapers"
                                    />
                                </li>
                                <li className="items-center">
                                    <SiderbarItem
                                        link="/admin/downloads/create"
                                        title="Create Weekly Downloads"
                                    />
                                </li>
                                <li className="items-center">
                                    <SiderbarItem
                                        link="/admin/downloads/previews"
                                        title="Preview Weekly Downloads"
                                    />
                                </li>

                                <li className="items-center">
                                    <SiderbarItem
                                        link="/admin/sensorySparkles/add"
                                        title="Add Sensory Sparkles"
                                    />
                                </li>
                                <li className="items-center">
                                    <SiderbarItem
                                        link="/admin/largeFontSparkles/add"
                                        title="Add Large Font Sparkles"
                                    />
                                </li>
                                <li className="items-center">
                                    <SiderbarItem
                                        link="/admin/activities/add"
                                        title="Add Activities"
                                    />
                                </li>
                                <li className="items-center">
                                    <SiderbarItem
                                        link="/admin/videos/add"
                                        title="Add Video"
                                    />
                                </li>
                                <li className="items-center">
                                    <SiderbarItem
                                        link="/admin/guides/add"
                                        title="Add Guides"
                                    />
                                </li>
                            </ul>
                        </>
                        <>
                            {/* Divider */}
                            <hr className="my-4 md:min-w-full" />
                            {/* Heading */}
                            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                TAGGING
                            </h6>
                            {/* Navigation */}
                            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                                <li className="items-center">
                                    <SiderbarItem
                                        link="/admin/tags"
                                        title="Manage Tags"
                                    />
                                </li>
                            </ul>
                        </>
                        <>
                            {/* Divider */}
                            <hr className="my-4 md:min-w-full" />
                            {/* Heading */}
                            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                REQUESTS
                            </h6>
                            {/* Navigation */}
                            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                                <li className="items-center">
                                    <SiderbarItem
                                        link="/admin/quizzes"
                                        title="Manage Quiz Requests"
                                    />
                                </li>
                                <li className="items-center">
                                    <SiderbarItem
                                        link="/admin/articles"
                                        title="Manage Article Requests"
                                    />
                                </li>
                            </ul>
                        </>
                        <>
                            {/* Divider */}
                            <hr className="my-4 md:min-w-full" />
                            {/* Heading */}
                            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                                MISC
                            </h6>
                            {/* Navigation */}
                            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                                <li className="items-center">
                                    <SiderbarItem
                                        link="/admin/testFunctions"
                                        title="Test Functions"
                                    />
                                </li>
                            </ul>
                        </>

                        {currentUser !== null && (
                            <>
                                {/* Divider */}
                                <hr className="my-4 md:min-w-full" />
                                {/* Navigation */}
                                <button
                                    class="w-full text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500"
                                    onClick={onLogOut}>
                                    Log Out
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </nav>
        </>
    );
}
