import {Backdrop, CircularProgress} from "@mui/material";
import {useLoading} from "providers/LoadingContext";

export function LoadingComponent() {
    const {loading} = useLoading();

    return (
        <Backdrop
            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
