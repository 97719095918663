import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import QuizIcon from "@mui/icons-material/Quiz";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import BrushIcon from "@mui/icons-material/Brush";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import CasinoIcon from "@mui/icons-material/Casino";
import ManIcon from "@mui/icons-material/Man";
import HikingIcon from "@mui/icons-material/Hiking";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import HandshakeIcon from "@mui/icons-material/Handshake";
import {Typography} from "@material-tailwind/react";

const unsortedMetaCategories = [
    {value: "Activity Planners", icon: <VolunteerActivismIcon fontSize="large" />, identifier: "activityPlanner"},
    {value: "Quizzes and Puzzles", icon: <QuizIcon fontSize="large" />, identifier: "quizzesandpuzzles"},
    {value: "Interactive Quizzes", icon: <QuizIcon fontSize="large" />, identifier: "interactiveQuizzes"},
    {value: "Discussions", icon: <RecordVoiceOverIcon fontSize="large" />, identifier: "discussions"},
    {value: "Arts and Crafts", icon: <ColorLensIcon fontSize="large" />, identifier: "artsandcrafts"},
    {value: "Colouring", icon: <BrushIcon fontSize="large" />, identifier: "colouring"},
    {value: "Food and Drink", icon: <FastfoodIcon fontSize="large" />, identifier: "foodanddrink"},
    {value: "Games", icon: <CasinoIcon fontSize="large" />, identifier: "games"},
    {value: "For Men", icon: <ManIcon fontSize="large" />, identifier: "formen"},
    {value: "Movement", icon: <HikingIcon fontSize="large" />, identifier: "movement"},
    {value: "Occupational", icon: <CleaningServicesIcon fontSize="large" />, identifier: "occupational"},
    {value: "Sensory", icon: <HandshakeIcon fontSize="large" />, identifier: "sensory"},
];

export const metaCategories = unsortedMetaCategories.sort((a, b) => a.value.localeCompare(b.value));

export const metaCategoryOptions = metaCategories.map((item) => {
    return {
        value: item.value,
        label: item.value,
    };
});

export const allCategories = metaCategories;

export function getParagraphText(categoryName) {
    switch (categoryName) {
        case "Arts and Crafts":
            return (
                <>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Arts and Crafts
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        Arts and crafts sessions are great opportunities for your residents to express their creativity.
                        Set projects at a level where everyone feels they can contribute, regardless of ability.
                        Download the PDFs and print them off for one-to-one or group activities. If printing is
                        difficult, you could also read them on a tablet.
                    </Typography>
                </>
            );
        case "Colouring":
            return (
                <>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Colouring
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        A varied range of coluring sheets - ideal for individual or group activity.
                    </Typography>
                </>
            );
        case "Discussions":
            return (
                <>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Discussions
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        Use these large images to begin discussions with your residents. Each of these large images has
                        a second page with a set of suggested questions to help you. Print them off for a resident to
                        look at alone, or in a small group, or one-to-one with a member of your team. If printing is
                        difficult, you could also show them on a tablet.
                    </Typography>
                </>
            );
        case "Food and Drink":
            return (
                <>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Food and Drink
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        A range of food and drink activities ideal for one-to-one or group sessions.
                    </Typography>
                </>
            );
        case "For Men":
            return (
                <>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        For Men
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        A range of activities that are ideal for men.
                    </Typography>
                </>
            );
        case "Activity Planners":
            return (
                <>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Activity Planners
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        This planner is designed to provide a handy overview of key events and topics for the month, as
                        well as helpful tips on getting the very most out of our resources.
                    </Typography>
                </>
            );
        case "Quizzes and Puzzles":
            return (
                <>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Quizzes and Puzzles
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        These quizzes, word searches, crosswords and games are a great way of keeping your residents
                        minds stimulated. Each Set of Resources is a PDF file. New Sets will be added every month. And
                        this month we have 28 Daily Sparkle quizzes for your residents to enjoy! Print them off for an
                        instant activity that a resident can do alone, in a small group, or one-to-one with a member of
                        your team. If printing is difficult, you could also look at them on a tablet and note your
                        answers on a piece of paper.
                    </Typography>
                </>
            );
        case "Games":
            return (
                <>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Games
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        A variety of games that are ideal for either individuals, 1-to-1 or group sessions. Special
                        jigsaws, spot the difference, matching games and many more - offering excellent enjoyment for
                        residents of all abilities.
                    </Typography>
                </>
            );
        case "Movement":
            return (
                <>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Movement
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        Whether you are supporting someone in their own home or in a care home, you can encourage
                        movement through all aspects of daily living.
                    </Typography>
                </>
            );
        case "Occupational":
            return (
                <>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Occupational
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        Providing simple practical tasks can be an excellent way to stimulate memories, make the
                        individual feel useful and keep them active physically and mentally.
                    </Typography>
                </>
            );
        case "Sensory":
            return (
                <>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Sensory
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        A person might no longer be able to move independently or hold a conversation. However, many
                        people with dementia will respond positively to close one-to-one attention using the eyes to
                        communicate or hands to touch and make a connection.
                    </Typography>
                </>
            );
        default:
            break;
    }
}
