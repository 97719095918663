import {SYSTEM_ERROR} from "../config/CONSTANTS";
import {getDatabase, ref, get, child} from "firebase/database";
import {WEEKLY_DOWNLOADS_URL} from "./CONSTANTS";

export const getWeeklyDownloads = (identifier) => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${WEEKLY_DOWNLOADS_URL}/${identifier}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    console.info(JSON.stringify(response.content));
                    resolve(response.content);
                } else {
                    reject("No weekly downloads retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
