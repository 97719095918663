import React, {useEffect, useState} from "react";
import {useAuth} from "providers/AuthContext";
import {Button, Input, Textarea, Typography} from "@material-tailwind/react";
import ProfileImageCard from "components/ProfileImageCard";
import {functions} from "utils/firebase.js";
import {httpsCallable} from "firebase/functions";
import {ref as storageRef, getDownloadURL, uploadBytesResumable} from "firebase/storage";
import {storage} from "utils/firebase.js";
import validator from "validator";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {getCareProviderProfile, updateCareProviderProfile} from "services/profileService";

export default function ProfileView() {
    const {currentUser, updateUserName} = useAuth();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [bio, setBio] = useState();
    const [contactNumber, setContactNumber] = useState();
    const [imageURL, setImageURL] = useState();
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();

    useEffect(() => {
        setLoading(true);
        getCareProviderProfile(currentUser.uid)
            .then((profileData) => {
                setName(profileData.name ? profileData.name : "");
                setEmail(profileData.email ? profileData.email : "");
                setContactNumber(profileData && profileData.contactNumber);
                setImageURL(profileData && profileData.imageURL);
                setBio(profileData && profileData.bio);
            })
            .catch((error) => {
                showAlert(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [currentUser, setLoading, showAlert]);

    async function onClickSave() {
        if (!validator.isMobilePhone(contactNumber, ["en-AU"])) {
            showAlert("Please enter a valid phone number.");
            return;
        }

        const payload = {
            providerId: currentUser.uid,
            name: name,
            contactNumber: contactNumber,
            bio: bio,
        };
        setLoading(true);
        try {
            await updateCareProviderProfile(payload);
            await updateUserName(name);
            showAlert("Your profile has been successfully updated.");
        } catch (error) {
            showAlert("There was an error submitting your request. Please try again later.");
            console.log({error});
        } finally {
            setLoading(false);
        }
    }

    const onChangeContactNumber = ({target}) => setContactNumber(target.value);

    const hiddenFileInput = React.useRef(null);

    async function handleChange(event) {
        const fileUploaded = event.target.files[0];
        handleFile(fileUploaded);
    }

    async function onClickImageUrl() {
        console.log("onClickImageUrl");
        hiddenFileInput.current.click();
    }

    async function handleFile(file) {
        console.log(file);
        if (file && file != null) {
            const fbStorageRef = storageRef(storage, `/profiles/${currentUser.uid}.${file.type.replace("image/", "")}`);
            const uploadTask = uploadBytesResumable(fbStorageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    setLoading(true);
                },
                (error) => {
                    showAlert("Upload failed. Please try again");
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        setImageURL(url);
                        const payload = {
                            providerId: currentUser.uid,
                            imageURL: url,
                        };
                        const updateCareProviderProfile = httpsCallable(functions, "updateCareProviderProfile");
                        updateCareProviderProfile(payload)
                            .then(() => {
                                showAlert("Uploaded successfully");
                                hiddenFileInput.current.value = "";
                            })
                            .catch((error) => {
                                showAlert("Creation failed. Please try again");
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    });
                },
            );
        } else {
            showAlert("Upload failed. Please try again later.");
        }
    }

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full mb-12 xl:mb-12 px-8 pt-8 pb-8">
                    {loading && <LoadingComponent />}
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-10 border-0">
                        <div class="w-full mx-auto bg-white rounded-xl overflow-hidden">
                            <div class="md:flex w-full gap-4">
                                <div class="md:shrink-0 mt-4">
                                    <ProfileImageCard
                                        imageUrl={imageURL ? imageURL : "/img/avatarplaceholder.png"}
                                        onClickImageUrl={onClickImageUrl}
                                        hiddenFileInput={hiddenFileInput}
                                        handleChange={handleChange}
                                    />
                                </div>
                                <div class="p-8 border-4 border-yellow-500 rounded-xl mt-4">
                                    <div className="mb-6">
                                        <Input
                                            size="lg"
                                            color="yellow"
                                            type="text"
                                            label="Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <Input
                                            size="lg"
                                            color="yellow"
                                            type="text"
                                            label="Email"
                                            value={email}
                                            disabled
                                        />
                                        <Typography
                                            variant="small"
                                            color="gray"
                                            className="mt-2 flex items-center gap-1 font-small">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="-mt-px h-4 w-4">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            Please contact us if you want to change your email
                                        </Typography>
                                    </div>
                                    <div className="mb-6">
                                        <Input
                                            size="lg"
                                            color="yellow"
                                            type="tel"
                                            label="Contact Number"
                                            value={contactNumber}
                                            onChange={onChangeContactNumber}
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <Textarea
                                            size="lg"
                                            color="yellow"
                                            type="tel"
                                            label="Bio"
                                            value={bio}
                                            onChange={(e) => setBio(e.target.value)}
                                        />
                                    </div>
                                    <div className="">
                                        <Button
                                            onClick={() => onClickSave()}
                                            className="min-w-full"
                                            color="yellow">
                                            SAVE
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {openSnackbar && <SnackbarComponent />}
                </div>
            </div>
        </>
    );
}
