import {SYSTEM_ERROR} from "../config/CONSTANTS";
import {getDatabase, ref, get, child} from "firebase/database";
import {LARGE_FONT_SPARKLES_URL} from "./CONSTANTS";

export const getAllLargeFontSparkles = () => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${LARGE_FONT_SPARKLES_URL}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    const keys = Object.keys(response);
                    const retrievedLargeFontSparkles = keys.map((key) => {
                        const largeFontSparkle = response[key];
                        largeFontSparkle.displayableTags = Object.keys(largeFontSparkle.tags);
                        return largeFontSparkle;
                    });
                    resolve(retrievedLargeFontSparkles);
                } else {
                    reject("No large font sparkles retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getLargeFontSparkle = (identifier) => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${LARGE_FONT_SPARKLES_URL}/${identifier}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    response["displayableTags"] = Object.keys(response.tags);
                    resolve(response);
                } else {
                    reject("No large font sparkles retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
