import React, {useEffect, useState} from "react";
import {Typography} from "@material-tailwind/react";
import {useAuth} from "providers/AuthContext";
import DataTable from "react-data-table-component";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {getApprovedArticles, getApprovedQuizzes} from "services";

function MyQuizRequests({quizzes}) {
    const navigate = useNavigate();

    const columns = [
        {
            name: "Date of request",
            selector: (row) => row.date,
        },
        {
            name: "Quiz Title",
            selector: (row) => row.topic,
        },
    ];

    const handleRowClicked = (row) => {
        navigate(`/member/quizzes/${row.id}`);
    };

    return (
        <>
            <Typography
                variant="h6"
                className="pt-8 text-left">
                Approved Quiz Requests
            </Typography>
            {quizzes && (
                <>
                    <DataTable
                        highlightOnHover
                        pointerOnHover
                        pagination
                        selectableRowsHighlight
                        columns={columns}
                        data={quizzes}
                        onRowClicked={handleRowClicked}
                    />
                </>
            )}
        </>
    );
}

function MyArticleRequests({articles}) {
    const navigate = useNavigate();

    const columns = [
        {
            name: "Date of request",
            selector: (row) => row.date,
        },
        {
            name: "Article Description",
            selector: (row) => row.description,
        },
    ];

    const handleRowClicked = (row) => {
        navigate(`/member/articles/${row.id}`);
    };

    return (
        <>
            <Typography
                variant="h6"
                className="pt-8 text-left">
                Approved Article Requests
            </Typography>
            {articles && (
                <>
                    <DataTable
                        highlightOnHover
                        pointerOnHover
                        pagination
                        selectableRowsHighlight
                        columns={columns}
                        data={articles}
                        onRowClicked={handleRowClicked}
                    />
                </>
            )}
        </>
    );
}

export default function MyRequestsView() {
    const {currentUser} = useAuth();
    const [articles, setArticles] = useState([]);
    const [quizzes, setQuizzes] = useState([]);
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();

    useEffect(() => {
        setLoading(true);
        getApprovedArticles(currentUser.uid)
            .then((res) => {
                setArticles(res);
                return getApprovedQuizzes(currentUser.uid);
            })
            .then((res) => {
                setQuizzes(res);
            })
            .catch((error) => {
                showAlert("Error retrieving requests. Please try again.");
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [currentUser.uid, setLoading, showAlert]);

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="py-28 px-4">
                <div className="container mx-auto mb-20 text-center">
                    <Typography
                        color="yellow"
                        className="mb-2 font-bold uppercase">
                        Request - A
                    </Typography>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        My Requests
                    </Typography>

                    {articles && (
                        <>
                            <MyArticleRequests articles={articles} />
                        </>
                    )}
                    {quizzes && (
                        <>
                            <MyQuizRequests quizzes={quizzes} />
                        </>
                    )}
                </div>
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
