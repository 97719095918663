import {SYSTEM_ERROR} from "../config/CONSTANTS";
import {getDatabase, ref, get, child} from "firebase/database";
import {NEWSPAPERS_URL} from "./CONSTANTS";

export const getAllNewspapers = () => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${NEWSPAPERS_URL}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    const keys = Object.keys(response);
                    const retrievedNewspapers = keys.map((key) => {
                        const newspaper = response[key];
                        newspaper.displayableTags = Object.keys(newspaper.tags);
                        return newspaper;
                    });
                    resolve(retrievedNewspapers);
                } else {
                    reject("No newspapers retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getNewspaper = (identifier) => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${NEWSPAPERS_URL}/${identifier}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    response["displayableTags"] = Object.keys(response.tags);
                    resolve(response);
                } else {
                    reject("No newspaper retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
