import React, {useEffect, useState, useMemo} from "react";
import MaterialReactTable from "material-react-table";
import {functions} from "utils/firebase.js";
import {httpsCallable} from "@firebase/functions";
import Select from "react-select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
        />
    );
});

export default function TestClaims() {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [users, setUsers] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [selectedUser, setSelectedUser] = useState();
    const [selectedAction, setSelectedAction] = useState();
    const [selectedUserRole, setSelectedUserRole] = useState();

    const userRoles = [
        {value: "CareProvider", label: "Care Provider"},
        {value: "Admin", label: "System Administator"},
        {value: "Content", label: "Content Administrator"},
    ];

    const actions = [
        {value: "trial", label: "Trial User"},
        {value: "standard", label: "Standard User"},
        {value: "premium", label: "Premium User"},
        {value: "owner", label: "Owner"},
    ];

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "email",
                header: "User Email",
            },
            {
                accessorKey: "emailVerified",
                header: "Email Verified",
            },
            {
                accessorKey: "role",
                header: "Role",
            },
            {
                accessorKey: "subscriptionType",
                header: "Subscription Type",
            },
        ],
        [],
    );

    useEffect(() => {
        async function fetchData() {
            setOpenBackdrop(true);
            try {
                await getAllUsers();
            } catch (error) {
                console.log(error);
                setSnackbarText("Failed to get users. Please try again");
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            } finally {
                setOpenBackdrop(false);
            }
        }
        fetchData();
    }, []);

    const getAllUsers = async () => {
        const getAllDailySparkleUsers = httpsCallable(functions, "getAllDailySparkleUsers");
        const result = await getAllDailySparkleUsers();
        if (result.data.success === true) {
            console.log(`Get All Users Response: ${JSON.stringify(result.data)}`);
            const users = result.data.response.map((user) => {
                return {
                    uid: user.uid,
                    email: user.email,
                    emailVerified: user.emailVerified === true ? "Verified" : "Not-verified",
                    role: user.customClaims.role,
                    subscriptionType: user.customClaims.subscription,
                };
            });
            setUsers(users);
        }
    };

    useEffect(() => {
        const keys = Object.keys(rowSelection);
        if (keys && keys.length === 1) {
            const indexFromKey = keys[0];
            setSelectedUser(users[indexFromKey]);
        }
    }, [rowSelection, selectedUser, users]);

    useEffect(() => {}, [selectedUser]);

    const handleActionChange = (selectedOption) => {
        console.log(selectedOption);
        setSelectedAction(selectedOption);
    };

    const handleUserRoleChange = (selectedOption) => {
        console.log(selectedOption);
        setSelectedUserRole(selectedOption);
    };

    const setSubscription = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        try {
            const changeSubscription = httpsCallable(functions, "changeSubscription");
            const payload = {
                userId: selectedUser.uid,
                subscriptionType: selectedAction.value,
            };
            await changeSubscription(payload);
            await getAllUsers();
        } catch (error) {
            console.log(error);
            setSnackbarText("Update failed. Please try again");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
        } finally {
            setOpenBackdrop(false);
        }
    };

    const setClaim = async (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        console.log(selectedAction);

        try {
            const changeSubscription = httpsCallable(functions, "changeSubscription");
            const payload = {
                userId: selectedUser.uid,
                userRole: selectedUserRole.value,
                subscriptionType: selectedAction.value,
            };
            await changeSubscription(payload);
            await getAllUsers();
        } catch (error) {
            console.log(error);
            setSnackbarText("Update failed. Please try again");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
        } finally {
            setOpenBackdrop(false);
        }
    };

    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="flex flex-wrap">
                <div className="w-full mb-12 xl:mb-12 px-8 pt-8 pb-8">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">Daily Sparkle Users</h6>
                            </div>
                        </div>
                        <hr className="mt-1 border-b-1 border-blueGray-300" />
                        <div className="flex-auto px-4 lg:px-4 py-4 pt-0">
                            <div className="overflow-x-auto py-4">
                                <MaterialReactTable
                                    columns={columns}
                                    data={users}
                                    enableRowSelection
                                    enableMultiRowSelection={false} //shows radio buttons instead of checkboxes
                                    onRowSelectionChange={setRowSelection}
                                    state={{rowSelection}}
                                    enableTopToolbar={true}
                                    enableColumnActions={false}
                                />
                            </div>
                        </div>
                    </div>
                    {selectedUser && (
                        <>
                            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                                <div className="rounded-t mb-0 px-6 py-6">
                                    <div className="text-center flex justify-between">
                                        <h6 className="text-blueGray-700 text-xl font-bold">
                                            Set Subscription Type in claims
                                        </h6>
                                        <button
                                            onClick={(e) => setSubscription(e)}
                                            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                            type="button">
                                            SUBMIT
                                        </button>
                                    </div>
                                </div>
                                <hr className="mt-1 border-b-1 border-blueGray-300" />
                                <div className="flex-auto px-4 lg:px-4 py-4 pt-0">
                                    <form>
                                        <div className="flex flex-wrap">
                                            <div className="w-full pt-4 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Select Subscription Type
                                                    </label>
                                                    <Select
                                                        value={selectedAction}
                                                        options={actions}
                                                        onChange={(e) => handleActionChange(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                                <div className="rounded-t mb-0 px-6 py-6">
                                    <div className="text-center flex justify-between">
                                        <h6 className="text-blueGray-700 text-xl font-bold">Set User Role in claims</h6>
                                        <button
                                            onClick={(e) => setClaim(e)}
                                            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                            type="button">
                                            SUBMIT
                                        </button>
                                    </div>
                                </div>

                                <hr className="mt-1 border-b-1 border-blueGray-300" />
                                <div className="flex-auto px-4 lg:px-4 py-4 pt-0">
                                    <form>
                                        <div className="flex flex-wrap">
                                            <div className="w-full pt-4 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Select User Role
                                                    </label>
                                                    <Select
                                                        value={selectedUserRole}
                                                        options={userRoles}
                                                        onChange={(e) => handleUserRoleChange(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}>
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{width: "100%"}}>
                        {snackbarText}
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
}
