import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {IconButton, List, ListItem, ListItemSuffix, Typography} from "@material-tailwind/react";
import {Button, Card, CardBody, ButtonGroup, CardFooter} from "@material-tailwind/react";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import {getMultipleChoiceQuiz} from "services";

function MultipleChoiceQuizCard({index, question, answers, answer, onNext, onPrev, numberOfQuestions}) {
    const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);

    function reset() {
        setShowCorrectAnswer(false);
    }

    function onClickNext() {
        if (index + 1 === numberOfQuestions) {
            return;
        }
        reset();
        onNext();
    }

    function onClickPrev() {
        if (index === 0) {
            return;
        }
        reset();
        onPrev();
    }

    function answerClicked() {
        setShowCorrectAnswer(true);
    }

    return (
        <section className="place-items-center p-4 mx-auto ">
            <Card>
                <CardBody>
                    <Typography
                        variant="h3"
                        color="blue-gray"
                        className="font-medium text-center">
                        {question}
                    </Typography>

                    <List>
                        <ListItem onClick={() => answerClicked(0)}>
                            {answers[0]}
                            <ListItemSuffix
                                className={`${showCorrectAnswer ? "visible" : "invisible"} ${
                                    answers[0] === answer ? "bg-green-500" : "bg-red-500"
                                } `}>
                                <IconButton
                                    hidden={showCorrectAnswer ? false : true}
                                    variant="text"
                                    color="blue-gray">
                                    {answers[0] === answer ? (
                                        <CheckIcon className="text-white" />
                                    ) : (
                                        <ClearIcon className="text-white" />
                                    )}
                                </IconButton>
                            </ListItemSuffix>
                        </ListItem>
                        <ListItem onClick={() => answerClicked(1)}>
                            {answers[1]}
                            <ListItemSuffix
                                className={`${showCorrectAnswer ? "visible" : "invisible"} ${
                                    answers[1] === answer ? "bg-green-500" : "bg-red-500"
                                } `}>
                                <IconButton
                                    variant="text"
                                    color="blue-gray">
                                    {answers[1] === answer ? (
                                        <CheckIcon className="text-white" />
                                    ) : (
                                        <ClearIcon className="text-white" />
                                    )}
                                </IconButton>
                            </ListItemSuffix>
                        </ListItem>
                        <ListItem onClick={() => answerClicked(2)}>
                            {answers[2]}
                            <ListItemSuffix
                                className={`${showCorrectAnswer ? "visible" : "invisible"} ${
                                    answers[2] === answer ? "bg-green-500" : "bg-red-500"
                                } `}>
                                <IconButton
                                    variant="text"
                                    color="blue-gray">
                                    {answers[2] === answer ? (
                                        <CheckIcon className="text-white" />
                                    ) : (
                                        <ClearIcon className="text-white" />
                                    )}
                                </IconButton>
                            </ListItemSuffix>
                        </ListItem>
                    </List>

                    <ButtonGroup
                        className="pt-4"
                        color="yellow"
                        fullWidth
                        ripple={true}>
                        <Button onClick={() => onClickPrev()}>{"<"}</Button>
                        <Button onClick={() => onClickNext()}>{">"}</Button>
                    </ButtonGroup>
                </CardBody>
                <CardFooter>
                    <Typography
                        variant="small"
                        className="text-center">
                        {index + 1} of {numberOfQuestions}
                    </Typography>
                </CardFooter>
            </Card>
        </section>
    );
}

export default function MutipleChoiceQuizView() {
    const params = useParams();
    const [questionsAndAnswers, setQuestionsAndAnswers] = useState();
    const [currentQuestionAndAnswer, setCurrentQuestionAndAnswer] = useState();

    const dayOfMonth = params.dayOfMonth;
    const month = params.month;

    useEffect(() => {
        getMultipleChoiceQuiz(month, dayOfMonth)
            .then((res) => {
                setQuestionsAndAnswers(res);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [dayOfMonth, month]);

    useEffect(() => {
        if (questionsAndAnswers !== undefined) {
            setCurrentQuestionAndAnswer(questionsAndAnswers[0]);
        }
    }, [questionsAndAnswers]);

    async function onNext() {
        const nextQuestionAndAnswer = questionsAndAnswers[currentQuestionAndAnswer.index + 1];
        setCurrentQuestionAndAnswer(nextQuestionAndAnswer);
    }

    async function onPrev() {
        const previousQuestionAndAnswer = questionsAndAnswers[currentQuestionAndAnswer.index - 1];
        setCurrentQuestionAndAnswer(previousQuestionAndAnswer);
    }

    return (
        <>
            <header className="px-4 pr-4">
                <>
                    <div className="w-w-full container mx-auto pt-12 pb-8">
                        <Typography
                            variant="h1"
                            color="blue-gray"
                            className="mx-auto mb-8 w-full leading-tight lg:max-w-2xl text-center">
                            Multiple Choice Quiz
                        </Typography>

                        <Typography
                            variant="paragrah"
                            color="blue-gray"
                            className="mx-auto mb-12 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20 text-center">
                            Try our daily, multiple choice quizzes.
                        </Typography>
                        <div className="mx-auto mb-12 w-full lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20">
                            {currentQuestionAndAnswer && questionsAndAnswers && (
                                <MultipleChoiceQuizCard
                                    index={currentQuestionAndAnswer.index}
                                    question={currentQuestionAndAnswer.question}
                                    answers={currentQuestionAndAnswer.answers}
                                    answer={currentQuestionAndAnswer.answer}
                                    onNext={onNext}
                                    onPrev={onPrev}
                                    numberOfQuestions={questionsAndAnswers.length}
                                />
                            )}
                        </div>
                    </div>
                </>
            </header>
        </>
    );
}
