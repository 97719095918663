import SimpleNavbar from "components/SimpleNavbar";
import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import AboutUsView from "features/AboutUs/AboutUsView";
import HomeView from "features/Home/HomeView";
import HowItWorksView from "features/HowItWorks/HowItWorksView";
import PricingView from "features/Pricing/PricingView";
import ContactUsView from "features/ContactUs/ContactUsView";
import LoginView from "features/Login/LoginView";
import RegisterView from "features/Register/RegisterView";
import ResetPasswordView from "features/ResetPassword/ResetPasswordView";
import CheckYourEmailView from "features/ResetPassword/CheckYourEmailView";
import ContactUsThanksView from "features/ContactUs/ContactUsThanksView";
import Details from "features/HowItWorks/details/Details";
import RegisterForTrialView from "features/Register/RegisterForTrialView";
import {
    ABOUT,
    CHECK_YOUR_EMAIL_WILDCARD,
    CONTACT_US,
    CONTACT_US_THANKS,
    HOME,
    HOW_IT_WORKS,
    HOW_IT_WORKS_WILDCARD,
    LOGIN,
    PRICING,
    REGISTER,
    REGISTER_FOR_TRIAL,
    RESET_PASSWORD,
    WILDCARD,
} from "navigation/CONSTANTS";

export default function Marketing() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen((cur) => !cur);

    useEffect(() => {
        window.addEventListener("resize", () => window.innerWidth >= 960 && setOpen(false));
    }, []);

    return (
        <>
            <SimpleNavbar
                handleOpen={handleOpen}
                open={open}
            />
            <div className="flex flex-col">
                <Routes>
                    <Route
                        path={LOGIN}
                        element={<LoginView />}
                    />

                    <Route
                        path={REGISTER}
                        element={<RegisterView />}
                    />

                    <Route
                        path={REGISTER_FOR_TRIAL}
                        element={<RegisterForTrialView />}
                    />

                    <Route
                        path={RESET_PASSWORD}
                        element={<ResetPasswordView />}
                    />

                    <Route
                        path={CHECK_YOUR_EMAIL_WILDCARD}
                        element={<CheckYourEmailView />}
                    />

                    <Route
                        path={WILDCARD}
                        element={<HomeView />}
                    />

                    <Route
                        path={HOME}
                        element={<HomeView />}
                    />

                    <Route
                        path={ABOUT}
                        element={<AboutUsView />}
                    />

                    <Route
                        path={HOW_IT_WORKS}
                        element={<HowItWorksView />}
                    />

                    <Route
                        path={HOW_IT_WORKS_WILDCARD}
                        element={<Details />}
                    />

                    <Route
                        path={PRICING}
                        element={<PricingView />}
                    />

                    <Route
                        path={CONTACT_US}
                        element={<ContactUsView />}
                    />

                    <Route
                        path={CONTACT_US_THANKS}
                        element={<ContactUsThanksView />}
                    />
                </Routes>
            </div>
        </>
    );
}
