import React, {useEffect, useMemo, useState} from "react";
import {functions} from "utils/firebase.js";
import MaterialReactTable from "material-react-table";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {Typography, Button} from "@material-tailwind/react";
import {getDatabase, ref, get, child} from "firebase/database";
import Datepicker from "react-tailwindcss-datepicker";
import {useAuth} from "providers/AuthContext";
import {httpsCallable} from "firebase/functions";
import dayjs from "dayjs";
var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
var tz = require("dayjs/plugin/timezone");
dayjs.extend(tz);

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
        />
    );
});

export default function CreateWeeklyDownloads() {
    const {currentUser} = useAuth();
    const [newspapers, setNewspapers] = useState([]);
    const [selectedNewspapers, setSelectedNewspapers] = useState();
    const [downloadWeekCommencing, setDownloadWeekCommencing] = useState();
    const [rowSelection, setRowSelection] = useState({});
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState();
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const database = getDatabase();

    const handleValueChange = (newValue) => {
        console.log(newValue);
        setDownloadWeekCommencing(newValue);
    };

    const sparklesColumns = useMemo(
        () => [
            {
                accessorKey: "name",
                header: "Name",
            },
            {
                accessorKey: "tags",
                header: "Tags",
            },
            {
                accessorKey: "filePath",
                header: "Storage File Path",
            },
        ],
        [],
    );

    useEffect(() => {
        async function fetchData() {
            const dbRef = ref(database);
            setOpenBackdrop(true);
            try {
                const response = (await get(child(dbRef, `newspapers`))).val();
                const keys = Object.keys(response);
                const retrievedNewspapers = keys.map((key) => {
                    const download = response[key];
                    return {
                        blurb: download.blurb,
                        name: download.name,
                        tags: Object.keys(download.tags),
                        filePath: download.filePath,
                        identifier: download.id,
                    };
                });
                setNewspapers(retrievedNewspapers);
            } catch (error) {
                setSnackbarSeverity("error");
                setSnackbarText("Error retrieving weekly downloads. Please try again.");
                setOpenSnackbar(true);
                console.error(error);
            } finally {
                setOpenBackdrop(false);
            }
        }
        fetchData();
    }, [database]);

    useEffect(() => {
        const keys = Object.keys(rowSelection);
        const selected = keys.map((key) => newspapers[key]);
        setSelectedNewspapers(selected);
    }, [rowSelection, newspapers]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const submitWeeklyDownload = async () => {
        const selectedDate = downloadWeekCommencing.startDate;
        const payload = {
            availableFrom: dayjs.utc(selectedDate).toString(),
            weekOfYear: dayjs.utc(selectedDate).week().toString(),
            year: dayjs.utc(selectedDate).year().toString(),
            content: selectedNewspapers,
            createdBy: currentUser.uid,
            createdByDisplayName: currentUser.displayName,
            createdOn: dayjs.utc().toString(),
        };
        const createWeeklyDownload = httpsCallable(functions, "createWeeklyDownload");
        setOpenBackdrop(true);
        try {
            await createWeeklyDownload(payload);
            setSnackbarText("Weekly download created");
            setSnackbarSeverity("success");
            setOpenSnackbar(true);
            setSelectedNewspapers([]);
            setRowSelection({});
            setDownloadWeekCommencing(null);
        } catch (error) {
            console.log(error);
            setSnackbarText("Weekly download creation failed. Please try again");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
        } finally {
            setOpenBackdrop(false);
        }
    };

    return (
        <>
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="flex flex-wrap">
                <div className="w-full mb-12 xl:mb-12 px-8 pt-8 pb-8">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <Typography variant="h3">Select Newspapers</Typography>
                            </div>
                        </div>
                        <hr className="mt-1 border-b-1 border-blueGray-300" />
                        <div className="flex-auto px-4 lg:px-4 py-0 pt-4">
                            <div className="overflow-x-auto py-0">
                                <Typography variant="paragraph">
                                    A list of available newspapers and carers notes to include in the weekly downloads.
                                </Typography>
                            </div>
                        </div>
                        <div className="flex-auto px-4 lg:px-4 py-4 pt-0">
                            <div className="overflow-x-auto py-4">
                                <MaterialReactTable
                                    columns={sparklesColumns}
                                    data={newspapers}
                                    enableRowSelection
                                    enableMultiRowSelection={true} //shows radio buttons instead of checkboxes
                                    onRowSelectionChange={setRowSelection}
                                    state={{rowSelection}}
                                    enableTopToolbar={true}
                                    enableColumnActions={false}
                                />
                            </div>
                        </div>
                        {selectedNewspapers && selectedNewspapers.length > 0 && (
                            <div className="flex-auto px-4 lg:px-4 py-4 pt-0 gap-6">
                                <div className="overflow-x-auto py-4">
                                    <Typography variant="paragraph">
                                        The following selected newspapers will be included in the weekly download.
                                    </Typography>
                                    <MaterialReactTable
                                        columns={sparklesColumns}
                                        data={selectedNewspapers}
                                        enableTopToolbar={false}
                                        enableColumnActions={false}
                                    />
                                </div>
                                <Typography variant="paragraph">Available from:</Typography>
                                <Datepicker
                                    displayFormat={"DD-MM-YYYY"}
                                    asSingle={true}
                                    showWeekNumbers
                                    value={downloadWeekCommencing}
                                    onChange={handleValueChange}
                                />
                                <div className="overflow-x-auto py-4 min-w-full">
                                    <Button
                                        onClick={() => submitWeeklyDownload()}
                                        className="min-w-full"
                                        color="yellow">
                                        SUBMIT
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{width: "100%"}}>
                    {snackbarText}
                </Alert>
            </Snackbar>
        </>
    );
}
