import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {Card, CardBody, Typography, Button, CardFooter} from "@material-tailwind/react";
import {ref as storageRef, getStorage, getDownloadURL} from "firebase/storage";
import {ArrowLongRightIcon} from "@heroicons/react/24/solid";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {getWeeklyDownloads} from "services";
var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);

export default function WeeklyDownloadView() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [weeklyDownload, setWeeklyDownload] = useState();
    const [contents, setContents] = useState();
    const [selectedContent, setSelectedContent] = useState();
    const weekIdentifier = `${dayjs().year().toString()}-${dayjs().week().toString()}`;
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();

    useEffect(() => {
        const weeklyIdentifier = params.weeklyIdentifier;
        setLoading(true);
        getWeeklyDownloads(weeklyIdentifier)
            .then((res) => {
                setWeeklyDownload(res);
            })
            .catch((error) => {
                showAlert(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [params.weeklyIdentifier, setLoading, showAlert]);

    useEffect(() => {
        async function fetchData() {
            setContents(weeklyDownload.content);
        }
        fetchData();
    }, [weeklyDownload]);

    const onClick = (item) => {
        setSelectedContent(item);
    };

    const onClickDownload = () => {
        const storage = getStorage();
        setLoading(true);
        getDownloadURL(storageRef(storage, `weeklyDownloads/${weekIdentifier}.zip`))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = "document";
                window.location.href = url;
                showAlert("Successfully downloaded");
            })
            .catch((error) => {
                showAlert("Something went wrong. Please try again");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading && <LoadingComponent />}
            {searchParams.get("preview") && (
                <div class="flex items-stretch flex-wrap p-4 mb-4 gap-4 text-center justify-center">
                    <Typography
                        variant="h1"
                        color="red">
                        THIS IS A PREVIEW
                    </Typography>
                </div>
            )}

            <div class="flex items-stretch flex-wrap p-4 mb-4 gap-4">
                <Typography variant="paragraph">
                    With Daily Sparkle, you have the ability to download newspapers and carer notes, ensuring you stay
                    informed and equipped to provide the best care possible. We understand the importance of staying
                    up-to-date with the latest news and trends, as well as having access to valuable resources that
                    assist you in your caregiving role.
                </Typography>
                <Typography variant="paragraph">
                    By downloading newspapers, you can immerse yourself in a wealth of knowledge from around the world.
                    Stay informed about current events, health advancements, lifestyle tips, and more. Enhance your
                    understanding of the world and discover new insights that can positively impact your caregiving
                    approach.
                </Typography>
            </div>
            <div className="grid flex-auto grid-cols-2 gap-4">
                <div class="flex items-stretch flex-wrap p-4 mb-4 gap-2">
                    {contents &&
                        contents.map((item) => (
                            <div class="min-w-full">
                                <Card
                                    onClick={() => onClick(item)}
                                    className={`bg-white h-full border-yellow-500 border-2 hover:bg-slate-100 group/${item.identifier}`}
                                    key={item.identifier}>
                                    <CardBody>
                                        <Typography
                                            variant="h5"
                                            color="blue-gray">
                                            {item.name}
                                        </Typography>
                                    </CardBody>
                                </Card>
                            </div>
                        ))}
                </div>
                <div class="flex items-stretch flex-wrap p-4 mb-4 gap-6">
                    {selectedContent && (
                        <div class="flex min-w-full w-full">
                            <div class="flex min-w-full min-h-full w-full">
                                <Link
                                    to={
                                        searchParams.get("preview")
                                            ? `/admin/newspapers/${selectedContent.identifier}?preview=true`
                                            : `/member/newspapers/${selectedContent.identifier}`
                                    }>
                                    <Card className={`bg-white min-w-full h-full border-yellow-500 border-2`}>
                                        <CardBody>
                                            <Typography
                                                variant="lead"
                                                color="blue-gray">
                                                {selectedContent.name}
                                            </Typography>
                                            <Typography
                                                className="line-clamp-6"
                                                variant="paragraph"
                                                color="blue-gray">
                                                {selectedContent.blurb}
                                            </Typography>
                                        </CardBody>
                                        <CardFooter className="pt-0">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="flex justify-end items-center gap-2">
                                                Read Now
                                                <ArrowLongRightIcon
                                                    strokeWidth={2}
                                                    className="w-4 h-4"
                                                />
                                            </Typography>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div class="flex items-stretch flex-wrap p-4 mb-4">
                <Button
                    color="yellow"
                    onClick={() => onClickDownload()}
                    fullWidth
                    size="lg">
                    Download All
                </Button>
            </div>
            {/* </div> */}
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
