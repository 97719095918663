import React from "react";
import {Card, CardBody, Typography, Button} from "@material-tailwind/react";
import {
    BuildingOfficeIcon,
    ChatBubbleLeftIcon,
    ArrowTrendingUpIcon,
    LinkIcon,
    CalendarDaysIcon,
    ClipboardDocumentListIcon,
    NewspaperIcon,
    CloudArrowDownIcon,
    ClipboardDocumentCheckIcon,
    QuestionMarkCircleIcon,
    VideoCameraIcon,
    ChatBubbleLeftEllipsisIcon,
} from "@heroicons/react/24/outline";
import TrialBanner from "components/TrialBanner";
import {useNavigate} from "react-router-dom";

function FeatureCard({icon, title, children, link}) {
    const navigate = useNavigate();

    async function onClickReadMore() {
        navigate(link);
    }

    return (
        <Card
            color="transparent"
            shadow={false}>
            <CardBody className="grid justify-center text-center">
                <div className="mx-auto mb-6 grid h-12 w-12 place-items-center rounded-full bg-yellow-500 p-2.5 text-white shadow">
                    {icon}
                </div>
                <Typography
                    variant="h5"
                    color="blue-gray"
                    className="mb-2">
                    {title}
                </Typography>
                <Typography className="px-8 font-normal !text-gray-500">{children}</Typography>
                {link && (
                    <Button
                        onClick={() => onClickReadMore()}
                        className="mt-8 mx-auto"
                        size="sm"
                        color="yellow">
                        Read More
                    </Button>
                )}
            </CardBody>
        </Card>
    );
}

const features = [
    {
        icon: (
            <CloudArrowDownIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
        title: "Weekly Downloads",
        description:
            "With Daily Sparkle, you have the ability to download newspapers and carer notes, ensuring you stay informed and equipped to provide the best care possible.",
        link: "weekly-downloads",
    },
    {
        icon: (
            <NewspaperIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
        title: "Articles",
        description:
            "you can immerse yourself in a wealth of knowledge from around the world. Stay informed about current events, health advancements, lifestyle tips, and more.",
        link: "articles",
    },
    {
        icon: (
            <ClipboardDocumentCheckIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
        title: "Activities",
        description:
            "A vast range of activities to entertain and stimulate your residents. You will find activities for individuals to play on their own, 1-to-1 and group sessions.",
        link: "activities",
    },
    {
        icon: (
            <QuestionMarkCircleIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
        title: "Quizzes",
        description:
            "These quizzes, word searches, crosswords and games are a great way of keeping your residents minds stimulated.",
        link: "quizzes",
    },
    {
        icon: (
            <VideoCameraIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
        title: "Videos",
        description:
            "There is also a fascinating collections of old videos on a range of subjects. Ideal for reminiscence, starting conversations as well as fun entertainment for individuals.",
        link: "videos",
    },
    {
        icon: (
            <ChatBubbleLeftEllipsisIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
        title: "Request-A",
        description:
            "Customise your Daily Sparkle experience by requesting tailored, unique content for your residents. ",
        link: "request-an-article",
    },
];

const benefits = [
    {
        icon: (
            <BuildingOfficeIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
        title: "Challenge fear in the workplace",
        description:
            "The Daily Sparkle is a user-friendly communication aid with minimal learning curve, and for those needing assistance, a quick-start guide offers an introduction and foundation for reminiscence training.",
    },
    {
        icon: (
            <ArrowTrendingUpIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
        title: "Easy learning curve",
        description:
            "Don't get your heart broken by people we love, even that we give them all we have. Then we lose family over time. As we live, our hearts turn colder.",
    },
    {
        icon: (
            <ChatBubbleLeftIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
        title: "Encourage your staff to engage",
        description:
            "Task-focused care home employees, often due to inexperience or language barriers, may lack the confidence to interact, but the Daily Sparkle can help initiate conversations, allowing staff to connect while attending to their duties.",
    },
    {
        icon: (
            <LinkIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
        title: "Foster Connections",
        description:
            "The Daily Sparkle, with a readership of over 100,000, has enhanced the lives of many dementia patients by supporting care homes, hospitals, and day centres in facilitating meaningful activities and encouraging memory-sharing.",
    },
    {
        icon: (
            <ClipboardDocumentListIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
        title: "Routine",
        description:
            "The Daily Sparkle offers a comforting routine in a disorienting world, providing familiarity and aiding orientation with clear date references.",
    },
    {
        icon: (
            <CalendarDaysIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
        title: "Planning",
        description:
            "The Daily Sparkle provides monthly plans to help activity coordinators plan their future activities program, giving clear frameworks to build on. Knowing what topics are coming up, you can now plan in advance specific activity sessions based on the content featured.",
    },
];

export default function HowItWorksView() {
    const navigate = useNavigate();

    async function onClickStartFreeTrial() {
        navigate("/register-for-trial");
    }

    return (
        <>
            <div>
                <TrialBanner onClickStartFreeTrial={onClickStartFreeTrial} />
            </div>

            <section className="pt-12 px-4">
                <div className="container mx-auto mb-20 text-center">
                    <Typography
                        color="yellow"
                        className="mb-2 font-bold uppercase">
                        how it works
                    </Typography>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Activities for People Living With Dementia
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 !text-gray-500 lg:w-8/12 lg:px-8">
                        Australia's leading activity resource company enhancing the quality of life for people living
                        with dementia.
                    </Typography>
                </div>

                <div className="container mx-auto grid grid-cols-1 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
                    {features.map(({icon, title, description, link}) => (
                        <FeatureCard
                            key={title}
                            icon={icon}
                            title={title}
                            link={link}>
                            {description}
                        </FeatureCard>
                    ))}
                </div>

                <div className="w-full lg:container lg:mx-auto mb-8">
                    <img
                        src="/img/bg-carehomes-banner.jpeg"
                        alt="credit cards"
                        className="h-96 w-full rounded-xl object-cover md:h-[48rem] lg:h-[56rem]"
                    />
                </div>

                <div className="container mx-auto mb-8 text-center">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Benefits of Daily Sparkle
                    </Typography>
                </div>

                <div className="container mx-auto grid grid-cols-1 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
                    {benefits.map(({icon, title, description}) => (
                        <FeatureCard
                            key={title}
                            icon={icon}
                            title={title}>
                            {description}
                        </FeatureCard>
                    ))}
                </div>
            </section>
        </>
    );
}
