import React, {useState} from "react";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {useSnackbar} from "providers/SnackbarContext";
import {SnackbarComponent} from "components/SnackbarComponent";
import {Input} from "@material-tailwind/react";
import {callCloudFunction} from "services";

class AddVideoPayload {
    constructor(videoId, videoName, videoBlurb) {
        this.videoId = videoId;
        this.videoName = videoName;
        this.videoBlurb = videoBlurb;
    }
}

export default function AddVideoView() {
    const [videoId, setVideoId] = useState();
    const [videoName, setVideoName] = useState();
    const [videoBlurb, setVideoBlurb] = useState();
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();

    async function submitVideo() {
        if ((videoId == null) | (videoName == null) | (videoId === undefined) | (videoName === undefined)) {
            showAlert("Fill in both URL and name.");
            return;
        }

        setLoading(true);
        const payload = AddVideoPayload(videoId, videoName, videoBlurb);
        callCloudFunction("addVideo", payload)
            .then(() => {
                setVideoId("");
                setVideoBlurb("");
            })
            .catch((error) => {
                console.error(error);
                showAlert(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            {loading && <LoadingComponent />}
            <div className="flex flex-wrap">
                <div className="w-full mb-12 xl:mb-12 px-8 pt-8 pb-8">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">Create the video record</h6>
                                <button
                                    onClick={(e) => submitVideo()}
                                    className="bg-yellow-500 text-white active:bg-yellow-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                    type="button">
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                            <form>
                                <div className="flex flex-wrap">
                                    <div className="w-full pt-8 px-4">
                                        <div className="relative w-full mb-3 bg-white">
                                            <Input
                                                type="text"
                                                label="Video Identifier"
                                                color="yellow"
                                                value={videoId}
                                                onChange={(e) => setVideoId(e.target.value)}
                                            />
                                        </div>
                                        <div className="relative w-full mb-3 bg-white">
                                            <Input
                                                className="pt-4"
                                                type="text"
                                                label="Video Name"
                                                color="yellow"
                                                value={videoName}
                                                onChange={(e) => setVideoName(e.target.value)}
                                            />
                                        </div>
                                        <div className="relative w-full mb-3 bg-white">
                                            <Input
                                                className="pt-4"
                                                type="text"
                                                label="Video Blurb"
                                                color="yellow"
                                                value={videoBlurb}
                                                onChange={(e) => setVideoBlurb(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
