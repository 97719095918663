import TestFunctionsCard from "components/TestFunctionsCard.js";

export default function TestFunctions() {
    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full mb-12 xl:mb-12 px-8 pt-8 pb-8">
                    <TestFunctionsCard />
                </div>
            </div>
        </>
    );
}
