import {SYSTEM_ERROR} from "../config/CONSTANTS";
import {httpsCallable} from "firebase/functions";
import {functions} from "utils/firebase.js";
import {getDatabase, ref, get, child} from "firebase/database";
import {ARTICLES_URL, QUIZZES_URL} from "./CONSTANTS";

export const requestArticle = async (payload) => {
    try {
        const requestArticleCallable = httpsCallable(functions, "requestArticle");
        await requestArticleCallable(payload);
    } catch (error) {
        throw new Error("There was an error submitting your request. Please try again later.");
    }
};

export const requestQuiz = async (payload) => {
    try {
        const requestQuizCallable = httpsCallable(functions, "requestQuiz");
        await requestQuizCallable(payload);
    } catch (error) {
        throw new Error("There was an error submitting your request. Please try again later.");
    }
};

export const getApprovedArticles = (userId) => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${ARTICLES_URL}/${userId}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const returnedVal = snapshot.val();
                    const keys = Object.keys(returnedVal);
                    const retrievedArticleRequests = keys.map(function (key) {
                        const retrievedArticleRequest = returnedVal[key];
                        return {
                            id: key,
                            date: retrievedArticleRequest.date,
                            description: retrievedArticleRequest.description,
                            title: retrievedArticleRequest.title,
                        };
                    });
                    resolve(retrievedArticleRequests);
                } else {
                    resolve([]);
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getApprovedQuizzes = (userId) => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${QUIZZES_URL}/${userId}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const returnedVal = snapshot.val();
                    const keys = Object.keys(returnedVal);
                    const retrievedQuizRequests = keys.map(function (key) {
                        const retrievedQuizRequest = returnedVal[key];
                        return {
                            id: key,
                            date: retrievedQuizRequest.date,
                            topic: retrievedQuizRequest.topic,
                            requestedBy: retrievedQuizRequest.requestedBy,
                        };
                    });
                    resolve(retrievedQuizRequests);
                } else {
                    resolve([]);
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
