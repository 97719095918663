import React from "react";
import {Typography, Card} from "@material-tailwind/react";
import RegisterCarer from "components/RegisterCarer";
import {useAuth} from "providers/AuthContext";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";

function StatsCard({count, title}) {
    return (
        <Card
            color="transparent"
            shadow={false}>
            <Typography
                variant="lead"
                className="font-bold"
                color="blue-gray">
                {count}
            </Typography>
            <Typography
                variant="paragraph"
                className="my-2 font-normal !text-gray-500 lg:w-[16rem]">
                {title}
            </Typography>
        </Card>
    );
}

const stats = [
    {
        count: "Articles",
        title: "Daily Sparkles Australia offers a diverse range of engaging articles on our platform, catering to a wide array of interests and preferences.",
    },
    {
        count: "Activities",
        title: "Carefully crafted, ensuring that each activity is not only engaging but also cognitively stimulating.",
    },
    {
        count: "Quizzes",
        title: "Trigger memories and encouraging residents to share their experiences and stories through interactive quizzes",
    },
    {
        count: "Request-A",
        title: "Merging groundbreaking technology with the invaluable human touch to create custom articles and quizzes.",
    },
    {
        count: "Newspapers",
        title: "Collections of articles and carer's notes to elevate your relationship building and increase participation.",
    },
    {
        count: "24/7 access",
        title: "Daily Sparkles Australia offers round-the-clock, 24/7 access to our platform, ensuring continuous and convenient availability for our users.",
    },
    {
        count: "Full customer support",
        title: "Daily Sparkles Australia provides full customer support on our platform, ensuring a smooth and user-friendly experience for all our clients.",
    },
    {
        count: "Full training support",
        title: "Daily Sparkles Australia offers comprehensive training on our platform, ensuring a seamless and effective experience for all users.",
    },
];

export default function RegisterForTrialView() {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const {startTrial} = useAuth();
    const navigate = useNavigate();

    async function onCompletedRegisterSignUp() {
        await startTrial();
        navigate("/member/landing", {replace: true});
    }

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="grid h-screen items-center lg:grid-cols-2">
                <>
                    <div className="my-auto p-8 text-center sm:p-10 md:p-20 xl:px-32 xl:py-24">
                        <Typography
                            variant="h1"
                            color="blue-gray"
                            className="mb-2">
                            Register today for a FREE trial
                        </Typography>
                        <Typography
                            variant="lead"
                            color="gray"
                            className="mb-12">
                            No credit card details needed, no auto-renew on expiry of trial. Completely FREE.
                        </Typography>
                        <RegisterCarer
                            setLoading={setLoading}
                            showAlert={showAlert}
                            onCompletedSignup={onCompletedRegisterSignUp}
                        />
                    </div>
                    <div className="my-auto p-8 text-center sm:p-10 md:p-20 xl:px-32 xl:py-12">
                        <Typography
                            className="mb-6"
                            variant="h2"
                            color="blue-gray">
                            Enjoy all of these benefits{" "}
                        </Typography>
                        <div className="grid grid-cols-2 gap-8 gap-y-8">
                            {stats.map((props, key) => (
                                <StatsCard
                                    key={key}
                                    {...props}
                                />
                            ))}
                        </div>
                    </div>
                </>
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
