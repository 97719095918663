import React, {useEffect, useState} from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import Footer from "components/Footer.js";
import Activities from "features/ActivityBank/ActivityCategoriesView";
import SparklesUserGuideView from "features/Guides/SparklesUserGuideView";
import ActivitiesView from "features/ActivityBank/ActivitiesView";
import TermsAndConditionsView from "features/TermsAndConditions/TermsAndConditionsView";
import NewspaperView from "features/Newspapers/Future/NewspaperView";
import QuizRequestView from "features/RequestA/Quiz/QuizRequestView";
import ArticleView from "features/RequestA/Article/ArticleView";
import ArticleRequestView from "features/RequestA/Article/ArticleRequestView";
import ProfileView from "features/Profile/ProfileView";
import {useAuth} from "providers/AuthContext";
import {ComplexNavbar} from "components/ComplexNavbar";
import UpgradeView from "features/Upgrade/UpgradeView";
import WeeklyDownloadsView from "features/Newspapers/Weekly/WeeklyDownloadsView";
import NewspapersView from "features/Newspapers/Future/NewspapersView";
import ArticlesView from "features/RequestA/Article/ArticlesView";
import QuizzesView from "features/Quiz/QuizzesView";
import ActivityDetail from "features/ActivityBank/ActivityDetail";
import ContactUsView from "features/ContactUs/ContactUsView";
import LandingView from "features/Landing/LandingView";
import VideoView from "features/Videos/VideoView";
import VideosView from "features/Videos/VideosView";
import ContactUsThanksView from "features/ContactUs/ContactUsThanksView";
import QuizDetailView from "features/Quiz/QuizDetailView";
import MyRequestsView from "features/RequestA/My/MyRequestsView";
import SensorySparklesView from "features/Newspapers/Sensory/SensorySparklesView";
import SensorySparkleView from "features/Newspapers/Sensory/SensorySparkleView";
import LargeFontSparkleView from "features/Newspapers/LargeFont/LargeFontSparkleView";
import LargeFontSparklesView from "features/Newspapers/LargeFont/LargeFontSparklesView";
import GuidesCategoryView from "features/Guides/GuidesCategoryView";
import GuidesView from "features/Guides/GuidesView";
import GuideView from "features/Guides/GuideView";
import MutipleChoiceQuizView from "features/Quiz/MutipleChoiceQuizView";
import MultipleChoiceQuizzesView from "features/Quiz/MultipleChoiceQuizzesView";
import TrialWelcomeView from "features/Trial/TrialWelcomeView";
import RequestADetail from "features/HowItWorks/details/RequestADetail";
import VideosDetail from "features/HowItWorks/details/VideosDetail";
import WeeklyDownloadsDetail from "features/HowItWorks/details/WeeklyDownloadsDetail";
import ActivitiesDetail from "features/HowItWorks/details/ActivitiesDetail";
import QuizzesDetail from "features/HowItWorks/details/QuizzesDetail";
import FeedbackPopover from "components/FeedbackPopover";
import ThanksForSubscribing from "features/Subscriptions/ThanksForSubscribing";
import FutureWeeklyDownloadsView from "features/Newspapers/Weekly/FutureWeeklyDownloadsView";
import {
    ACTIVITIES,
    ACTIVITIES_CATEGORYIDENTIFIER_ACTIVITYIDENTIFIER,
    ACTIVITIES_IDENTIFIER,
    ACTIVITIES_INTERACTIVE_QUIZZES,
    ACTIVITIES_INTERACTIVE_QUIZZES_MONTH_DAYOFMONTH,
    ARTICLES,
    ARTICLES_IDENTIFIER,
    ARTICLES_REQUEST,
    ARTICLES_REQUEST_OVERVIEW,
    CONTACTUS,
    CONTACTUS_THANKS,
    DETAILS_REQUEST_AN_ARTICLE,
    DETAILS_VIDEOS,
    DETAILS_WEEKLY_ACTIVITIES,
    DETAILS_WEEKLY_ARTICLES,
    DETAILS_WEEKLY_DOWNLOADS,
    DETAILS_WEEKLY_QUIZZES,
    DOWNLOADS_FUTURE,
    DOWNLOADS_WEEKLY_IDENTIFIER,
    GUIDES,
    GUIDES_CATEGORY,
    GUIDES_CATEGORY_IDENTIFIER,
    LANDING,
    LARGE_FONT_SPARKLES,
    LARGE_FONT_SPARKLES_IDENTIFIER,
    MEMBER_UPGRADE_ACTIVITY,
    MEMBER_UPGRADE_REQUEST_AN_ARTICLE,
    MEMBER_UPGRADE_REQUEST_A_QUIZ,
    NEWSPAPERS,
    NEWSPAPERS_IDENTIFIER,
    PROFILE,
    QUIZZES,
    QUIZZES_IDENTIFIER,
    QUIZZES_REQUEST,
    SENSORY_SPARKLES,
    SENSORY_SPARKLES_IDENTIFIER,
    SPARKLES_USERGUIDE,
    TERMS_AND_CONDITIONS,
    THANKS_FOR_SUBSCRIBING,
    UPGRADE,
    VIDEOS,
    VIDEOS_IDENTIFIER,
    WELCOME_TO_TRIAL,
} from "navigation/CONSTANTS";

export default function Member() {
    const {getStripeSubscription} = useAuth();
    const [subscriptionType, setSubscriptionType] = useState();

    useEffect(() => {
        async function fetchData() {
            const stripeSubscriptionType = await getStripeSubscription();
            setSubscriptionType(stripeSubscriptionType);
            console.log(stripeSubscriptionType);
        }
        fetchData();
    }, [getStripeSubscription]);

    return (
        <>
            <ComplexNavbar />
            <div className="">
                <div className="flex flex-col pt-8">
                    <Routes>
                        <Route
                            path={PROFILE}
                            element={<ProfileView />}
                        />
                        <Route
                            path={LANDING}
                            element={<LandingView />}
                        />
                        <Route
                            path={DOWNLOADS_WEEKLY_IDENTIFIER}
                            element={<WeeklyDownloadsView />}
                        />
                        <Route
                            path={DOWNLOADS_FUTURE}
                            element={<FutureWeeklyDownloadsView />}
                        />
                        <Route
                            path={NEWSPAPERS}
                            element={<NewspapersView />}
                        />
                        <Route
                            path={NEWSPAPERS_IDENTIFIER}
                            element={<NewspaperView />}
                        />
                        <Route
                            path={SENSORY_SPARKLES}
                            element={<SensorySparklesView />}
                        />
                        <Route
                            path={SENSORY_SPARKLES_IDENTIFIER}
                            element={<SensorySparkleView />}
                        />
                        <Route
                            path={LARGE_FONT_SPARKLES}
                            element={<LargeFontSparklesView />}
                        />
                        <Route
                            path={LARGE_FONT_SPARKLES_IDENTIFIER}
                            element={<LargeFontSparkleView />}
                        />
                        <Route
                            path={SPARKLES_USERGUIDE}
                            element={<SparklesUserGuideView />}
                        />
                        <Route
                            path={ACTIVITIES}
                            element={<Activities />}
                        />

                        <Route
                            path={ACTIVITIES_IDENTIFIER}
                            element={<ActivitiesView />}
                        />

                        <Route
                            path={ACTIVITIES_INTERACTIVE_QUIZZES}
                            element={<MultipleChoiceQuizzesView />}
                        />

                        <Route
                            path={ACTIVITIES_INTERACTIVE_QUIZZES_MONTH_DAYOFMONTH}
                            element={<MutipleChoiceQuizView />}
                        />

                        <Route 
                            path="/activities/activityPlanner/:activityIdentifier" 
                            element={ <ActivityDetail /> }
                        />

                        <Route
                            path={ACTIVITIES_CATEGORYIDENTIFIER_ACTIVITYIDENTIFIER}
                            element={
                                subscriptionType === "premium" || subscriptionType === "standard" ? (
                                    <ActivityDetail />
                                ) : (
                                    <Navigate to={MEMBER_UPGRADE_ACTIVITY} />
                                )
                            }
                        />

                        <Route
                            path={TERMS_AND_CONDITIONS}
                            element={<TermsAndConditionsView />}
                        />
                        <Route
                            path={CONTACTUS}
                            element={<ContactUsView />}
                        />
                        <Route
                            path={CONTACTUS_THANKS}
                            element={<ContactUsThanksView />}
                        />
                        <Route
                            path={QUIZZES}
                            element={<QuizzesView />}
                        />
                        <Route
                            path={QUIZZES_IDENTIFIER}
                            element={<QuizDetailView />}
                        />
                        <Route
                            path={QUIZZES_REQUEST}
                            element={
                                subscriptionType && subscriptionType === "premium" ? (
                                    <QuizRequestView />
                                ) : (
                                    <Navigate to={MEMBER_UPGRADE_REQUEST_A_QUIZ} />
                                )
                            }
                        />

                        <Route
                            path={ARTICLES}
                            element={<ArticlesView />}
                        />
                        <Route
                            path={ARTICLES_IDENTIFIER}
                            element={<ArticleView />}
                        />
                        <Route
                            path={ARTICLES_REQUEST}
                            element={
                                subscriptionType === "premium" ? (
                                    <ArticleRequestView />
                                ) : (
                                    <Navigate to={MEMBER_UPGRADE_REQUEST_AN_ARTICLE} />
                                )
                            }
                        />
                        <Route
                            path={UPGRADE}
                            element={<UpgradeView />}
                        />

                        <Route
                            path={ARTICLES_REQUEST_OVERVIEW}
                            element={<MyRequestsView />}
                        />
                        <Route
                            path={THANKS_FOR_SUBSCRIBING}
                            element={<ThanksForSubscribing />}
                        />

                        <Route
                            path={VIDEOS}
                            element={<VideosView />}
                        />
                        <Route
                            path={VIDEOS_IDENTIFIER}
                            element={<VideoView />}
                        />
                        <Route
                            path={GUIDES}
                            element={<GuidesCategoryView />}
                        />
                        <Route
                            path={GUIDES_CATEGORY}
                            element={<GuidesView />}
                        />
                        <Route
                            path={GUIDES_CATEGORY_IDENTIFIER}
                            element={<GuideView />}
                        />

                        <Route
                            path={WELCOME_TO_TRIAL}
                            element={<TrialWelcomeView />}
                        />

                        <Route
                            path={DETAILS_REQUEST_AN_ARTICLE}
                            element={<RequestADetail />}
                        />

                        <Route
                            path={DETAILS_VIDEOS}
                            element={<VideosDetail />}
                        />

                        <Route
                            path={DETAILS_WEEKLY_DOWNLOADS}
                            element={<WeeklyDownloadsDetail />}
                        />

                        <Route
                            path={DETAILS_WEEKLY_ARTICLES}
                            element={<WeeklyDownloadsDetail />}
                        />

                        <Route
                            path={DETAILS_WEEKLY_ACTIVITIES}
                            element={<ActivitiesDetail />}
                        />

                        <Route
                            path={DETAILS_WEEKLY_QUIZZES}
                            element={<QuizzesDetail />}
                        />
                    </Routes>
                    <div class="fixed bottom-8 right-8">
                        <FeedbackPopover />
                    </div>
                </div>
                <div className="mx-auto w-full m-4">
                    <Footer />
                </div>
            </div>
        </>
    );
}
