import React, {useState} from "react";
import Vimeo from "@u-wave/react-vimeo"; // eslint-disable-line import/no-unresolved

export default function VimeoPlayer({videoId}) {
    const [paused, setPaused] = useState(false);

    async function handlePlayerPause() {
        setPaused(true);
    }

    async function handlePlayerPlay() {
        setPaused(false);
    }

    return (
        <>
            <div className=" mx-auto w-max">
                <Vimeo
                    video={videoId}
                    width={640}
                    height={480}
                    autoplay
                    volume={0.5}
                    paused={paused}
                    onPause={() => handlePlayerPause}
                    onPlay={() => handlePlayerPlay}
                />
            </div>
        </>
    );
}
