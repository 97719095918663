import "assets/styles/App.css";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {AuthProvider} from "providers/AuthContext";
import PrivateRoute from "utils/PrivateRoute";
import Admin from "navigation/routes/AdminRoute";
import Member from "navigation/routes/MemberRoute";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Marketing from "navigation/routes/MarketingRoute";
import SubscriptionView from "features/Subscriptions/SubscriptionView";
import QuizFullScreenView from "features/Quiz/QuizFullScreenView";
import {LoadingProvider} from "providers/LoadingContext";
import {SnackbarProvider} from "providers/SnackbarContext";

export default function App() {
    return (
        <LoadingProvider>
            <SnackbarProvider>
                <div className="app">
                    <Router>
                        <AuthProvider>
                            <Routes>
                                <Route
                                    path="/member/*"
                                    element={
                                        <PrivateRoute>
                                            <Member />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/admin/*"
                                    element={
                                        <PrivateRoute>
                                            <Admin />
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="/onboarding"
                                    element={
                                        <PrivateRoute>
                                            <SubscriptionView />
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="/fullscreen"
                                    element={
                                        <PrivateRoute>
                                            <QuizFullScreenView />
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="*"
                                    element={<Marketing />}
                                />
                            </Routes>
                        </AuthProvider>
                    </Router>
                </div>
            </SnackbarProvider>
        </LoadingProvider>
    );
}
