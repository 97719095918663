import * as React from "react";
import {useState, useRef} from "react";
import {getDatabase, ref, push, child} from "firebase/database";
import {ref as storageRef, getDownloadURL, uploadBytesResumable} from "firebase/storage";
import {functions} from "utils/firebase.js";
import {httpsCallable} from "firebase/functions";
import {storage} from "utils/firebase.js";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Datepicker from "react-tailwindcss-datepicker";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
var tz = require("dayjs/plugin/timezone");
dayjs.extend(tz);
const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
        />
    );
});

export default function SensorySparkleAdminSection() {
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    const [sensorySparkleDate, setSensorySparkleDate] = useState({
        startDate: new Date(),
        endDate: null,
    });

    const handleValueChange = (newValue) => {
        setSensorySparkleDate(newValue);
    };

    function handleFileSelectionChange(event) {
        setFile(event.target.files[0]);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setFile(null);
        setOpenSnackbar(false);
    };

    const createSensorySparkleId = () => {
        const db = getDatabase();
        const sensorySparkleId = push(child(ref(db), "sensorySparkles")).key;
        return sensorySparkleId;
    };

    const handleFileUpload = async () => {
        if (file && file != null) {
            const sensorySparkleId = createSensorySparkleId();
            const fbStorageRef = storageRef(storage, `/sensorySparkles/${sensorySparkleId}.pdf`);
            const uploadTask = uploadBytesResumable(fbStorageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    setOpenBackdrop(true);
                },
                (error) => {
                    setOpenBackdrop(false);
                    setSnackbarText("Upload failed. Please try again");
                    setSnackbarSeverity("error");
                    setOpenSnackbar(true);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        const payload = {
                            id: sensorySparkleId,
                            name: `Sensory Sparkle ${dayjs.utc(sensorySparkleDate.endDate).format("D MMMM YYYY")}`,
                            filePath: `/sensorySparkles/${sensorySparkleId}.pdf`,
                            date: dayjs.utc(sensorySparkleDate.endDate).toString(),
                            blurb: "",
                            url: url,
                        };
                        const response = httpsCallable(functions, "createSensorySparkle");
                        response(payload)
                            .then(() => {
                                setOpenBackdrop(false);
                                setSnackbarText("Created successfully");
                                setSnackbarSeverity("success");
                                setOpenSnackbar(true);
                                fileInputRef.current.value = "";
                                setSensorySparkleDate(null);
                            })
                            .catch((error) => {
                                setOpenBackdrop(false);
                                setSnackbarText("Creation failed. Please try again");
                                setSnackbarSeverity("error");
                                setOpenSnackbar(true);
                            });
                    });
                },
            );
        } else {
            setSnackbarSeverity("error");
            setSnackbarText("Please fill in all fields.");
            setOpenSnackbar(true);
        }
    };

    return (
        <>
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center flex justify-between">
                        <h6 className="text-blueGray-700 text-xl font-bold">Upload a Sensory Sparkle</h6>
                        <button
                            onClick={(e) => handleFileUpload(e)}
                            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button">
                            SUBMIT
                        </button>
                    </div>
                </div>
                <hr className="mt-1 border-b-1 border-blueGray-300" />
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>
                        <div className="flex flex-wrap">
                            <div className="w-full pt-4 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Daily Sparkle PDF file
                                    </label>
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        onChange={handleFileSelectionChange}
                                        accept="/pdf/*"
                                    />
                                </div>
                                <div className="relative w-full mb-3">
                                    <h6 className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                        Sparkle Date
                                    </h6>
                                    <Datepicker
                                        displayFormat={"DD-MM-YYYY"}
                                        asSingle={true}
                                        value={sensorySparkleDate}
                                        onChange={handleValueChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}>
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={snackbarSeverity}
                            sx={{width: "100%"}}>
                            {snackbarText}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
        </>
    );
}
