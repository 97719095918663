import React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import validator from "validator";
import {useAuth} from "providers/AuthContext";
import {Input, Button} from "@material-tailwind/react";
import SmallFooter from "components/SmallFooter";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";

export default function ResetPasswordView() {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const {resetPassword} = useAuth();

    const sendResetPasswordLink = async () => {
        if (validator.isEmail(email) === false) {
            showAlert("Invalid email. Please try again");
            return;
        }

        setLoading(true);
        try {
            await resetPassword(email);
            navigate("/checkYourEmail", {replace: true});
        } catch (error) {
            console.log({error});
            showAlert("There was a problem. Please try again");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="grid h-screen items-center lg:grid-cols-2">
                <div className="my-auto p-8 text-center sm:p-10 md:p-20 xl:px-32 xl:py-24">
                    <div className="flex flex-col">
                        <img
                            alt="Daily sparkles logo"
                            class="content-center mb-8 mx-auto"
                            src={require("assets/img/dailysparklelogo-large.jpg")}
                        />

                        <div className="mx-auto min-w-[32rem] max-w-[32rem] text-left pt-4">
                            <div className="mb-8">
                                <Input
                                    color="yellow"
                                    required
                                    size="lg"
                                    label="Email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <Button
                                onClick={() => sendResetPasswordLink()}
                                color="yellow"
                                size="lg"
                                disabled={!email}
                                className="mt-6"
                                fullWidth>
                                Reset Password
                            </Button>
                        </div>
                    </div>
                </div>
                <img
                    alt="Lady looking at newspaper"
                    src="/img/bg-reading-sparkle.png"
                    className="hidden h-screen w-full object-cover lg:block"
                />
            </section>
            <div className="absolute bottom-0 min-w-1/2 w-1/2">
                <SmallFooter />
            </div>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
