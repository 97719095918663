import {SYSTEM_ERROR} from "../config/CONSTANTS";
import {getDatabase, ref, get, child} from "firebase/database";
import {MULTIPLE_CHOICE_QUIZ_URL, QUIZZES_URL} from "./CONSTANTS";

export const getQuizzes = () => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${QUIZZES_URL}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    const keys = Object.keys(response);
                    const retrievedQuizzes = keys.map((key) => {
                        const quiz = response[key];
                        quiz["displayableTags"] = Object.keys(quiz.tags);
                        return quiz;
                    });
                    resolve(retrievedQuizzes);
                } else {
                    reject("No quizzes retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getQuiz = (userId, identifier) => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${QUIZZES_URL}/${userId}/${identifier}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    const contentKeys = Object.keys(response.content);
                    const questionsAndAnswers = contentKeys.map((key, index) => {
                        return {
                            index: index,
                            question: key,
                            answer: response.content[key],
                        };
                    });
                    const quizData = {
                        title: response.title,
                        blurb: response.blurb,
                        tags: Object.keys(response.tags),
                        quiz: questionsAndAnswers,
                    };
                    resolve(quizData);
                } else {
                    reject("No quiz retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getMultipleChoiceQuiz = (month, dayOfMonth) => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${MULTIPLE_CHOICE_QUIZ_URL}/${month}/${dayOfMonth}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    const questionKeys = Object.keys(response);
                    const retrievedQuestionAndAnswers = questionKeys.map((questionKey, index) => {
                        const questionObject = response[questionKey];
                        const possibleAnswers = [questionObject.a1, questionObject.a2, questionObject.a3]
                            .map((value) => ({value, sort: Math.random()}))
                            .sort((a, b) => a.sort - b.sort)
                            .map(({value}) => value);
                        const correctAnswer = questionObject.a1;
                        return {
                            index: index,
                            question: questionObject.q,
                            answers: possibleAnswers,
                            answer: correctAnswer,
                        };
                    });
                    resolve(retrievedQuestionAndAnswers);
                } else {
                    reject("No quiz retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
