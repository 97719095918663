import React from "react";
import {Typography} from "@material-tailwind/react";

export default function VideosDetail() {
    return (
        <>
            <div className="container mx-auto mb-20 text-center">
                <Typography
                    color="yellow"
                    className="mb-2 font-bold uppercase">
                    how it works
                </Typography>
                <Typography
                    variant="h1"
                    color="blue-gray"
                    className="mb-4">
                    Videos by Daily Sparkle
                </Typography>
                <Typography
                    variant="lead"
                    className="mx-auto w-full px-4 !text-gray-500 lg:w-8/12 lg:px-8">
                    "Reminiscence With Jan" – Daily Sparkle's Video Series Enriching Lives in Care Homes
                </Typography>
            </div>
            <div className="container mx-auto grid items-center lg:grid-cols-2">
                <div className="row-start-2 mt-12 lg:row-auto lg:mt-0 lg:pr-12">
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        In the quest to enhance the quality of life for residents in care homes, Daily Sparkle has
                        introduced a remarkable video series titled "Reminiscence With Jan". This series is not just a
                        collection of videos; it's a journey into the past, serving as a powerful reminiscence tool that
                        resonates deeply with the elderly, especially those grappling with dementia.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        A Journey Back in Time with Jan
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        At the heart of this series is Jan, a charismatic and empathetic host who guides viewers through
                        various eras, topics, and memories. Each episode is thoughtfully crafted to evoke nostalgia and
                        stimulate conversation among care home residents. "Reminiscence With Jan" covers an array of
                        themes, ensuring that every resident finds something that sparks a memory.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        More Than Just Entertainment
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        The utility of "Reminiscence With Jan" extends far beyond mere entertainment. It's a therapeutic
                        tool, meticulously designed to align with the principles of reminiscence therapy. This form of
                        therapy is known for its effectiveness in improving mood, enhancing communication skills, and
                        promoting a sense of personal identity among elderly residents. By engaging with familiar sights
                        and sounds from their past, residents can reconnect with their memories and share stories,
                        fostering a sense of community and belonging.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        A Valuable Resource for Caregivers
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        For caregivers in care homes, "Reminiscence With Jan" is an invaluable resource. The series not
                        only serves as an engaging activity for residents but also as a means to facilitate interaction
                        and spark conversations that might not happen otherwise. Caregivers often use these videos as a
                        starting point for group discussions or one-on-one reminiscence sessions, helping to build
                        stronger connections with and among residents.
                    </Typography>
                </div>
                <img
                    src="https://material-taillwind-pro-ct-tailwind-team.vercel.app/img/content1.jpg"
                    alt="team work"
                    className=" h-full min-h-[50vh] w-full rounded-xl object-cover object-center md:min-h-[75vh]"
                />
            </div>
        </>
    );
}
