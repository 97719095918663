import {Typography} from "@material-tailwind/react";
import React from "react";

export default function QuizzesDetail() {
    return (
        <>
            <div className="container mx-auto mb-20 text-center">
                <Typography
                    color="yellow"
                    className="mb-2 font-bold uppercase">
                    how it works
                </Typography>
                <Typography
                    variant="h1"
                    color="blue-gray"
                    className="mb-4">
                    Daily Sparkle's Quizzes
                </Typography>
                <Typography
                    variant="lead"
                    className="mx-auto w-full px-4 !text-gray-500 lg:w-8/12 lg:px-8">
                    Fostering Reminiscence and Interaction in Elderly Care
                </Typography>
            </div>
            <div className="container mx-auto grid items-center lg:grid-cols-2">
                <div className="row-start-2 mt-12 lg:row-auto lg:mt-0 lg:pr-12">
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        Daily Sparkle has been transforming the way we approach activities in elderly care, especially
                        for individuals living with dementia. Their series of quizzes stands out as a key tool in this
                        transformative approach, adeptly aiding reminiscence and promoting group interaction. Further
                        enhancing this experience is the innovative 'Request-A' feature, allowing users to request
                        quizzes on any topic of their choice.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        Quizzes as a Gateway to the Past
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        The power of reminiscence therapy in elderly care cannot be overstated, particularly for
                        individuals with dementia. Daily Sparkle's quizzes are designed to tap into this power,
                        triggering memories and encouraging residents to share their experiences and stories. Each quiz
                        is a journey through time, touching on various themes and topics that resonate with the older
                        generation. From historical events to popular culture of their youth, these quizzes are more
                        than just a game; they are a bridge to the past.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        Promoting Group Interaction and Socialization
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        In addition to aiding reminiscence, Daily Sparkle's quizzes play a crucial role in fostering
                        group interaction. They bring residents together, creating an environment of camaraderie and
                        shared experiences. As residents participate in these quizzes, they engage not only in a mental
                        exercise but also in social interaction, which is vital for emotional well-being. These quiz
                        sessions often turn into lively discussions, filled with laughter and storytelling,
                        strengthening the sense of community within the care setting.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        Customisation with 'Request-A'
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        hat sets Daily Sparkle’s quizzes apart is the 'Request-A' feature. This unique service allows
                        caregivers or residents to request quizzes on specific topics, tailoring the content to the
                        interests and backgrounds of the participants. Whether it’s a quiz about a particular era, a
                        genre of music, or even a geographic location, 'Request-A' ensures that every quiz is relevant
                        and engaging for its audience. This level of customization not only enhances the reminiscence
                        experience but also gives residents a sense of control and personalization in their activities.
                    </Typography>
                </div>
                <img
                    src="/img/bg-quizzes.jpg"
                    alt="team work"
                    className=" h-full min-h-[50vh] w-full rounded-xl object-cover object-center md:min-h-[75vh]"
                />
            </div>
        </>
    );
}
