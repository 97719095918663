import React, {useEffect} from "react";
import {Card, CardHeader, CardBody, Typography, Avatar} from "@material-tailwind/react";
import {useAuth} from "providers/AuthContext";

export default function ProfileImageCard({imageUrl, onClickImageUrl, hiddenFileInput, handleChange}) {
    const {getRole, currentUser} = useAuth();

    useEffect(() => {
        console.log(JSON.stringify(getRole));
    }, [getRole]);

    return (
        <>
            <Card
                shadow={false}
                className="relative grid h-[40rem] w-full max-w-[28rem] items-end justify-center overflow-hidden text-center border-4 border-yellow-500">
                <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="absolute inset-0 m-0 h-full w-full rounded-none bg-[url('/public/img/bg-reading-sparkle.png')] bg-cover bg-center">
                    <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
                </CardHeader>
                <CardBody className="relative py-14 px-6 md:px-12">
                    <Typography
                        variant="h2"
                        color="white"
                        className="mb-2 font-medium leading-[1.5]">
                        {currentUser.displayName}
                    </Typography>
                    <Typography
                        variant="h5"
                        className="mb-4 text-gray-400">
                        {currentUser.email}
                    </Typography>
                    <Avatar
                        onClick={() => onClickImageUrl()}
                        size="xxl"
                        variant="circular"
                        alt=""
                        className="border-2 border-yellow-500"
                        src={imageUrl}
                    />
                    <input
                        accept=".jpg, .png"
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{display: "none"}}
                    />
                </CardBody>
            </Card>
        </>
    );
}
