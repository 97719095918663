import React from "react";
import {Typography} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import RegisterCarer from "components/RegisterCarer";

export default function RegisterView() {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const navigate = useNavigate();

    async function onCompletedRegisterSignUp() {
        navigate(`/onboarding`, {replace: true});
    }

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="grid h-screen items-center lg:grid-cols-2">
                <div className="my-auto p-8 text-center sm:p-10 md:p-20 xl:px-32 xl:py-24">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-2">
                        Register
                    </Typography>
                    <Typography
                        variant="lead"
                        color="gray"
                        className="mb-12">
                        Enter your details to join Daily Sparkle on a FREE TRIAL.
                    </Typography>
                    <RegisterCarer
                        setLoading={setLoading}
                        showAlert={showAlert}
                        onCompletedSignup={onCompletedRegisterSignUp}
                    />
                </div>

                <img
                    alt="Lady looking at newspaper"
                    src="/img/bg-reading-sparkle.png"
                    className="hidden h-screen w-full object-cover lg:block"
                />
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
