import * as React from "react";
import {useState, useRef} from "react";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {useSnackbar} from "providers/SnackbarContext";
import {SnackbarComponent} from "components/SnackbarComponent";
import Select from "react-select";
import {metaCategoryOptions} from "data/MetaCategories";
import {callCloudFunction, uploadToStorage} from "services";

class AddActivityType {
    constructor(name, blurb, path, url, category) {
        this.name = name;
        this.blurb = blurb;
        this.path = path;
        this.url = url;
        this.category = category;
    }
}

export default function AddActivityView() {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [file, setFile] = useState(null);
    const [activityName, setActivityName] = useState();
    const [selectedActivityCategoryOption, setSelectedActivityCategoryOption] = useState(null);
    const [activityBlurb, setactivityBlurb] = useState();
    const fileInputRef = useRef();
    const activityOptions = metaCategoryOptions;

    const handleChange = (selectedOption) => {
        setSelectedActivityCategoryOption(selectedOption);
    };

    function handleFileSelectionChange(event) {
        setFile(event.target.files[0]);
    }

    const handleFileUpload = () => {
        if ((file === null) | (selectedActivityCategoryOption === null)) {
            showAlert("Please select an activity file first. This must be in .pdf format.");
            return;
        }
        const activityCategory = selectedActivityCategoryOption.label;
        const fileStoragePath = `/activities/${activityCategory}/${file.name}`;
        setLoading(true);
        uploadToStorage(fileStoragePath, file)
            .then((url) => {
                const payload = AddActivityType(activityName, activityBlurb, fileStoragePath, url, activityCategory);
                return callCloudFunction("createActivity", payload);
            })
            .then(() => {
                setActivityName("");
                setactivityBlurb("");
                fileInputRef.current.value = "";
            })
            .catch((error) => {
                showAlert(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full mb-12 xl:mb-12 px-8 pt-8 pb-8">
                    {loading && <LoadingComponent />}
                    {activityOptions && (
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                            <div className="rounded-t mb-0 px-6 py-6">
                                <div className="text-center flex justify-between">
                                    <h6 className="text-blueGray-700 text-xl font-bold">Create a new activity</h6>
                                    <button
                                        onClick={(e) => handleFileUpload(e)}
                                        className="bg-yellow-500 text-white active:bg-yellow-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        type="button">
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
                            <hr className="mt-1 border-b-1 border-blueGray-300" />
                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                <form>
                                    <div className="flex flex-wrap">
                                        <div className="w-full pt-4 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password">
                                                    Select Activity category
                                                </label>
                                                <Select
                                                    isClearable={true}
                                                    value={selectedActivityCategoryOption}
                                                    options={activityOptions}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password">
                                                    Activity file
                                                </label>
                                                <input
                                                    ref={fileInputRef}
                                                    type="file"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    onChange={handleFileSelectionChange}
                                                    accept="/pdf/*"
                                                />
                                            </div>
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password">
                                                    Activity Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    value={activityName}
                                                    onChange={(e) => setActivityName(e.target.value)}
                                                />
                                            </div>
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password">
                                                    Activity Blurb
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    value={activityBlurb}
                                                    onChange={(e) => setactivityBlurb(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {openSnackbar && <SnackbarComponent />}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
