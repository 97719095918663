import React from "react";
import {useState, useEffect} from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TagCreate from "components/TagCreate";
import {functions} from "utils/firebase.js";
import {httpsCallable} from "firebase/functions";
import TagActivities from "components/TagActivities";
import {getDatabase, ref, get, child} from "firebase/database";
import TagQuizzes from "components/TagQuizzes";
import TagArticles from "components/TagArticles";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
        />
    );
});

export default function AdminTags() {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [tagOptions, setTagOptions] = useState();

    useEffect(() => {
        const database = getDatabase();
        const dbRef = ref(database);
        get(child(dbRef, "/metaTags")).then((snapshot) => {
            if (snapshot.exists()) {
                const response = snapshot.val();
                const keys = Object.keys(response);
                const retrievedTags = keys.map((key) => {
                    const tag = response[key];
                    return {
                        value: key,
                        label: tag.name,
                    };
                });
                console.log(`Retrieved Tags: ${JSON.stringify(retrievedTags)}`);
                setTagOptions(retrievedTags);
            }
        });
    }, []);

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleSubmit = (tag, setTag) => {
        setOpenBackdrop(true);
        const response = httpsCallable(functions, "createMetaTag");
        const payload = {name: tag};
        console.log(`HandleSubmit payload: ${JSON.stringify(payload)}`);
        response(payload)
            .then(() => {
                setSnackbarText("Tag created successfully");
                setSnackbarSeverity("success");
                setOpenSnackbar(true);
            })
            .catch((error) => {
                console.log(error);
                setSnackbarText("Tag creation failed. Please try again");
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            })
            .finally(() => {
                setOpenBackdrop(false);
                setTag("");
            });
    };

    return (
        <>
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className="flex flex-wrap">
                <div className="w-full mb-12 xl:mb-12 px-8 pt-8 pb-8">
                    <TagCreate handleSubmit={handleSubmit} />
                    <TagActivities
                        setSnackbarText={setSnackbarText}
                        setSnackbarSeverity={setSnackbarSeverity}
                        setOpenSnackbar={setOpenSnackbar}
                        setOpenBackdrop={setOpenBackdrop}
                        tagOptions={tagOptions}
                    />
                    <TagQuizzes
                        setSnackbarText={setSnackbarText}
                        setSnackbarSeverity={setSnackbarSeverity}
                        setOpenSnackbar={setOpenSnackbar}
                        setOpenBackdrop={setOpenBackdrop}
                        tagOptions={tagOptions}
                    />
                    <TagArticles
                        setSnackbarText={setSnackbarText}
                        setSnackbarSeverity={setSnackbarSeverity}
                        setOpenSnackbar={setOpenSnackbar}
                        setOpenBackdrop={setOpenBackdrop}
                        tagOptions={tagOptions}
                    />
                </div>
            </div>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{width: "100%"}}>
                    {snackbarText}
                </Alert>
            </Snackbar>
        </>
    );
}
