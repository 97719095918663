export const trialProduct = {
    packageName: "Trial",
    price: "FREE",
    buttonTitle: "START TRIAL NOW",
    features: [
        "Trial access to weekly Sparkle Newspapers",
        "Trial access to Activity Bank - ready-to-use activity ideas, perfect for one-to-one and group sessions",
        "Trial access to weekly quizzes",
        "24/7 access",
        "Full customer service support",
        "Full training support",
    ],
};

export const standardProduct = {
    key: "price_1Nc4sICePCfXkQEFenqIALTr",
    packageName: "Standard",
    price: "60",
    buttonTitle: "Sign up now",
    features: [
        "Weekly Sparkle Newspapers",
        "Ready-to-use activity ideas",
        "Quizzes",
        "24/7 access",
        "Full customer service support",
        "Full training support",
    ],
};

export const premiumProduct = {
    key: "price_1NbxhwCePCfXkQEFU9EgPVRk",
    packageName: "Premium",
    price: "80",
    buttonTitle: "Sign up now",
    features: [
        "All standard package",
        "Request-A feature",
        "FREE e-Learning course",
        "24/7 access",
        "Full customer service support",
        "Full training support",
    ],
};

export const entperiseProduct = {
    key: "enterprise",
    packageName: "Enterprise",
    price: "TBA",
    buttonTitle: "NOTIFY ME",
    features: [
        "All premium features",
        "Create and manage a team of care providers",
        "Resident profiles",
        "Custom content curation tools",
        "Reporting tool suite",
    ],
};
