import React from "react";
import {Link} from "react-router-dom";
import {metaCategories} from "data/MetaCategories";
import {Card, CardBody, Typography} from "@material-tailwind/react";

export default function ActivityCategoriesView() {
    return (
        <>
            <section className="py-10 px-4">
                <div className="container mx-auto mb-4 text-center">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Activities
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        We have a wide range of reminiscence activities plus all our usual quizzes, puzzles, large image
                        discussions and many more! Access the monthly activity booklet via the PDF download below. A
                        vast range of activities to entertain and stimulate your residents. You will find activities for
                        individuals to play on their own, 1-to-1 and group sessions.
                    </Typography>
                </div>
                <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-start gap-y-4 gap-x-4 px-4 pt-10 pb-16">
                    {metaCategories &&
                        metaCategories.map((category) => (
                            <Link to={category.identifier}>
                                <Card
                                    key={category.identifier}
                                    className="mt-2 bg-white h-full border-yellow-500 border-2">
                                    <CardBody key={category.identifier}>
                                        {category.icon}
                                        <Typography
                                            variant="h5"
                                            color="blue-gray"
                                            className="mb-2">
                                            {category.value}
                                        </Typography>
                                    </CardBody>
                                </Card>
                            </Link>
                        ))}
                </div>
            </section>
        </>
    );
}
