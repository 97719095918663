import {SYSTEM_ERROR} from "../config/CONSTANTS";
import {getDatabase, ref, get, child} from "firebase/database";
import {CARE_PROVIDERS_URL} from "./CONSTANTS";
import {httpsCallable} from "firebase/functions";
import {functions} from "utils/firebase.js";

export const getCareProviderProfile = (identifier) => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${CARE_PROVIDERS_URL}/${identifier}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const profileData = snapshot.val();
                    resolve(profileData);
                } else {
                    reject("No user profile retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateCareProviderProfile = async (payload) => {
    const updateCareProviderProfile = httpsCallable(functions, "updateCareProviderProfile");
    await updateCareProviderProfile(payload);
};
