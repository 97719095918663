import TrialBanner from "components/TrialBanner";
import React from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import RequestADetail from "./RequestADetail";
import VideosDetail from "./VideosDetail";
import WeeklyDownloadsDetail from "./WeeklyDownloadsDetail";
import ActivitiesDetail from "./ActivitiesDetail";
import QuizzesDetail from "./QuizzesDetail";

export default function Details() {
    const navigate = useNavigate();

    async function onClickStartFreeTrial() {
        navigate("/register-for-trial");
    }
    return (
        <>
            <div>
                <TrialBanner onClickStartFreeTrial={onClickStartFreeTrial} />
            </div>
            <section className="py-12 px-8 lg:py-12">
                <Routes>
                    <Route
                        path="/request-an-article"
                        element={<RequestADetail />}
                    />

                    <Route
                        path="/videos"
                        element={<VideosDetail />}
                    />

                    <Route
                        path="/weekly-downloads"
                        element={<WeeklyDownloadsDetail />}
                    />

                    <Route
                        path="/articles"
                        element={<WeeklyDownloadsDetail />}
                    />

                    <Route
                        path="/activities"
                        element={<ActivitiesDetail />}
                    />

                    <Route
                        path="/quizzes"
                        element={<QuizzesDetail />}
                    />
                </Routes>
            </section>
        </>
    );
}
