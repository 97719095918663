import React from "react";
import {addCheckoutSession} from "utils/firestore/subscriptionFirestore";
import {useAuth} from "providers/AuthContext";
import PricingSection from "../../components/PricingSection";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";

export default function SubscriptionView() {
    const {currentUser, startTrial} = useAuth();
    const navigate = useNavigate();
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();

    function assignUrl(url, error) {
        if (url === undefined && error === undefined) {
            return;
        }
        setLoading(true);
        if (error) {
            console.error("There was an error creating a checkout session", error);
            showAlert("There was an error subscribing. Please try again later.");
        } else {
            window.location.assign(url);
        }
    }

    async function onClick(payload) {
        if (payload.packageName === "Trial") {
            await onClickTrial();
        } else {
            await onClickSubscription(payload);
        }
        setLoading(true);
        addCheckoutSession(payload, currentUser.uid, assignUrl);
    }

    async function onClickTrial() {
        setLoading(true);
        try {
            await startTrial();
            navigate("/member/landing", {replace: true});
        } catch (error) {
            showAlert("There was an error submitting your request. Please try again later.");
            console.error({error});
        } finally {
            setLoading(false);
        }
    }

    async function onClickSubscription(payload) {
        setLoading(true);
        addCheckoutSession(payload, currentUser.uid, assignUrl);
    }

    return (
        <>
            {loading && <LoadingComponent />}
            <PricingSection onClick={onClick} />
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
