import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
var tz = require("dayjs/plugin/timezone");
dayjs.extend(tz);

export class AddNewspaperPayload {
    constructor(id, docType, filePath, date, url) {
        this.id = id;
        this.name = `${docType.label} ${dayjs.utc(date).tz("Australia/Melbourne").format("D MMMM YYYY")}`;
        this.filePath = filePath;
        this.type = docType.value;
        this.date = dayjs.utc(date).toString();
        this.blurb = "";
        this.url = url;
    }
}
