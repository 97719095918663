import {httpsCallable} from "firebase/functions";
import {functions} from "utils/firebase.js";

export const callCloudFunction = async (name, payload) => {
    try {
        const callable = httpsCallable(functions, name);
        await callable(payload);
    } catch (error) {
        console.error(error);
        throw new Error("There was an error submitting your request. Please try again later.");
    }
};
