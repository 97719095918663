import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Typography} from "@material-tailwind/react";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";
var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);

function WeeklyDownloadsTable({weeklyDownloads}) {
    const navigate = useNavigate();

    const columns = [
        {
            name: "Name",
            selector: (row) => row.name,
        },
    ];

    const handleRowClicked = (row) => {
        navigate(`/member/downloads/${row.id}`);
    };

    return (
        <>
            <Typography
                variant="h6"
                className="pt-8 text-left">
                Weekly Downloads
            </Typography>
            {weeklyDownloads && (
                <>
                    <DataTable
                        highlightOnHover
                        pointerOnHover
                        pagination
                        selectableRowsHighlight
                        columns={columns}
                        data={weeklyDownloads}
                        onRowClicked={handleRowClicked}
                    />
                </>
            )}
        </>
    );
}

export default function FutureWeeklyDownloadsView() {
    const [weeklyDownloads, setWeeklyDownloads] = useState();

    useEffect(() => {
        const weekOfYear = dayjs().week();
        const year = dayjs().year();
        const next4weeks = [
            {
                id: `${year}-${weekOfYear + 1}`,
                name: `Week commencing ${dayjs()
                    .week(weekOfYear + 1)
                    .format("D MMMM YYYY")}`,
            },
            {
                id: `${year}-${weekOfYear + 2}`,
                name: `Week commencing ${dayjs()
                    .week(weekOfYear + 2)
                    .format("D MMMM YYYY")}`,
            },
            {
                id: `${year}-${weekOfYear + 3}`,
                name: `Week commencing ${dayjs()
                    .week(weekOfYear + 3)
                    .format("D MMMM YYYY")}`,
            },
            {
                id: `${year}-${weekOfYear + 4}`,
                name: `Week commencing ${dayjs()
                    .week(weekOfYear + 4)
                    .format("D MMMM YYYY")}`,
            },
        ];
        setWeeklyDownloads(next4weeks);
    }, []);

    return (
        <>
            <section className="py-10 px-4">
                <div className="container mx-auto mb-4 text-center">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Future Weekly Downloads
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        With Daily Sparkle, you have the ability to download newspapers and carer notes, ensuring you
                        stay informed and equipped to provide the best care possible. We understand the importance of
                        staying up-to-date with the latest news and trends, as well as having access to valuable
                        resources that assist you in your caregiving role.
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        By downloading newspapers, you can immerse yourself in a wealth of knowledge from around the
                        world. Stay informed about current events, health advancements, lifestyle tips, and more.
                        Enhance your understanding of the world and discover new insights that can positively impact
                        your caregiving approach.
                    </Typography>

                    {weeklyDownloads && <WeeklyDownloadsTable weeklyDownloads={weeklyDownloads} />}
                </div>
            </section>
        </>
    );
}
