import React, {useState, useEffect} from "react";
import {getDatabase, ref, get, child} from "firebase/database";
import {Link, useParams} from "react-router-dom";
import {allCategories, getParagraphText} from "data/MetaCategories";
import {Card, CardBody, CardFooter, Typography} from "@material-tailwind/react";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";

function ActivityCard({activity, icon}) {
    return (
        <>
            <Card className="mt-2 bg-white h-full border-yellow-500 border-2">
                <CardBody key={activity.id}>
                    {icon}
                    <Typography
                        variant="h5"
                        color="blue-gray"
                        className="mb-2">
                        {activity.name}
                    </Typography>
                </CardBody>
                <CardFooter className="pt-0">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="flex justify-end items-center gap-2 hover:text-yellow-700">
                        Download Now
                    </Typography>
                </CardFooter>
            </Card>
        </>
    );
}

export default function ActivitiesView() {
    const params = useParams();
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [activities, setActivities] = useState([]);
    const [categoryName, setCategoryName] = useState();
    const [category, setCategory] = useState();
    const database = getDatabase();

    const categories = allCategories;

    useEffect(() => {
        const identifier = params.identifier;
        const category = categories.filter((cat) => cat.identifier === identifier)[0];
        setCategory(category);
        setCategoryName(category.value);
    }, [categories, params.identifier]);

    useEffect(() => {
        if (categoryName === null || categoryName === undefined) {
            return;
        }

        const dbRef = ref(database);
        setLoading(true);
        get(child(dbRef, `/categories/${categoryName}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    const keys = Object.keys(response);
                    const filteredKeys = keys.filter((key) => {
                        if (key === "abstract") {
                            return false;
                        } else if (key === "title") {
                            return false;
                        } else {
                            return true;
                        }
                    });

                    const retrievedActivities = filteredKeys.map((key) => {
                        return response[key];
                    });
                    setActivities(retrievedActivities);
                } else {
                    showAlert("No activities retrieved. Please try again.");
                }
                setLoading(false);
            })
            .catch((error) => {
                showAlert("Error retrieving activity. Please try again.");
                console.error(error);
            });
    }, [database, categoryName, setLoading, showAlert]);

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="py-10 px-2">
                <div className="container mx-auto mb-4 text-center">
                    {categoryName && getParagraphText(categoryName)}
                </div>
            </section>
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-start gap-y-4 gap-x-4 px-4 pt-10 pb-16">
                {activities.map((activity) => (
                    <Link to={activity.id}>
                        <ActivityCard
                            activity={activity}
                            icon={category.icon}
                        />
                    </Link>
                ))}
            </div>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
