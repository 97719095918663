import {ref as storageRef, uploadBytesResumable, getDownloadURL} from "firebase/storage";
import {setDoc, doc, getDoc} from "firebase/firestore";
import {db, storage} from "utils/firebase.js";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
var tz = require("dayjs/plugin/timezone");
dayjs.extend(tz);

export const uploadPublicationToStorage = (publication, publicationDate, publicationType, publicationFormat) => {
    const path = `/${publicationType}/${dayjs
        .utc(publicationDate)
        .tz("Australia/Melbourne")
        .format("D-M-YYYY")}.${publicationFormat}`;
    const fbStorageRef = storageRef(storage, path);
    return new Promise((resolve, reject) => {
        const uploadTask = uploadBytesResumable(fbStorageRef, publication);
        uploadTask.on(
            null,
            null,
            (error) => {
                reject(`Failed to upload ${publicationType} to storage.`);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    resolve({path: path, url: url});
                });
            },
        );
    });
};

export const addPublicationToDataStore = (
    publicationDate,
    publicationType,
    publicationFormat,
    publicationUrl,
    publicationStoragePath,
) => {
    return new Promise((resolve, reject) => {
        try {
            const payload = {
                name: `${dayjs.utc(publicationDate).tz("Australia/Melbourne").format("D-M-YYYY")}.${publicationFormat}`,
                path: publicationStoragePath,
                type: publicationType,
                date: publicationDate,
                url: publicationUrl,
            };
            const collectionName = collectionForPublicationType(publicationType);
            if (collectionName.length === 0) {
                reject("Unknown publication type");
            }

            setDoc(
                doc(db, `${collectionName}/${dayjs.utc(publicationDate).tz("Australia/Melbourne").format("D-M-YYYY")}`),
                payload,
            )
                .then((res) => {
                    resolve();
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        } catch (error) {
            console.error(error);
            reject(`Failed to add ${publicationType} to data store.`);
        }
    });
};

const collectionForPublicationType = (publicationType) => {
    switch (publicationType) {
        case "daily":
            return "daily_publications";
        case "weekly":
            return "weekly_publications";
        case "carer_notes":
            return "carer_notes";
        case "articles":
            return "articles";
        default:
            return "";
    }
};

export const getDailyPublications = (publicationType) => {
    return new Promise((resolve, reject) => {
        try {
            const collectionName = collectionForPublicationType(publicationType);
            const docRef = doc(db, collectionName, publicationType);
            getDoc(docRef).then((res) => {
                if (res.exists()) {
                    resolve(res.data());
                } else {
                    reject(`Failed to retrieved all ${publicationType}.`);
                }
            });
        } catch (error) {
            console.error(error);
            reject(`Failed to retrieved all ${publicationType}.`);
        }
    });
};
