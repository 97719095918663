import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Typography} from "@material-tailwind/react";
import DataTable from "react-data-table-component";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {getAllNewspapers} from "services";

function NewspapersTable({newspapers}) {
    const navigate = useNavigate();

    const columns = [
        {
            name: "Name",
            selector: (row) => row.name,
        },
    ];

    const handleRowClicked = (row) => {
        navigate(`/member/newspapers/${row.id}`);
    };

    return (
        <>
            <Typography
                variant="h6"
                className="pt-8 text-left">
                Past Newspapers
            </Typography>
            {newspapers && (
                <>
                    <DataTable
                        highlightOnHover
                        pointerOnHover
                        selectableRowsHighlight
                        pagination
                        columns={columns}
                        data={newspapers}
                        onRowClicked={handleRowClicked}
                    />
                </>
            )}
        </>
    );
}

export default function NewspapersView() {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [newspapers, setNewspapers] = useState([]);

    useEffect(() => {
        setLoading(true);

        getAllNewspapers()
            .then((res) => {
                setNewspapers(res);
            })
            .catch((error) => {
                console.error(error);
                showAlert(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [showAlert, setLoading]);

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="py-10 px-4">
                <div className="container mx-auto mb-4 text-center">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Past Newspapers
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        With Daily Sparkle, you have the power to read each newspaper item individually, unlocking a
                        treasure trove of information at your fingertips. Immerse yourself in a world of diverse topics,
                        ranging from global news and current affairs to captivating stories and insightful features.
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        Savor the joy of indulging in each newspaper item, one by one. Dive deep into thought-provoking
                        articles that shed light on pressing issues, engage with opinion pieces that challenge your
                        perspectives, and stay informed about the latest trends and discoveries across various fields.
                    </Typography>

                    {newspapers && <NewspapersTable newspapers={newspapers} />}
                </div>
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
