import React from "react";
import {Routes, Route} from "react-router-dom";
import Footer from "components/Footer.js";
import TestFunctions from "features/Admin/TestFunctions";
import Users from "features/Admin/Users";
import SidebarAdmin from "components/SidebarAdmin";
import LandingView from "features/Landing/LandingView";
import AdminTags from "features/Admin/AdminTags";
import QuizManage from "components/QuizManage";
import ArticleManage from "features/Admin/ArticleManage";
import TestClaims from "features/Admin/TestClaims";
import NewspaperView from "features/Newspapers/Future/NewspaperView";
import WeeklyDownloadView from "features/Newspapers/Weekly/WeeklyDownloadView";
import PreviewWeeklyDownloads from "features/Admin/PreviewWeeklyDownloads";
import CreateWeeklyDownloads from "features/Admin/CreateWeeklyDownloads";
import ArticleEditor from "features/Admin/ArticleEditor";
import ArticlePreview from "features/Admin/ArticlePreview";
import AddVideoView from "features/Admin/AddVideoView";
import SensorySparkleAdminSection from "features/Admin/SensorySparkleAdminSection";
import LargeFontSparkleAdminSection from "features/Admin/LargeFontSparkleAdminSection";
import GuidesAdminSection from "features/Admin/GuidesAdminSection";
import AddPublicationView from "features/Admin/AddPublicationView";
import AddNewspaperView from "features/Admin/AddNewspaper/AddNewspaperView";
import AddActivityView from "features/Admin/AddActivityView";

export default function Admin() {
    return (
        <>
            <SidebarAdmin />;
            <div className="relative md:ml-64">
                <section>
                    <Routes>
                        <Route
                            path="/landing"
                            element={<LandingView />}
                        />
                        <Route
                            path="/users"
                            element={<Users />}
                        />
                        <Route
                            path="/claims"
                            element={<TestClaims />}
                        />

                        <Route
                            path="/newspapers/add"
                            element={<AddNewspaperView />}
                        />
                        <Route
                            path="/tags"
                            element={<AdminTags />}
                        />
                        <Route
                            path="/testFunctions"
                            element={<TestFunctions />}
                        />
                        <Route
                            path="/activities/add"
                            element={<AddActivityView />}
                        />
                        <Route
                            path="/quizzes"
                            element={<QuizManage />}
                        />
                        <Route
                            path="/articles"
                            element={<ArticleManage />}
                        />
                        <Route
                            path="/articles/preview/:identifier"
                            element={<ArticlePreview />}
                        />
                        <Route
                            path="/articles/edit/:identifier"
                            element={<ArticleEditor />}
                        />
                        <Route
                            path="/downloads/create"
                            element={<CreateWeeklyDownloads />}
                        />
                        <Route
                            path="/downloads/previews"
                            element={<PreviewWeeklyDownloads />}
                        />
                        <Route
                            path="/downloads/:weeklyIdentifier"
                            element={<WeeklyDownloadView />}
                        />
                        <Route
                            path="/preview/newspapers/:identifier"
                            element={<NewspaperView />}
                        />
                        <Route
                            path="/newspapers/:identifier"
                            element={<NewspaperView />}
                        />
                        <Route
                            path="/videos/add"
                            element={<AddVideoView />}
                        />
                        <Route
                            path="/sensorySparkles/add"
                            element={<SensorySparkleAdminSection />}
                        />
                        <Route
                            path="/largeFontSparkles/add"
                            element={<LargeFontSparkleAdminSection />}
                        />
                        <Route
                            path="/guides/add"
                            element={<GuidesAdminSection />}
                        />
                        <Route
                            path="/publications/add"
                            element={<AddPublicationView />}
                        />
                    </Routes>
                </section>
                <section>
                    <Footer />
                </section>
            </div>
        </>
    );
}
