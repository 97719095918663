import React from "react";
import {Card, CardBody, Typography} from "@material-tailwind/react";
import {
    NewspaperIcon,
    PuzzlePieceIcon,
    QuestionMarkCircleIcon,
    TrophyIcon,
    VideoCameraIcon,
    WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";

const offerings = [
    {
        title: "Award Winning",
        desc: "The award-winning Daily Sparkle reminiscence newspaper emailed to you each week.",
        icon: (
            <TrophyIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
    },
    {
        title: "Activities",
        desc: "Top quality, professionally created activities for older people and people living with dementia.",
        icon: (
            <PuzzlePieceIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
    },
    {
        title: "Articles",
        desc: "Quick and easy-to-read articles to provide stimulation, interest, fun and enjoyment every day.",
        icon: (
            <NewspaperIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
    },
    {
        title: "Quizzes",
        desc: "Highly effective relationship-building tools.",
        icon: (
            <QuestionMarkCircleIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
    },
    {
        title: "Videos",
        desc: "Highly effective relationship-building tools.",
        icon: (
            <VideoCameraIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
    },
    {
        title: "Tools",
        desc: "Highly effective relationship-building tools.",
        icon: (
            <WrenchScrewdriverIcon
                className="h-6 w-6"
                strokeWidth={2}
            />
        ),
    },
];

function StatsCard({offering}) {
    return (
        <Card
            shadow={false}
            color="transparent">
            <CardBody className="grid justify-center text-center">
                <div className="mx-auto mb-6 grid h-12 w-12 place-items-center rounded-full bg-yellow-500 p-2.5 text-white shadow">
                    {offering.icon}
                </div>
                <Typography
                    variant="h5"
                    color="blue-gray"
                    className="mb-2">
                    {offering.title}
                </Typography>
                <Typography className="px-8 font-normal !text-gray-500">{offering.desc}</Typography>
            </CardBody>
        </Card>
    );
}

export default function WhatWeOffer() {
    return (
        <>
            <Typography
                variant="h2"
                color="blue-gray"
                className="mt-12 mb-6 w-full text-center">
                We Offer You
            </Typography>

            <div className="container mx-auto grid grid-cols-1 gap-y-12 md:grid-cols-2 lg:grid-cols-3">
                {offerings.map((offering) => (
                    <StatsCard offering={offering} />
                ))}
            </div>
        </>
    );
}
