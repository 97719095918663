import {ArrowRightIcon} from "@heroicons/react/24/outline";
import {Card, CardBody, Typography, Button, Chip} from "@material-tailwind/react";

export function PricingCard({payload, onClick}) {
    return (
        <Card
            variant="gradient"
            color="white">
            <CardBody className="text-center">
                <Chip
                    value={payload.packageName}
                    color="blue-gray"
                    className="mx-auto -mt-9 w-32 rounded-full !bg-blue-gray-50 pt-2 !text-blue-gray-900"
                />
                <Typography
                    variant="h1"
                    color="blue-gray"
                    className="mb-2 mt-10 flex justify-center">
                    <Typography
                        as="span"
                        variant="h5"
                        color="blue-gray">
                        $
                    </Typography>
                    {payload.price}
                    <Typography
                        as="span"
                        variant="h5"
                        color="blue-gray">
                        per month
                    </Typography>
                </Typography>

                <ul className="my-6 flex flex-col gap-3 pt-6">
                    {payload.features.map((option, key) => (
                        <li
                            key={key}
                            className="flex gap-2 text-gray-700">
                            <Typography className="font-normal text-left">{option}</Typography>
                        </li>
                    ))}
                </ul>
                <Button
                    onClick={() => onClick(payload)}
                    fullWidth
                    variant="gradient"
                    color="yellow"
                    className="flex items-center justify-center gap-4">
                    Join Now{" "}
                    <ArrowRightIcon
                        className="h-5 w-5"
                        strokeWidth={2}
                    />
                </Button>
            </CardBody>
        </Card>
    );
}
