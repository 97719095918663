import React, {useEffect} from "react";
import {useState} from "react";
import Snackbar from "@mui/material/Snackbar";
import {Typography, Card, CardBody} from "@material-tailwind/react";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {getDatabase, ref, get, child} from "firebase/database";
import {Link} from "react-router-dom";
import dayjs from "dayjs";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
        />
    );
});

export default function PreviewWeeklyDownloads() {
    const database = getDatabase();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const tableHead = ["Available From", "Week", "Year", "Created By", ""];
    const weeklyIdentifier = `${dayjs().year().toString()}-${dayjs().week().toString()}`;

    useEffect(() => {
        async function fetchData() {
            const dbRef = ref(database);
            setOpenBackdrop(true);
            try {
                const response = (await get(child(dbRef, `weeklyDownloads`))).val();
                console.log(`reponse: ${JSON.stringify(response)}`);
                if (response != null) {
                    const keys = Object.keys(response);
                    const retrievedWeeklyDownloads = keys.map((key) => {
                        const weeklyDownload = response[key];
                        return weeklyDownload;
                    });
                    setTableRows(retrievedWeeklyDownloads);
                }
            } catch (error) {
                setSnackbarSeverity("error");
                setSnackbarText("Error retrieving weekly downloads. Please try again.");
                setOpenSnackbar(true);
                console.error(error);
            } finally {
                setOpenBackdrop(false);
            }
        }
        fetchData();
    }, [database, weeklyIdentifier]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-10 pb-6">
                <div className="w-full h-60 mb-6">
                    <img
                        alt="Daily sparkles logo"
                        class="max-w-full mx-auto pt-6 object-center h-60 content-center"
                        src={require("assets/img/dailysparklelogo-large.jpg")}
                    />
                </div>
                <div class="flex items-stretch flex-wrap px-6 py-6">
                    <Card className="h-full w-full mb-6  border-yellow-500 border-2">
                        <CardBody>
                            <table className="w-full min-w-max table-auto text-left">
                                <thead>
                                    <tr>
                                        {tableHead.map((head) => (
                                            <th
                                                key={head}
                                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal leading-none opacity-70">
                                                    {head}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRows.map(({availableFrom, weekOfYear, year, createdBy}, index) => {
                                        const isLast = index === tableRows.length - 1;
                                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                        return (
                                            <tr key={index}>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal">
                                                        {availableFrom}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal">
                                                        {weekOfYear}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal">
                                                        {year}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal">
                                                        {createdBy}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Link to={`/admin/downloads/${year}-${weekOfYear}?preview=true`}>
                                                        <Typography
                                                            variant="small"
                                                            color="blue"
                                                            className="font-medium">
                                                            View Preview
                                                        </Typography>
                                                    </Link>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{width: "100%"}}>
                    {snackbarText}
                </Alert>
            </Snackbar>
        </>
    );
}
