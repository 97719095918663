//General
export const MEMBER = "/member/*";
export const ADMIN = "/admin/*";
export const ONBOARDING = "/onboarding";
export const FULL_SCREEN = "/fullscreen";
//Auth
export const CHECK_YOUR_EMAIL = "/checkYourEmail";
//Fullscreen
export const QUIZZES_FULL_SCREEN = "*/quizzes/*/fullscreen";
//Marketing
export const LOGIN = "login/*";
export const REGISTER = "register";
export const REGISTER_FOR_TRIAL = "register-for-trial";
export const RESET_PASSWORD = "/resetPassword";
export const CHECK_YOUR_EMAIL_WILDCARD = "/checkYourEmail/*";
export const HOME = "home";
export const ABOUT = "about";
export const HOW_IT_WORKS = "how-it-works";
export const HOW_IT_WORKS_WILDCARD = "how-it-works/*";
export const PRICING = "pricing";
export const CONTACT_US = "contactus";
export const CONTACT_US_THANKS = "contactus/thanks";
//Member
export const PROFILE = "/profile";
export const LANDING = "/landing";
export const DOWNLOADS_WEEKLY_IDENTIFIER = "/downloads/:weeklyIdentifier";
export const DOWNLOADS_FUTURE = "/downloads/future";
export const NEWSPAPERS = "/newspapers";
export const NEWSPAPERS_IDENTIFIER = "/newspapers/:identifier";
export const SENSORY_SPARKLES = "/sensorySparkles";
export const SENSORY_SPARKLES_IDENTIFIER = "/sensorySparkles/:identifier";
export const LARGE_FONT_SPARKLES = "/largeFontSparkles";
export const LARGE_FONT_SPARKLES_IDENTIFIER = "/largeFontSparkles/:identifier";
export const SPARKLES_USERGUIDE = "/sparkles/userguide";
export const ACTIVITIES = "/activities";
export const ACTIVITIES_IDENTIFIER = "/activities/:identifier";
export const ACTIVITIES_INTERACTIVE_QUIZZES = "/activities/interactiveQuizzes";
export const ACTIVITIES_INTERACTIVE_QUIZZES_MONTH_DAYOFMONTH = "/activities/interactiveQuizzes/:month/:dayOfMonth";
export const ACTIVITIES_CATEGORYIDENTIFIER_ACTIVITYIDENTIFIER = "/activities/:categoryIdentifier/:activityIdentifier";
export const TERMS_AND_CONDITIONS = "/termsandconditions";
export const CONTACTUS = "/contactus";
export const CONTACTUS_THANKS = "/contactus/thanks";
export const QUIZZES = "/quizzes";
export const QUIZZES_IDENTIFIER = "/quizzes/:identifier";
export const QUIZZES_REQUEST = "/quizzes/request";
export const ARTICLES = "/articles";
export const ARTICLES_IDENTIFIER = "/articles/:identifier";
export const ARTICLES_REQUEST = "/articles/request";
export const UPGRADE = "/upgrade";
export const ARTICLES_REQUEST_OVERVIEW = "/articles/request/overview";
export const THANKS_FOR_SUBSCRIBING = "/thanksForSubscribing";
export const VIDEOS = "/videos";
export const VIDEOS_IDENTIFIER = "/videos/:identifier";
export const GUIDES = "/guides";
export const GUIDES_CATEGORY = "/guides/:category";
export const GUIDES_CATEGORY_IDENTIFIER = "/guides/:category/:identifier";
export const WELCOME_TO_TRIAL = "/welcome-to-trial";
export const DETAILS_REQUEST_AN_ARTICLE = "/details/request-an-article";
export const DETAILS_VIDEOS = "/details/videos";
export const DETAILS_WEEKLY_DOWNLOADS = "/details/weekly-downloads";
export const DETAILS_WEEKLY_ARTICLES = "/details/articles";
export const DETAILS_WEEKLY_ACTIVITIES = "/details/activities";
export const DETAILS_WEEKLY_QUIZZES = "/details/quizzes";
export const MEMBER_UPGRADE_ACTIVITY = "/member/upgrade?feature=activity";
export const MEMBER_UPGRADE_REQUEST_A_QUIZ = "/member/upgrade?feature=requestaquiz";
export const MEMBER_UPGRADE_REQUEST_AN_ARTICLE = "/member/upgrade?feature=requestanarticle";
//ALL
export const WILDCARD = "*";
