import React, {useState} from "react";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {Input, Button, Typography} from "@material-tailwind/react";
import {InformationCircleIcon} from "@heroicons/react/24/solid";
import {useAuth} from "providers/AuthContext";
import {requestArticle} from "services";

export default function ArticleRequestView() {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [articleDescription, setArticleDescription] = useState();
    const {currentUser} = useAuth();

    const onChange = ({target}) => setArticleDescription(target.value);

    const onClickRequestArticle = async () => {
        try {
            const payload = {
                description: articleDescription,
                requestedBy: currentUser.uid,
            };
            setLoading(true);
            await requestArticle(payload);
        } catch (error) {
            showAlert(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="py-28 px-4">
                <div className="container mx-auto mb-20 text-center">
                    <Typography
                        color="yellow"
                        className="mb-2 font-bold uppercase">
                        Request - A
                    </Typography>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Request an Article
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 !text-gray-500 lg:w-8/12 lg:px-8 pb-8">
                        If there are specific topics you'd love to read about, share them with us! Whether it's
                        nostalgic songs, memorable events, or anything that sparks your reminiscing journey, we're all
                        ears. Your input will help us curate content that brings joy and warmth to your heart.
                    </Typography>
                    <div className="mx-auto relative justify-center flex w-full">
                        <Input
                            type="text"
                            label="Article Description"
                            color="yellow"
                            value={articleDescription}
                            onChange={onChange}
                            className="pr-20"
                            containerProps={{
                                className: "min-w-0",
                            }}
                        />
                        <Button
                            onClick={() => onClickRequestArticle()}
                            size="sm"
                            color={articleDescription ? "yellow" : "blue-gray"}
                            disabled={!articleDescription}
                            className="!absolute right-1 top-1 rounded">
                            REQUEST
                        </Button>
                    </div>
                    <div className="mx-auto relative justify-center flex w-full">
                        <Typography
                            variant="small"
                            color="gray"
                            className="flex items-center gap-1 font-normal mt-2">
                            <InformationCircleIcon className="w-4 h-4 -mt-px" />
                            Keep the description as short and concise as possible e.g. "A movie review of the Titanic"
                            or "A commentary of the 1966 world cup final"
                        </Typography>
                    </div>
                </div>
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
