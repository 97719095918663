import React, {useEffect, useState, useMemo} from "react";
import MaterialReactTable from "material-react-table";
import {functions} from "utils/firebase.js";
import {httpsCallable} from "@firebase/functions";
import Select from "react-select";

export default function UsersList({setSnackbarText, setSnackbarSeverity, setOpenSnackbar, setOpenBackdrop}) {
    const [users, setUsers] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [selectedUser, setSelectedUser] = useState();
    const [selectedAction, setSelectedAction] = useState();
    const actions = [{value: "delete", label: "Delete User"}];

    const columns = useMemo(
        () => [
            {
                accessorKey: "email",
                header: "User Email",
            },
            {
                accessorKey: "emailVerified",
                header: "Email Verified",
            },
            {
                accessorKey: "role",
                header: "Role",
            },
            {
                accessorKey: "uid",
                header: "UID",
            },
        ],
        [],
    );

    useEffect(() => {
        if (users.length === 0) {
            setOpenBackdrop(true);
            getAllUsers()
                .then(() => {
                    console.log("Users retrieved");
                })
                .catch((error) => {
                    console.log(error);
                    setSnackbarText("Failed to get users. Please try again");
                    setSnackbarSeverity("error");
                    setOpenSnackbar(true);
                })
                .finally(() => {
                    setOpenBackdrop(false);
                });
        }
    });

    const getAllUsers = () => {
        const getAllDailySparkleUsers = httpsCallable(functions, "getAllDailySparkleUsers");
        return getAllDailySparkleUsers().then((result) => {
            if (result.data.success === true) {
                console.log(`Get All Users Response: ${JSON.stringify(result.data)}`);
                const users = result.data.response.map((user) => {
                    return {
                        email: user.email,
                        emailVerified: user.emailVerified === true ? "Verified" : "Not-verified",
                        role: user.customClaims.role,
                        uid: user.uid,
                    };
                });
                setUsers(users);
            }
        });
    };

    useEffect(() => {
        const keys = Object.keys(rowSelection);
        if (keys && keys.length === 1) {
            const indexFromKey = keys[0];
            setSelectedUser(users[indexFromKey]);
        }
    }, [rowSelection, selectedUser, users]);

    const handleActionChange = (selectedOption) => {
        setSelectedAction(selectedOption);
    };

    const submitAction = (e) => {
        e.preventDefault();
        setOpenBackdrop(true);
        const payload = {uid: selectedUser.uid, role: selectedUser.role};
        const deleteUser = httpsCallable(functions, "deleteUser");
        deleteUser(payload)
            .then(() => {
                getAllUsers();
            })
            .catch((error) => {
                console.log(error);
                setSnackbarText("User deletion failed. Please try again");
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            })
            .finally(() => {
                setOpenBackdrop(false);
            });
    };

    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center flex justify-between">
                        <h6 className="text-blueGray-700 text-xl font-bold">Daily Sparkle Users</h6>
                    </div>
                </div>
                <hr className="mt-1 border-b-1 border-blueGray-300" />
                <div className="flex-auto px-4 lg:px-4 py-4 pt-0">
                    <div className="overflow-x-auto py-4">
                        <MaterialReactTable
                            columns={columns}
                            data={users}
                            enableRowSelection
                            enableMultiRowSelection={false} //shows radio buttons instead of checkboxes
                            onRowSelectionChange={setRowSelection}
                            state={{rowSelection}}
                            enableTopToolbar={true}
                            enableColumnActions={false}
                        />
                    </div>
                </div>
            </div>
            {selectedUser && (
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                    <div className="rounded-t mb-0 px-6 py-6">
                        <div className="text-center flex justify-between">
                            <h6 className="text-blueGray-700 text-xl font-bold">Perform action</h6>
                            <button
                                onClick={(e) => submitAction(e)}
                                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="button">
                                SUBMIT
                            </button>
                        </div>
                    </div>
                    <hr className="mt-1 border-b-1 border-blueGray-300" />
                    <div className="flex-auto px-4 lg:px-4 py-4 pt-0">
                        <form>
                            <div className="flex flex-wrap">
                                <div className="w-full pt-4 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            Select Permission Action
                                        </label>
                                        <Select
                                            value={selectedAction}
                                            options={actions}
                                            onChange={handleActionChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}
