import * as React from "react";
import {useState, useRef} from "react";
import {getDatabase, ref, push, child} from "firebase/database";
import dayjs from "dayjs";
import Select from "react-select";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {useSnackbar} from "providers/SnackbarContext";
import {SnackbarComponent} from "components/SnackbarComponent";
import {callCloudFunction, uploadToStorage} from "services";
import {AddNewspaperPayload} from "./AddNewspaperType";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
var tz = require("dayjs/plugin/timezone");
dayjs.extend(tz);

export default function AddNewspaperView() {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const fileInputRef = useRef(null);
    const [files, setFiles] = useState();
    const [selectedDocType, setSelectedDocType] = useState();

    const docTypeOptions = [
        {value: "daily_newspaper", label: "Daily Sparkle"},
        {value: "weekly_newspaper", label: "Weekly Sparkle"},
        {value: "carers_notes", label: "Carer Notes"},
    ];

    function handleFileSelectionChange(event) {
        const files = Array.from(event.target.files);
        console.log(files);
        setFiles(files);
    }

    function extractDateFromString(filename) {
        // Regular expression to match the date pattern YYYY-MM-DD
        const datePattern = /\d{4}[-_]\d{2}[-_]\d{2}/;
        // Use the regular expression to find a match in the filename
        const match = filename.match(datePattern);
        // If a match is found, return it. Otherwise, return null or an appropriate response
        return match ? match[0] : null;
    } 

    const handleDocTypeChange = (selectedOption) => {
        setSelectedDocType(selectedOption);
    };

    const createNewspaperId = async () => {
        const db = getDatabase();
        const newspaperId = await push(child(ref(db), "newspapers")).key;
        return newspaperId;
    };

    const handleFileUpload = async () => {
        if (!files| (files === null)) {
            showAlert("Please fill in all fields.");
            return;
        }

        files.forEach(async file => {
            const newspaperId = await createNewspaperId();
            const path = `/newspapers/${newspaperId}.pdf`;
            console.info(`Path: ${path}`);
            setLoading(true);

            try {
                const fileURL = await uploadToStorage(path, file);
                const newspaperDate = extractDateFromString(file.name);
                const payload = new AddNewspaperPayload(newspaperId, selectedDocType, path, newspaperDate, fileURL);
                await callCloudFunction("createNewspaper", payload);
            } catch (error) {
                console.log(error);
                showAlert(error.msg);
            } finally {
                setLoading(false);
            }        
        });
    };

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full mb-12 xl:mb-12 px-8 pt-8 pb-8">
                    {loading && <LoadingComponent />}
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">Upload a Newspaper</h6>
                                <button
                                    onClick={(e) => handleFileUpload(e)}
                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                    type="button">
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                        <hr className="mt-1 border-b-1 border-blueGray-300" />
                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                            <form>
                                <div className="flex flex-wrap">
                                    <div className="w-full pt-4 px-4">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password">
                                                Daily Sparkle PDF file
                                            </label>
                                            <input
                                                ref={fileInputRef}
                                                type="file"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                onChange={handleFileSelectionChange}
                                                accept="application/pdf"
                                                multiple
                                            />
                                        </div>
                                        <div className="relative w-full mb-3">
                                            <h6 className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                                Type
                                            </h6>
                                            <Select
                                                isClearable={true}
                                                value={selectedDocType}
                                                options={docTypeOptions}
                                                onChange={handleDocTypeChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {openSnackbar && <SnackbarComponent />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
