import React, {useEffect, useState} from "react";
import Select from "react-select";
import {getDatabase, ref, get, child} from "firebase/database";
import {functions} from "utils/firebase.js";
import {httpsCallable} from "firebase/functions";

export default function TagArticles({
    setSnackbarText,
    setSnackbarSeverity,
    setOpenSnackbar,
    setOpenBackdrop,
    tagOptions,
}) {
    const [selectedArticles, setSelectedArticles] = useState();
    const [selectedTag, setSelectedTag] = useState();
    const [articleOptions, setArticleOptions] = useState();

    useEffect(() => {
        const database = getDatabase();
        const dbRef = ref(database);
        get(child(dbRef, "/articles")).then((snapshot) => {
            if (snapshot.exists()) {
                const response = snapshot.val();
                const keys = Object.keys(response);
                const retrievedArticles = keys.map((key) => {
                    const article = response[key];
                    return {
                        value: key,
                        label: article.title,
                    };
                });
                console.log(`Retrieved Articles: ${JSON.stringify(retrievedArticles)}`);
                setArticleOptions(retrievedArticles);
            }
        });
    }, []);

    const handleQuizChange = (selectedOption) => {
        console.log(JSON.stringify(selectedOption));
        setSelectedArticles(selectedOption);
        console.log(JSON.stringify(selectedArticles));
    };

    const handleTagChange = (selectedOption) => {
        console.log(JSON.stringify(selectedOption));
        setSelectedTag(selectedOption);
        console.log(JSON.stringify(selectedTag));
    };

    const tagArticles = () => {
        console.log("tagArticles");
        const payload = {
            tag: selectedTag,
            articles: selectedArticles,
        };
        console.log(`Payload: ${JSON.stringify(payload)}`);
        const response = httpsCallable(functions, "article-tagArticle");
        response(payload)
            .then(() => {
                setSnackbarText("Articles tagged successfully");
                setSnackbarSeverity("success");
                setOpenSnackbar(true);
            })
            .catch((error) => {
                console.log(error);
                setSnackbarText("Articles tagging failed. Please try again");
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            })
            .finally(() => {
                setOpenBackdrop(false);
                setSelectedArticles();
                setSelectedTag();
            });
    };

    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center flex justify-between">
                        <h6 className="text-blueGray-700 text-xl font-bold">Tag an article</h6>
                        <button
                            onClick={() => tagArticles()}
                            className="bg-yellow-500 text-white active:bg-yellow-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button">
                            SUBMIT
                        </button>
                    </div>
                </div>
                <hr className="mt-1 border-b-1 border-blueGray-300" />
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>
                        <div className="flex flex-wrap">
                            <div className="w-full pt-4 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Select Article/Articles
                                    </label>
                                    <Select
                                        isClearable={true}
                                        isMulti={true}
                                        value={selectedArticles}
                                        options={articleOptions}
                                        onChange={handleQuizChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="w-full pt-4 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Select Tag
                                    </label>
                                    <Select
                                        isClearable={true}
                                        value={selectedTag}
                                        options={tagOptions}
                                        onChange={handleTagChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
