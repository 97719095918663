import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {Editor} from "react-draft-wysiwyg";
import {EditorState, ContentState, convertToRaw} from "draft-js";
import {functions} from "utils/firebase.js";
import {httpsCallable} from "firebase/functions";
import {useNavigate} from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
        />
    );
});

export default function ArticleEditor() {
    const params = useParams();
    const location = useLocation();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState();
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [rawContent, setRawContent] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        async function prepareData() {
            var convertedContent = [];
            console.log(location.state);
            const contentJSON = JSON.parse(location.state.content);
            console.log(JSON.stringify(contentJSON));
            convertedContent.push(contentJSON.title);
            contentJSON.paragraphs.forEach((p) => convertedContent.push(`${p}`));
            const convertedContentText = convertedContent.join("\n\n");
            console.log(convertedContentText);
            const content = ContentState.createFromText(convertedContentText);
            setEditorState(EditorState.createWithContent(content));
        }
        prepareData();
    }, [location.state]);

    const onEditorStateChange = (editorState) => {
        const json = convertToRaw(editorState.getCurrentContent());
        setRawContent(json);
        setEditorState(editorState);
    };

    const handleSave = async () => {
        setOpenBackdrop(true);
        try {
            const identifier = params.identifier;
            const payload = await createPayload(identifier);
            console.log(JSON.stringify(payload));
            const updateArticleContent = httpsCallable(functions, "updateRequestedArticle");
            await updateArticleContent(payload);
            navigate(-1);
        } catch (error) {
            console.log(error);
            setSnackbarText("Update failed. Please try again");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
        } finally {
            setOpenBackdrop(false);
        }
    };

    const createPayload = async (identifier) => {
        const rawPayload = JSON.stringify(rawContent);
        const unifiedPayload = await createUnifiedJsonPayload(identifier, rawPayload);
        return unifiedPayload;
    };

    const createUnifiedJsonPayload = async (identifier, rawPayload) => {
        let title = "";
        var paragraphs = [];
        for (let index = 0; index < rawContent.blocks.length; index++) {
            const block = rawContent.blocks[index];
            if (title.length === 0 && block["text"].length > 0) {
                title = block.text;
            } else if (block.text.length > 0) {
                paragraphs.push(block.text);
            }
        }

        var payload = {title: title, paragraphs: paragraphs};
        var convertedPayload = JSON.stringify(payload);
        return {id: identifier, title: title, raw: rawPayload, converted: convertedPayload};
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <>
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="flex flex-wrap">
                <div className="w-full mb-12 xl:mb-12 px-8 pt-8 pb-8">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">Article Editor</h6>
                                <button
                                    onClick={(e) => handleSave()}
                                    className="bg-yellow-500 text-white active:bg-yellow-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                    type="button">
                                    SAVE CHANGES
                                </button>
                            </div>
                        </div>
                        <div className="bg-white rounded-t mb-0 px-6 py-6">
                            <Editor
                                editorState={editorState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{width: "100%"}}>
                    {snackbarText}
                </Alert>
            </Snackbar>
        </>
    );
}
