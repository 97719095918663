import React, {useState} from "react";
import {Typography, Button, Card, CardBody, ButtonGroup, CardFooter} from "@material-tailwind/react";

export default function QuizCard({index, question, answer, onNext, onPrev, onExpand, numberOfQuestions}) {
    const [revealAnswer, setRevealAnswer] = useState(false);
    const [buttonTitle, setButtonTitle] = useState("Reveal Answer");

    function onClickRevealAnswer() {
        setRevealAnswer(!revealAnswer);
        setButtonTitle(revealAnswer ? "Reveal Answer" : "Hide Answer");
    }

    function reset() {
        setRevealAnswer(false);
        setButtonTitle("Reveal Answer");
    }

    function onClickNext() {
        if (index + 1 === numberOfQuestions) {
            return;
        }
        reset();
        onNext();
    }

    function onClickPrev() {
        if (index === 0) {
            return;
        }
        reset();
        onPrev();
    }

    return (
        <section className="place-items-center p-4 mx-auto ">
            <Card>
                <CardBody>
                    <Typography
                        variant="h3"
                        color="blue-gray"
                        className="font-medium text-center">
                        {question}
                    </Typography>
                    <Typography
                        variant="lead"
                        color="blue-gray"
                        className="font-medium text-center p-8">
                        {revealAnswer ? answer : "******"}
                    </Typography>
                    <ButtonGroup
                        color="yellow"
                        fullWidth
                        ripple={true}>
                        <Button onClick={() => onClickPrev()}>{"<"}</Button>
                        <Button onClick={() => onClickRevealAnswer()}>{buttonTitle}</Button>
                        <Button onClick={() => onClickNext()}>{">"}</Button>
                    </ButtonGroup>
                </CardBody>
                <CardFooter>
                    <Typography
                        variant="small"
                        className="text-center">
                        {index + 1} of {numberOfQuestions}
                    </Typography>
                </CardFooter>
            </Card>
        </section>
    );
}
