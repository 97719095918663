import {SYSTEM_ERROR} from "../config/CONSTANTS";
import {getDatabase, ref, get, child} from "firebase/database";
import {SENSORY_SPARKLES_URL} from "./CONSTANTS";

export const getAllSensorySparkles = () => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${SENSORY_SPARKLES_URL}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    const keys = Object.keys(response);
                    const retrievedSensorySparkles = keys.map((key) => {
                        const sensorySparkle = response[key];
                        sensorySparkle.displayableTags = Object.keys(sensorySparkle.tags);
                        return sensorySparkle;
                    });
                    resolve(retrievedSensorySparkles);
                } else {
                    reject("No sensory sparkles retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getSensorySparkle = (identifier) => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${SENSORY_SPARKLES_URL}/${identifier}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    response["displayableTags"] = Object.keys(response.tags);
                    resolve(response);
                } else {
                    reject("No sensory sparkles retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
