import React from "react";
import {Alert, Snackbar} from "@mui/material";
import {useSnackbar} from "providers/SnackbarContext";

export function SnackbarComponent() {
    const {openSnackbar, snackbarText, snackbarSeverity, hideAlert} = useSnackbar();

    return (
        <Snackbar
            open={openSnackbar}
            autoHideDuration={100}>
            <Alert
                onClose={hideAlert}
                severity={snackbarSeverity}
                sx={{width: "100%"}}>
                {snackbarText}
            </Alert>
        </Snackbar>
    );
}
