import React, {createContext, useContext, useState} from "react";

// Create the context
const SnackbarContext = createContext({
    openSnackbar: false,
    snackbarText: null,
    snackbarSeverity: null,
    showAlert: null,
    hideAlert: null,
});

// Create the provider component
export const SnackbarProvider = ({children}) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("");

    // Function to show the alert
    const showAlert = (text, severity) => {
        setSnackbarText(text);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const hideAlert = () => {
        setOpenSnackbar(false);
    };

    const value = {openSnackbar, snackbarText, snackbarSeverity, showAlert, hideAlert};

    return <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>;
};

export function useSnackbar() {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error("openSnackbar must be used within SnackbarProvider");
    }
    return context;
}
