import {Input, Button, Typography} from "@material-tailwind/react";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "providers/AuthContext";
import validator from "validator";

export default function RegisterCarer({setLoading, showAlert, onCompletedSignup}) {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();
    const {signup, currentUser} = useAuth();

    const onRegister = async () => {
        if (password && confirmPassword && confirmPassword !== password) {
            showAlert("Passwords do not match. Please try again");
            return;
        }

        if (validator.isEmail(email) === false) {
            showAlert("Invalid email. Please try again");
            return;
        }

        setLoading(true);
        try {
            await signup(email, password, name);
            console.log(`User: ${currentUser}`);
            await onCompletedSignup();
        } catch (error) {
            console.log(error);
            showAlert("Something went wrong. Please try again");
        } finally {
            setLoading(false);
        }
    };

    const onClickLogin = async () => {
        navigate("/login", {replace: true});
    };

    return (
        <>
            <div className="mx-auto max-w-[24rem] text-left">
                <div className="mb-8">
                    <Input
                        color="yellow"
                        required
                        size="lg"
                        label="Name"
                        type="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="mb-8">
                    <Input
                        color="yellow"
                        required
                        size="lg"
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="mb-8">
                    <Input
                        color="yellow"
                        required
                        size="lg"
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="mb-8">
                    <Input
                        color="yellow"
                        required
                        size="lg"
                        label="Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                <div className="flex flex-wrap items-center justify-between gap-2">
                    <Typography
                        onClick={() => onClickLogin()}
                        color="blue"
                        className="font-medium text-center min-w-full hover:text-yellow-500">
                        Already have an account? Click here to login.
                    </Typography>
                </div>
                <Button
                    onClick={() => onRegister()}
                    color="yellow"
                    size="lg"
                    disabled={!name || !email || !password || !confirmPassword}
                    className="mt-6"
                    fullWidth>
                    Register for FREE trial
                </Button>
            </div>
            {/* </div> */}
        </>
    );
}
