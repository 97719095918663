import {Typography} from "@material-tailwind/react";
import React from "react";
import {Link} from "react-router-dom";

export default function SmallFooter() {
    return (
        <>
            <footer className="block py-4">
                <div className="container mx-auto px-4">
                    <hr className="mb-4 border-b-1 border-blueGray-200" />
                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                        <div className="w-full md:w-6/12 px-4">
                            <div className="py-1 text-left md:text-left">
                                <Link to="/aboutus">
                                    <Typography variant="h6">
                                        Copyright © {new Date().getFullYear()} Daily Sparkle Australia
                                    </Typography>
                                </Link>
                            </div>
                        </div>
                        <div className="w-full md:w-6/12 px-4">
                            <ul className="flex flex-wrap list-none md:justify-end  justify-center">
                                <li>
                                    <Link to="/contactus">
                                        <Typography variant="h6">Contact Us</Typography>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
