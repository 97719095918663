import React, {useContext, useEffect, useState} from "react";
import {doc, setDoc} from "firebase/firestore";
import {db, auth, functions} from "../utils/firebase";
import {signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, updateProfile} from "firebase/auth";
import {httpsCallable} from "firebase/functions";
import logCustomAnalyticsEvent from "../utils/DSAnalytics";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    async function setUser(value) {
        localStorage.setItem("currentUser", JSON.stringify(value));
        setCurrentUser(value);
    }

    async function getUser() {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return user;
    }

    async function updateUser(user) {
        await resolveClaimsData(user);
        return await getUser();
    }

    const [currentUser, setCurrentUser] = useState(getUser());

    const [loading, setLoading] = useState(true);

    async function getAdminUpdatedSubscription() {
        return localStorage.getItem("currentUserSubscriptionType");
    }

    async function setAdminUpdatedSubscription(value) {
        localStorage.setItem("currentUserSubscriptionType", value);
        setCurrentUserSubscriptionType(value);
    }

    const [currentUserSubscriptionType, setCurrentUserSubscriptionType] = useState(getAdminUpdatedSubscription());

    async function getStripeSubscription() {
        return localStorage.getItem("currentUserStripeSubscriptionType");
    }

    const setStripeSubscription = (value) => {
        localStorage.setItem("currentUserStripeSubscriptionType", value);
    };

    async function getRole() {
        const role = localStorage.getItem("currentUserRole");
        return role;
    }

    async function setRole(value) {
        localStorage.setItem("currentUserRole", value);
        setCurrentUserRole(value);
    }

    const [currentUserRole, setCurrentUserRole] = useState(getRole());

    async function signup(email, password, name) {
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            await updateProfile(auth.currentUser, {displayName: name});
            await createFirestoreUser(auth.currentUser);
            setCurrentUser(auth.currentUser);
            setUser(auth.currentUser);
            const userData = {
                userid: auth.currentUser.uid,
                name: name,
                email: email,
            };
            const createNewCareProvider = httpsCallable(functions, "createNewCareProvider");
            await createNewCareProvider(userData);
            await resolveClaimsData(auth.currentUser);
            logCustomAnalyticsEvent("sign_up", {id: userData.userid});
        } catch (error) {
            await logCustomAnalyticsEvent("sign_up_error", error);
        }
    }

    async function createFirestoreUser(user) {
        try {
            const firestoreUser = {
                uid: user.uid,
                email: user.email,
                name: user.displayName,
            };
            console.log(`Firestore User: ${JSON.stringify(firestoreUser)}`);
            await setDoc(doc(db, "customers", user.uid), {
                uid: user.uid,
                email: user.email,
                name: user.displayName,
            });
        } catch (error) {
            console.log(`Firestore Error: ${JSON.stringify(error)}`);
            await logCustomAnalyticsEvent("sign_up_error", JSON.stringify(error));
        }
    }

    async function signupCarer(email, password, name, contactNumber) {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            setUser(userCredential.user);
            const userData = {
                userid: userCredential.user.uid,
                name: name,
                contactNumber: contactNumber,
                email: email,
            };
            const setupCareHome = httpsCallable(functions, "setupCarer");
            await setupCareHome(userData);
            await resolveClaimsData(userCredential.user);
            await logCustomAnalyticsEvent("sign_up", {id: userData.userid});
        } catch (error) {
            await logCustomAnalyticsEvent("sign_up_error", error);
        }
    }

    async function signupCarerFromCareHomeInvite(email, password, name, contactNumber, originURL, careHomeId) {
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            setUser(auth.currentUser);
            const setupCarer = httpsCallable(functions, "createCarerForCareHome");
            const payload = {
                careHomeId: careHomeId,
                originURL: originURL,
                userData: {
                    uid: auth.currentUser.uid,
                    name: name,
                    contactNumber: contactNumber,
                    email: email,
                },
            };
            await setupCarer(payload);
            await resolveClaimsData(auth.currentUser);
            await logCustomAnalyticsEvent("sign_up_care_home_invite", {id: auth.currentUser.uid});
        } catch (error) {
            await logCustomAnalyticsEvent("sign_up_care_home_invite_error", error);
        }
    }

    async function startTrial() {
        try {
            const careProviderEnableTrial = httpsCallable(functions, "careProviderEnableTrial");
            const payload = {
                providerId: currentUser.uid,
            };
            await careProviderEnableTrial(payload);
            await resolveClaimsData(currentUser);
            await logCustomAnalyticsEvent("trial_started", {userId: currentUser.uid});
        } catch (error) {
            await logCustomAnalyticsEvent("trial_started_error", error);
        }
    }

    async function login(email, password) {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            console.log(`Auth.currentUser: ${JSON.stringify(auth.currentUser)}`);
            setUser(auth.currentUser);
            await resolveClaimsData(auth.currentUser);
            await logCustomAnalyticsEvent("log_in", {id: auth.currentUser.uid});
        } catch (error) {
            console.log(JSON.stringify(error));
            await logCustomAnalyticsEvent("log_in_error", error);
            throw error;
        } finally {
            setLoading(false);
        }
    }

    async function loginNewCarer(email) {
        try {
            await signInWithEmailAndPassword(auth, email, "NewUser");
            setUser(auth.currentUser);
            await resolveClaimsData(auth.currentUser);
            await logCustomAnalyticsEvent("log_in_new_carer", {id: auth.currentUser.uid});
        } catch (error) {
            await logCustomAnalyticsEvent("log_in_new_carer_error", error);
        } finally {
            setLoading(false);
        }
    }

    async function passwordlessLogin(email) {
        const origin = window.location.origin;
        const payloadURL = `${origin}/login`;
        const payload = {
            email: email,
            actionCodeSettings: {
                url: payloadURL,
                handleCodeInApp: true,
            },
        };
        const generateEmailLink = httpsCallable(functions, "generateEmailLink");
        return generateEmailLink({payload});
    }

    async function resetPassword(email) {
        const origin = window.location.origin;
        const payloadURL = `${origin}/login`;
        const payload = {
            email: email,
            actionCodeSettings: {
                url: payloadURL,
                handleCodeInApp: true,
            },
        };
        const resetPassword = httpsCallable(functions, "resetPassword");
        return resetPassword({payload});
    }

    async function updateUserName(name) {
        await updateProfile(auth.currentUser, {displayName: name});
        await resolveClaimsData(auth.currentUser);
        setUser(auth.currentUser);
    }

    async function logout() {
        await signOut(auth);
        await clearData();
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setLoading(false);
        });
        return unsubscribe;
    });

    async function resolveClaimsData(user) {
        const idTokenResult = await user.getIdTokenResult(true);
        console.log(`ResolveClaimsData: ${JSON.stringify(idTokenResult.claims)}`);
        setRole(idTokenResult.claims.role);
        setAdminUpdatedSubscription(idTokenResult.claims.subscription);
        setStripeSubscription(idTokenResult.claims.stripeRole);
    }

    async function clearData() {
        setCurrentUser(null);
        localStorage.removeItem("currentUserSubscriptionType");
        localStorage.removeItem("currentUserRole");
        localStorage.removeItem("currentUser");
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                currentUserRole,
                getRole,
                getUser,
                updateUser,
                updateUserName,
                currentUserSubscriptionType,
                getAdminUpdatedSubscription,
                getStripeSubscription,
                login,
                loginNewCarer,
                passwordlessLogin,
                signup,
                signupCarer,
                signupCarerFromCareHomeInvite,
                startTrial,
                logout,
                resetPassword,
            }}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
