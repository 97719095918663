import React from "react";
import {Typography} from "@material-tailwind/react";

export default function RequestADetail() {
    return (
        <>
            <div className="container mx-auto mb-20 text-center">
                <Typography
                    color="yellow"
                    className="mb-2 font-bold uppercase">
                    how it works
                </Typography>
                <Typography
                    variant="h1"
                    color="blue-gray"
                    className="mb-4">
                    Request-A by Daily Sparkle
                </Typography>
                <Typography
                    variant="lead"
                    className="mx-auto w-full px-4 !text-gray-500 lg:w-8/12 lg:px-8">
                    Pioneering Personalized Reminiscence Therapy in Care Homes with Advanced Technology and Human
                    Insight
                </Typography>
            </div>
            <div className="container mx-auto grid items-center lg:grid-cols-2">
                <div className="row-start-2 mt-12 lg:row-auto lg:mt-0 lg:pr-12">
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        In the realm of elderly care, the power of reminiscence therapy has long been recognized as a
                        vital tool for enhancing the quality of life for care home residents. Daily Sparkle's innovative
                        feature, "Request-A", is setting a new standard in this field, merging groundbreaking technology
                        with the invaluable human touch to create custom articles and quizzes that resonate with the
                        personal histories and interests of the elderly.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        Technology Meets Personalization in Elderly Care
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        Request-A harnesses advanced algorithms to tailor content specifically for reminiscence therapy.
                        This feature allows caregivers and residents in care homes to request articles and quizzes that
                        reflect the personal experiences, cultural backgrounds, and historical interests of the
                        residents. Whether it's an article about a specific era, a quiz on a beloved pastime, or content
                        that triggers happy memories, Request-A’s technology ensures that each piece is relevant and
                        engaging, facilitating meaningful interaction and reflection.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        Human Oversight: Ensuring Relevance and Compassion
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        While technology forms the backbone of Request-A, the role of human editors is indispensable.
                        These professionals review each piece of content, ensuring that it is not only factually
                        accurate but also gentle, respectful, and empathetic. This human touch is crucial in maintaining
                        the dignity and emotional well-being of elderly residents, as they connect with their past
                        through these personalized articles and quizzes.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        Enhancing Reminiscence Therapy in Care Homes
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        Request-A is proving to be a valuable asset in care homes, providing a unique way to engage
                        residents in reminiscence therapy. This therapy is known to boost mood, improve communication,
                        and foster a sense of identity and belonging among the elderly, particularly those with
                        dementia. By bringing forth tailored content that echoes the personal journeys of the residents,
                        Daily Sparkle aids in sparking conversations, sharing memories, and creating new joyful moments.
                    </Typography>
                </div>
                <img
                    src="/img/bg-request.jpg"
                    alt="team work"
                    className=" h-full min-h-[50vh] w-full rounded-xl object-cover object-center md:min-h-[75vh]"
                />
            </div>
        </>
    );
}
