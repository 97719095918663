import React, {useEffect, useState, useMemo} from "react";
import {getDatabase, ref, get, child} from "firebase/database";
import MaterialReactTable from "material-react-table";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {Button, Input, Typography} from "@material-tailwind/react";
import {InformationCircleIcon} from "@heroicons/react/24/solid";
import {functions} from "utils/firebase.js";
import {httpsCallable} from "firebase/functions";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
        />
    );
});

export default function QuizManage() {
    const [requestedQuizzes, setRequestedQuizzes] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [selectedQuiz, setSelectedQuiz] = useState();
    const [quizTopic, setQuizTopic] = useState();

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState();
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const columns = useMemo(
        () => [
            {
                accessorKey: "date", //access nested data with dot notation
                header: "Date of request",
            },
            {
                accessorKey: "topic",
                header: "Quiz Topic",
            },
            {
                accessorKey: "requestedBy", //normal accessorKey
                header: "User UID",
            },
        ],
        [],
    );

    useEffect(() => {
        getRequestedQuizzes();
    }, []);

    const getRequestedQuizzes = () => {
        const dbRef = ref(getDatabase());
        get(child(dbRef, "/requestedQuizzes"))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const returnedVal = snapshot.val();
                    const keys = Object.keys(returnedVal);
                    const retrievedQuizRequests = keys.map(function (key) {
                        const retrievedQuizRequest = returnedVal[key];
                        return {
                            id: key,
                            date: retrievedQuizRequest.date,
                            topic: retrievedQuizRequest.topic,
                            requestedBy: retrievedQuizRequest.requestedBy,
                        };
                    });
                    setRequestedQuizzes(retrievedQuizRequests);
                } else {
                    console.log("No data available");
                }
            })
            .catch((err) => {
                console.error(err);
                setSnackbarText("Failed to retrieve requested quizzes. Please try again");
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            })
            .finally(() => {
                setOpenBackdrop(false);
            });
    };

    useEffect(() => {
        const keys = Object.keys(rowSelection);
        if (keys && keys.length === 1) {
            const indexFromKey = keys[0];
            const quiz = requestedQuizzes[indexFromKey];
            setSelectedQuiz(quiz);
        }
    }, [rowSelection, requestedQuizzes]);

    useEffect(() => {
        if (selectedQuiz && selectedQuiz !== null) {
            setQuizTopic(selectedQuiz.topic);
        }
    }, [selectedQuiz]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const onChange = ({target}) => setQuizTopic(target.value);

    const onSubmitRequestedQuiz = () => {
        const response = httpsCallable(functions, "generateRequestedQuiz");
        const payload = {
            id: selectedQuiz.id,
            topic: quizTopic,
            requestedBy: selectedQuiz.requestedBy,
        };
        setOpenBackdrop(true);
        response(payload)
            .then((result) => {
                setSnackbarSeverity("success");
                setSnackbarText(
                    "Quiz approval has been submitted. The user will receive an email advising it is ready for viewing.",
                );
                setOpenSnackbar(true);
                setQuizTopic("");
                getRequestedQuizzes();
            })
            .catch((error) => {
                setSnackbarSeverity("error");
                setSnackbarText("There was an error submitting your request. Please try again later.");
                setOpenSnackbar(true);
                console.log({error});
            })
            .finally(() => {
                setOpenBackdrop(false);
            });
    };

    return (
        <>
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="flex flex-wrap">
                <div className="w-full mb-12 xl:mb-12 px-8 pt-8 pb-8">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">Quiz Requests</h6>
                            </div>
                        </div>
                        <hr className="mt-1 border-b-1 border-blueGray-300" />
                        <div className="flex-auto px-4 lg:px-4 py-4 pt-0">
                            <div className="overflow-x-auto py-4">
                                <MaterialReactTable
                                    columns={columns}
                                    data={requestedQuizzes}
                                    enableRowSelection
                                    enableMultiRowSelection={false} //shows radio buttons instead of checkboxes
                                    onRowSelectionChange={setRowSelection}
                                    state={{rowSelection}}
                                    enableTopToolbar={true}
                                    enableColumnActions={false}
                                />
                            </div>
                        </div>
                    </div>
                    {selectedQuiz && (
                        <>
                            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                                <div className="rounded-t mb-0 px-6 py-6">
                                    <div className="text-center flex justify-between">
                                        <h6 className="text-blueGray-700 text-xl font-bold">
                                            Create the requested quiz
                                        </h6>
                                    </div>
                                </div>
                                <hr className="mt-1 border-b-1 border-blueGray-300" />
                                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                    <form>
                                        <div className="flex flex-wrap">
                                            <div className="w-full pt-8 px-4">
                                                <div className="relative w-full mb-3">
                                                    <Input
                                                        type="text"
                                                        label="Quiz Topic"
                                                        color="yellow"
                                                        value={quizTopic}
                                                        onChange={onChange}
                                                        className="pr-20"
                                                        containerProps={{
                                                            className: "min-w-0",
                                                        }}
                                                    />
                                                    <Button
                                                        onClick={() => onSubmitRequestedQuiz()}
                                                        size="sm"
                                                        color={quizTopic ? "yellow" : "blue-gray"}
                                                        disabled={!quizTopic}
                                                        className="!absolute right-1 top-1 rounded">
                                                        APPROVE & SUBMIT
                                                    </Button>
                                                </div>
                                                <div className="mx-auto relative justify-left flex max-w-full">
                                                    <Typography
                                                        variant="small"
                                                        color="gray"
                                                        className="flex items-start gap-1 font-normal mt-2">
                                                        <InformationCircleIcon className="w-4 h-4 -mt-px" />
                                                        Feel free to amend the text to correct any spellings or make the
                                                        topic more descriptive/concise
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                    )}

                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}>
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={snackbarSeverity}
                            sx={{width: "100%"}}>
                            {snackbarText}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
        </>
    );
}
