import SubscriptionUnregistered from "components/SubscriptionUnregistered";
import React from "react";

export default function PricingView() {
    return (
        <>
            <SubscriptionUnregistered />
        </>
    );
}
