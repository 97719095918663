import React from "react";
import {Typography, Button, Card, CardBody, Chip, CardFooter} from "@material-tailwind/react";
import {ArrowRightIcon} from "@heroicons/react/24/outline";
import {trialProduct, premiumProduct, standardProduct} from "data/Products";

function PricingCard({payload, onClick}) {
    return (
        <Card
            variant="gradient"
            color="white">
            <CardBody className="text-center h-full">
                <Chip
                    value={payload.packageName}
                    color="blue-gray"
                    className="mx-auto -mt-9 w-32 rounded-full !bg-blue-gray-50 pt-2 !text-blue-gray-900"
                />
                <Typography
                    variant="h1"
                    color="blue-gray"
                    className="mb-2 mt-10 flex justify-center">
                    {payload.packageName !== "Trial" && (
                        <Typography
                            as="span"
                            variant="h5"
                            color="blue-gray">
                            $
                        </Typography>
                    )}
                    {payload.price}
                    {payload.packageName !== "Trial" && (
                        <Typography
                            as="span"
                            variant="h5"
                            color="blue-gray">
                            per month
                        </Typography>
                    )}
                </Typography>

                <ul className="my-6 flex flex-col gap-3 pt-6">
                    {payload.features.map((option, key) => (
                        <li
                            key={key}
                            className="flex gap-2 text-gray-700">
                            <Typography className="font-normal text-left">{option}</Typography>
                        </li>
                    ))}
                </ul>
            </CardBody>
            <CardFooter>
                <Button
                    onClick={() => onClick(payload)}
                    fullWidth
                    variant="gradient"
                    color="yellow"
                    className="flex items-center justify-center gap-4">
                    join now
                    <ArrowRightIcon
                        className="h-5 w-5"
                        strokeWidth={2}
                    />
                </Button>
            </CardFooter>
        </Card>
    );
}

export function PricingSection({onClick}) {
    const products = [trialProduct, standardProduct, premiumProduct];

    return (
        <section className="p-4">
            <Card
                shadow={false}
                className="via-light-gray-900 min-h-[30vh] rounded-xl bg-gradient-to-br from-yellow-500 to-yellow-600 px-8 py-24">
                <div className="container mx-auto text-center">
                    <Typography
                        variant="h2"
                        color="white"
                        className="mb-4">
                        THE DAILY SPARKLE SUBSCRIPTIONS
                    </Typography>
                    <Typography
                        variant="lead"
                        color="white"
                        className="mb-8 opacity-70">
                        Choose the package that works best for you
                    </Typography>
                </div>
            </Card>
            <div className="-mt-32 px-8 pt-8 pb-16">
                <div className="container mx-auto grid gap-10 md:grid-cols-2 lg:grid-cols-3">
                    {products.map((product) => (
                        <PricingCard
                            payload={product}
                            onClick={onClick}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PricingSection;
