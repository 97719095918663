import {Card, CardBody, Typography} from "@material-tailwind/react";

function TestimonialCard({img, rating, feedback, client, title}) {
    return (
        <Card
            color="transparent"
            shadow={false}
            className="p-8">
            <CardBody className="!p-0">
                <Typography
                    variant="h5"
                    color="blue-gray"
                    className="mb-1">
                    {client}
                </Typography>
                <Typography className="mb-4 font-normal !text-gray-500">{title}</Typography>
                <Typography
                    variant="lead"
                    color="blue-gray"
                    className="mb-8 font-medium">
                    &quot;{feedback}&quot;
                </Typography>
            </CardBody>
        </Card>
    );
}

const testimonials = [
    {
        feedback:
            "Our residents love their Daily Sparkle and some get passed on to family and friends. When we discuss the Daily Sparkle in a group it’s amazing the memories it brings back for our residents.",
        client: "Sue",
        title: "Care Home Manager",
    },
    {
        feedback:
            "The content was just perfect and the lovely clear colourful pictures and easy to read print held my mum’s attention. My mum was 100 years old when she died and I have happy memories of sharing the Daily Sparkle with her in the last few years when other avenues of entertainment were less accessible to her. We would talk about the articles in it and follow up with listening to the music references on my iPad.",
        client: "Maria",
        title: "Relative",
    },
    {
        feedback:
            "The Sparkle also comes with an excellent set of carer’s notes. It is a wonderful reminiscing tool and the CareGivers love it too. I am so glad I came across it because I know how much joy it brings to our clients.",
        client: "Julia",
        title: "Care Home Owner",
    },
];

export function TestimonialSection() {
    return (
        <section className="py-8 px-8 lg:py-8">
            <div className="container mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                {testimonials.map((props, key) => (
                    <TestimonialCard
                        key={key}
                        {...props}
                    />
                ))}
            </div>
        </section>
    );
}

export default TestimonialSection;
