import React from "react";
import {useNavigate} from "react-router-dom";
import {Typography, Button, Card, CardBody} from "@material-tailwind/react";
import {premiumProduct, standardProduct} from "data/Products";
import {PricingCard} from "components/PricingCard";
import {useAuth} from "providers/AuthContext";
import {addCheckoutSession} from "utils/firestore/subscriptionFirestore";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";

function StatsCard({title, desc, link}) {
    const navigate = useNavigate();

    async function onClickReadMore() {
        console.log(link);
        navigate(link);
    }

    return (
        <Card
            shadow={false}
            color="transparent">
            <CardBody>
                <Typography
                    variant="h2"
                    className="mb-4 text-center">
                    {title}
                </Typography>

                <Typography variant="para">{desc}</Typography>
                {link && (
                    <Button
                        fullWidth
                        onClick={() => onClickReadMore()}
                        className="mt-8"
                        size="sm"
                        color="yellow">
                        Read More
                    </Button>
                )}
            </CardBody>
        </Card>
    );
}

const stats = [
    {
        title: "Articles",
        desc: "A rich array of engaging and informative content, catering to a diverse range of interests and preferences for its users.",
        link: "/member/details/articles",
    },
    {
        title: "Activities",
        desc: "Top quality, professionally created activities for older people and people living with dementia.",
        link: "/member/details/activities",
    },
    {
        title: "Request-A",
        desc: "A unique opportunity for users to request custom quizzes and articles on topics of their choice, enhancing personalization and engagement.",
        link: "/member/details/request-an-article",
    },
    {
        title: "Videos",
        desc: "A captivating collection of visual content, designed to entertain, educate, and inspire its audience through a variety of engaging topics.",
        link: "/member/details/videos",
    },
];

export function TrialWelcomeView() {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const payloads = [standardProduct, premiumProduct];
    const navigate = useNavigate();
    const {currentUser} = useAuth();

    async function onClickContactUs() {
        navigate("/contactus");
    }

    async function onClick(payload) {
        console.log(`Payload: ${JSON.stringify(payload)}`);
        if (payload.packageName === "Standard") {
            await onClickSubscription(payload);
        } else if (payload.packageName === "Premium") {
            await onClickSubscription(payload);
        }
    }

    async function onClickSubscription(payload) {
        setLoading(true);
        await addCheckoutSession(payload, currentUser.uid, assignUrl);
    }

    async function assignUrl(url, error) {
        if (url === undefined && error === undefined) {
            return;
        }
        setLoading(false);
        if (error) {
            console.error("There was an error creating a checkout session", error);
            showAlert("There was an error subscribing. Please try again later.");
        } else {
            window.location.assign(url);
        }
    }

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="px-8 py-4 ">
                <div className="container mx-auto mt-8">
                    <div className="container mx-auto mb-8 text-center">
                        <Typography
                            color="yellow"
                            className="mb-2 font-bold uppercase">
                            Daily Sparkle Australia
                        </Typography>
                        <Typography
                            variant="h1"
                            className="mx-auto mb-12 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20 text-center">
                            Welcome to Australia's leading activity resource company
                        </Typography>
                    </div>

                    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4 mb-12 ">
                        {stats.map((props, key) => (
                            <StatsCard
                                className=""
                                key={key}
                                {...props}
                            />
                        ))}
                    </div>

                    <div className="w-full lg:container lg:mx-auto">
                        <img
                            alt="Lady looking at newspaper"
                            src="/img/bg-reading-sparkle.png"
                            className="h-48 w-full rounded-xl object-cover md:h-[48rem] lg:h-[24rem]"
                        />
                    </div>

                    <div className="container mx-auto pt-12 mb-8 text-center">
                        <Typography
                            color="yellow"
                            className="mb-2 font-bold uppercase">
                            Ready to get the full experience?
                        </Typography>
                        <Typography
                            variant="h1"
                            className="mx-auto mb-12 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20 text-center">
                            Check our subscription offers
                        </Typography>
                    </div>

                    <div className="grid m-12 gap-20 grid-cols-3">
                        {payloads &&
                            payloads.map((payload) => (
                                <PricingCard
                                    payload={payload}
                                    onClick={onClick}
                                />
                            ))}
                        <div className="my-auto">
                            <Typography
                                className="mb-8"
                                variant="h2">
                                Looking for an enterprise solution?
                            </Typography>
                            <Typography
                                className="mb-8"
                                variant="para">
                                Contact Daily Sparkles Australia today to explore our tailored enterprise subscriptions,
                                designed to meet the unique needs of your organization with our comprehensive range of
                                engaging and educational content.
                            </Typography>
                            <Button
                                onClick={() => onClickContactUs()}
                                fullWidth
                                variant="gradient"
                                color="yellow"
                                className="flex items-center justify-center gap-4">
                                Contact Us{" "}
                            </Button>
                        </div>
                    </div>
                </div>
                {openSnackbar && <SnackbarComponent />}
            </section>
        </>
    );
}

export default TrialWelcomeView;
