import React, {useState} from "react";
import {Typography, Input, Textarea, Button} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";
import validator from "validator";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {functions} from "utils/firebase.js";
import {httpsCallable} from "firebase/functions";
import logCustomAnalyticsEvent from "utils/DSAnalytics";

export function ContactUsView() {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const navigate = useNavigate();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [contactNumber, setContactNumber] = useState();
    const [message, setMessage] = useState();

    const onSubmit = async () => {
        if (validator.isEmail(email) === false) {
            showAlert("Invalid email. Please try again");
            return;
        }

        setLoading(true);
        try {
            const submitContactUsRequest = httpsCallable(functions, "contactUsRequest");
            const payload = {
                email: email,
                name: name,
                contactNumber: contactNumber,
                message: message,
            };
            await submitContactUsRequest(payload);
            navigate("thanks", {replace: true});
        } catch (error) {
            showAlert("Something went wrong. Please try again");
            logCustomAnalyticsEvent("contact_us", {error: error});
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="grid h-screen items-center lg:grid-cols-2">
                <div className="mx-auto p-10 lg:max-w-xl">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Contact us
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-16 !text-gray-500">
                        Whether you have questions or you would just like to say hello, contact us.
                    </Typography>

                    <form>
                        <div className="mb-4">
                            <Input
                                color="yellow"
                                required
                                size="lg"
                                label="Name"
                                type="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="mb-4">
                            <Input
                                color="yellow"
                                required
                                size="lg"
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-4">
                            <Input
                                color="yellow"
                                required
                                size="lg"
                                label="Contact Number"
                                type="tel"
                                value={contactNumber}
                                onChange={(e) => setContactNumber(e.target.value)}
                            />
                        </div>
                        <div className="mb-4 h-max">
                            <Textarea
                                label="Message"
                                color="yellow"
                                required
                                size="lg"
                                fullWidth
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </div>

                        <Button
                            onClick={() => onSubmit()}
                            color="yellow"
                            size="lg"
                            disabled={!email || !name || !contactNumber || !message}
                            className="mt-6"
                            fullWidth>
                            SEND
                        </Button>
                    </form>
                </div>
                <img
                    alt="Lady looking at newspaper"
                    src="/img/bg-reading-sparkle.png"
                    className="hidden h-full min-h-screen w-full object-cover lg:block"
                />
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}

export default ContactUsView;
