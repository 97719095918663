import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Chip} from "@material-tailwind/react";
import {Typography} from "@material-tailwind/react";
import {getDatabase, ref, get, child} from "firebase/database";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
        />
    );
});

export default function ArticlePreview() {
    const params = useParams();
    const database = getDatabase();
    const [tags, setTags] = useState();
    const [article, setArticle] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [openBackdrop, setOpenBackdrop] = useState(false);

    useEffect(() => {
        const dbRef = ref(database);
        const identifier = params.identifier;
        get(child(dbRef, `/requestedArticles/${identifier}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    const contentObject = JSON.parse(response.content);
                    response["contentObject"] = contentObject;
                    setArticle(response);
                    if (response.tags) {
                        setTags(Object.keys(response.tags));
                    }
                }
            })
            .catch((error) => {
                setSnackbarSeverity("error");
                setSnackbarText("Error retrieving article. Please try again.");
                setOpenSnackbar(true);
                console.error(error);
            })
            .finally(() => {
                setOpenBackdrop(false);
            });
    }, [database, params.identifier]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-10 pb-6">
                <div className="w-full h-60">
                    <img
                        alt="Daily sparkles logo"
                        class="max-w-full mx-auto pt-6 object-center h-60 content-center mb-4"
                        src={require("assets/img/dailysparklelogo-large.jpg")}
                    />
                </div>
                <div class="flex items-stretch flex-wrap p-4 mb-4 gap-4 text-center justify-center">
                    <Typography
                        variant="h1"
                        color="red">
                        THIS IS A PREVIEW
                    </Typography>
                </div>
                {article && (
                    <>
                        <div class="flex items-stretch flex-wrap px-6 mb-2">
                            <Typography
                                variant="h2"
                                color="blue-gray"
                                className="mb-2">
                                {article.contentObject.title}
                            </Typography>
                        </div>
                        {tags && (
                            <div className="flex gap-2 px-6 pb-2">
                                {tags.map((tag) => (
                                    <Chip
                                        color="yellow"
                                        variant="ghost"
                                        value={tag}
                                    />
                                ))}
                            </div>
                        )}
                        <div class="flex items-stretch flex-wrap p-6 mb-4">
                            {article.contentObject.paragraphs.map((para) => (
                                <Typography
                                    variant="paragrah"
                                    color="blue-gray"
                                    className="mb-4">
                                    {para}
                                </Typography>
                            ))}
                        </div>
                    </>
                )}
            </div>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{width: "100%"}}>
                    {snackbarText}
                </Alert>
            </Snackbar>
        </>
    );
}
