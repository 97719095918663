import {Typography} from "@material-tailwind/react";
import React from "react";

export default function WeeklyDownloadsDetail() {
    return (
        <>
            <div className="container mx-auto mb-20 text-center">
                <Typography
                    color="yellow"
                    className="mb-2 font-bold uppercase">
                    how it works
                </Typography>
                <Typography
                    variant="h1"
                    color="blue-gray"
                    className="mb-4">
                    Daily Sparkle Newspapers
                </Typography>
                <Typography
                    variant="lead"
                    className="mx-auto w-full px-4 !text-gray-500 lg:w-8/12 lg:px-8">
                    A Window to the Past for Care Home Residents
                </Typography>
            </div>
            <div className="container mx-auto grid items-center lg:grid-cols-2">
                <div className="row-start-2 mt-12 lg:row-auto lg:mt-0 lg:pr-12">
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        In the landscape of elderly care, reminiscence therapy plays a crucial role in enhancing the
                        quality of life for residents, especially those living with dementia. Daily Sparkle has emerged
                        as a trailblazer in this arena with its unique offering – a series of specially designed
                        newspapers that serve as powerful tools for memory recall and engagement. Accompanied by Carer's
                        Notes, these newspapers are not just publications; they are bridges connecting the present with
                        the past.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        Daily Sparkle Newspapers: Nostalgia and Knowledge Combined
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        Each edition of Daily Sparkle newspapers is crafted with care, keeping in mind the cognitive and
                        emotional needs of the elderly. These newspapers feature a variety of articles that range from
                        historical events to popular culture of yesteryears, music, famous personalities, and more. The
                        content is carefully selected to evoke memories of the past, triggering conversations and
                        reminiscences among residents. The newspapers are designed to be easy to read, with large print
                        and clear images, making them accessible to all residents, including those with visual
                        impairments.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        Carer's Notes: Facilitating Meaningful Conversations
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        A standout feature of Daily Sparkle's service is the inclusion of Carer's Notes as an addendum
                        to the newspapers. These notes serve as a valuable resource for caregivers in care homes,
                        providing background information and discussion prompts related to the newspaper articles. They
                        help caregivers engage residents in meaningful conversations, encouraging them to share their
                        memories and experiences. This not only aids in cognitive stimulation but also fosters a sense
                        of belonging and connection among the residents and staff.
                    </Typography>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                        className="mb-6">
                        More Than Just Reminiscence
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mb-12 !text-gray-500">
                        While reminiscence is a key aspect of the Daily Sparkle newspapers, their benefits extend far
                        beyond. Reading and discussing these newspapers helps in keeping the residents mentally active
                        and engaged. It can also improve their communication skills and provide a sense of routine and
                        normalcy, which is particularly important in a care home setting. For many residents, the Daily
                        Sparkle newspaper becomes a part of their daily ritual, something they look forward to each day.
                    </Typography>
                </div>
                <img
                    src="/img/weekly-sparkle-mock-up-1.jpg"
                    alt="team work"
                    className=" h-full min-h-[50vh] w-full rounded-xl object-contain object-top md:min-h-[75vh]"
                />
            </div>
        </>
    );
}
