import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Typography} from "@material-tailwind/react";
import DataTable from "react-data-table-component";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {getAllLargeFontSparkles} from "services";

function LargeFontSparklesTable({largeFontSparkles}) {
    const navigate = useNavigate();

    const columns = [
        {
            name: "Name",
            selector: (row) => row.name,
        },
    ];

    const handleRowClicked = (row) => {
        navigate(`/member/largeFontSparkles/${row.id}`);
    };

    return (
        <>
            <Typography
                variant="h6"
                className="pt-8 text-left">
                Sensory Sparkles
            </Typography>
            {largeFontSparkles && (
                <>
                    <DataTable
                        highlightOnHover
                        pointerOnHover
                        pagination
                        selectableRowsHighlight
                        columns={columns}
                        data={largeFontSparkles}
                        onRowClicked={handleRowClicked}
                    />
                </>
            )}
        </>
    );
}

export default function LargeFontSparklesView() {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [largeFontSparkles, setLargeFontSparkles] = useState([]);

    useEffect(() => {
        setLoading(true);
        getAllLargeFontSparkles()
            .then((res) => {
                setLargeFontSparkles(res);
            })
            .catch((err) => {
                console.error(err);
                setLargeFontSparkles([]);
                showAlert(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, showAlert]);

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="py-10 px-4">
                <div className="container mx-auto mb-4 text-center">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Large Font Sparkles
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        Giant font version of the ever-popular Daily Sparkle newspaper, designed with visually impaired
                        users in mind.
                    </Typography>

                    {largeFontSparkles && <LargeFontSparklesTable largeFontSparkles={largeFontSparkles} />}
                </div>
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
