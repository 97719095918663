import {Typography} from "@mui/material";

export default function CheckYourEmailView() {
    return (
        <>
            <section className="grid h-screen items-center lg:grid-cols-2">
                <div className="my-auto p-8 text-center sm:p-10 md:p-20 xl:px-32 xl:py-24">
                    <div className="flex flex-col">
                        <img
                            alt="Daily sparkles logo"
                            class="content-center mb-8mx-auto"
                            src={require("assets/img/dailysparklelogo-large.jpg")}
                        />

                        <div className="mx-auto min-w-[32rem] max-w-[32rem] text-center pt-4">
                            <Typography
                                variant="leading"
                                className="mx-auto">
                                If your email address is registered, we will send you a link to action.
                            </Typography>
                        </div>
                    </div>
                </div>
                <img
                    alt="Lady looking at newspaper"
                    src="/img/bg-reading-sparkle.png"
                    className="hidden h-screen w-full object-cover lg:block"
                />
            </section>
        </>
    );
}
