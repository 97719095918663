import {Button} from "@material-tailwind/react";
import React from "react";

export default function TrialBanner({onClickStartFreeTrial}) {
    return (
        <>
            <div class="text-center flex flex-col p-4 md:text-left md:flex-row md:items-center md:justify-between md:p-8 bg-yellow-500 rounded-md">
                <div class="text-2xl font-semibold">
                    <div class="text-gray-900">
                        Receive all our products completely FREE for FOUR WEEKS. No credit card details needed, no
                        auto-renew.
                    </div>
                </div>

                <div class="mt-3 md:mt-0 md:ml-2">
                    <div class="inline-block uppercase rounded-md text-lg font-semibold py-2 px-4 text-black">
                        <Button
                            onClick={() => onClickStartFreeTrial()}
                            size="lg"
                            color="white">
                            START A FREE TRIAL NOW
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
