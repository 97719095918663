import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Chip} from "@material-tailwind/react";
import {Typography} from "@material-tailwind/react";
import QuizCard from "components/quiz/QuizCard";
import {useAuth} from "providers/AuthContext";
import {getQuiz} from "services";

export default function QuizDetailView() {
    const params = useParams();
    const [tags, setTags] = useState();
    const {currentUser} = useAuth();
    const [quizTitle, setQuizTitle] = useState();
    const [quizBlurb, setQuizBlurb] = useState();

    const [questionsAndAnswers, setQuestionsAndAnswers] = useState();
    const [currentQuestionAndAnswer, setCurrentQuestionAndAnswer] = useState();
    // const navigate = useNavigate();

    useEffect(() => {
        const identifier = params.identifier;

        getQuiz(currentUser.uid, identifier)
            .then((res) => {
                setTags(res.tags);
                setQuizBlurb(res.blurb);
                setQuizTitle(res.title);
                setQuestionsAndAnswers(res.quiz);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [params.identifier, currentUser.uid]);

    useEffect(() => {
        if (questionsAndAnswers !== undefined) {
            setCurrentQuestionAndAnswer(questionsAndAnswers[0]);
        }
    }, [questionsAndAnswers]);

    async function onNext() {
        const nextQuestionAndAnswer = questionsAndAnswers[currentQuestionAndAnswer.index + 1];
        setCurrentQuestionAndAnswer(nextQuestionAndAnswer);
    }

    async function onPrev() {
        const previousQuestionAndAnswer = questionsAndAnswers[currentQuestionAndAnswer.index - 1];
        setCurrentQuestionAndAnswer(previousQuestionAndAnswer);
    }

    async function onExpand() {
        //navigate("/fullscreen", {state: {questionsAndAnswers: questionsAndAnswers, quiz: quiz}});
    }

    return (
        <>
            <header className="px-4 pr-4">
                {quizTitle && (
                    <>
                        <div className="w-w-full container mx-auto pt-12 pb-8">
                            <Typography
                                variant="h1"
                                color="blue-gray"
                                className="mx-auto mb-8 w-full leading-tight lg:max-w-2xl text-center">
                                {quizTitle}
                            </Typography>
                            <div className="flex flex-row mx-auto gap-2 lg:max-w-2xl mb-8 justify-center">
                                {tags.map((tag) => (
                                    <Chip
                                        color="yellow"
                                        value={tag}
                                    />
                                ))}
                            </div>
                            <Typography
                                variant="paragrah"
                                color="blue-gray"
                                className="mx-auto mb-12 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20">
                                {quizBlurb}
                            </Typography>
                            <div className="mx-auto mb-12 w-full lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20">
                                {currentQuestionAndAnswer && questionsAndAnswers && (
                                    <QuizCard
                                        index={currentQuestionAndAnswer.index}
                                        question={currentQuestionAndAnswer.question}
                                        answer={currentQuestionAndAnswer.answer}
                                        onNext={onNext}
                                        onPrev={onPrev}
                                        onExpand={onExpand}
                                        numberOfQuestions={questionsAndAnswers.length}
                                    />
                                )}
                            </div>
                        </div>
                    </>
                )}
            </header>
        </>
    );
}
