import React, {useEffect, useState} from "react";
import {useAuth} from "providers/AuthContext";
import {Avatar} from "@material-tailwind/react";
import {getDatabase, ref, get, child} from "firebase/database";

export function ProfileAvatar() {
    const [image, setImage] = useState();
    const database = getDatabase();
    const {currentUser} = useAuth();

    useEffect(() => {
        async function fetchData() {
            const dbRef = ref(database);
            const snapshot = await get(child(dbRef, "/careProviders/" + currentUser.uid));
            if (snapshot.exists()) {
                setImage(snapshot.val() && snapshot.val().imageURL);
            }
        }
        fetchData();
    }, [database, currentUser.uid]);

    return (
        <div className="flex flex-col">
            <div className="flex items-center gap-4">
                <Avatar
                    withBorder={true}
                    className="border-white ring-2 ring-yellow-500"
                    src={image ? image : "/img/avatarplaceholder.png"}
                    alt="avatar"
                />
            </div>
        </div>
    );
}
