import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Typography} from "@material-tailwind/react";
import DataTable from "react-data-table-component";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {getAllSensorySparkles} from "services";

function SensorySparklesTable({sensorySparkles}) {
    const navigate = useNavigate();

    const columns = [
        {
            name: "Name",
            selector: (row) => row.name,
        },
    ];

    const handleRowClicked = (row) => {
        navigate(`/member/sensorySparkles/${row.id}`);
    };

    return (
        <>
            <Typography
                variant="h6"
                className="pt-8 text-left">
                Sensory Sparkles
            </Typography>
            {sensorySparkles && (
                <>
                    <DataTable
                        highlightOnHover
                        pointerOnHover
                        pagination
                        selectableRowsHighlight
                        columns={columns}
                        data={sensorySparkles}
                        onRowClicked={handleRowClicked}
                    />
                </>
            )}
        </>
    );
}

export default function SensorySparklesView() {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [sensorySparkles, setSensorySparkles] = useState([]);

    useEffect(() => {
        setLoading(true);

        getAllSensorySparkles()
            .then((res) => {
                setSensorySparkles(res);
            })
            .catch((error) => {
                showAlert(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, showAlert]);

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="py-10 px-4">
                <div className="container mx-auto mb-4 text-center">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Sensory Sparkles
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        A Sensory Sparkle for each week of the month, containing a large image and questions in a larger
                        font to stimulate reminiscence and discussion. Ideal for individual residents with poorer
                        eyesight and also good for group discussion.
                    </Typography>

                    {sensorySparkles && <SensorySparklesTable sensorySparkles={sensorySparkles} />}
                </div>
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
