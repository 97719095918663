import React, {useState} from "react";
import {Input, Button, Typography} from "@material-tailwind/react";
import {InformationCircleIcon} from "@heroicons/react/24/solid";
import {useAuth} from "providers/AuthContext";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {requestQuiz} from "services";

export default function QuizRequestView() {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [quizTopic, setQuizTopic] = useState();
    const {currentUser} = useAuth();

    const onChange = ({target}) => setQuizTopic(target.value);

    const onClickRequestQuizTopic = async () => {
        const payload = {
            topic: quizTopic,
            requestedBy: currentUser.uid,
        };

        try {
            setLoading(true);
            await requestQuiz(payload);
        } catch (error) {
            console.error(error);
            showAlert(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="py-28 px-4">
                <div className="container mx-auto mb-20 text-center">
                    <Typography
                        color="yellow"
                        className="mb-2 font-bold uppercase">
                        Request - A
                    </Typography>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Request a Quiz
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 !text-gray-500 lg:w-8/12 lg:px-8 pb-8">
                        Share your suggestions, and we'll create quizzes that will transport you back to the golden
                        days, evoking a sense of nostalgia and joy. So, don't miss the chance to relive those cherished
                        memories - request your favorite quiz topic now!
                    </Typography>
                    <div className="mx-auto relative justify-center flex w-full pb-2">
                        <Input
                            type="text"
                            label="Quiz Topic"
                            color="yellow"
                            value={quizTopic}
                            onChange={onChange}
                            className="pr-20"
                            containerProps={{
                                className: "min-w-0",
                            }}
                        />
                        <Button
                            onClick={() => onClickRequestQuizTopic()}
                            size="sm"
                            color={quizTopic ? "yellow" : "blue-gray"}
                            disabled={!quizTopic}
                            className="!absolute right-1 top-1 rounded">
                            REQUEST
                        </Button>
                    </div>
                    <div className="mx-auto relative justify-center flex w-full">
                        <Typography
                            variant="small"
                            color="gray"
                            className="flex items-center gap-1 font-normal mt-2">
                            <InformationCircleIcon className="w-4 h-4 -mt-px" />
                            Keep the description as short and concise as possible e.g. Richmond Tigers AFL club
                        </Typography>
                    </div>
                </div>
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
