import React, {useEffect, useState} from "react";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {Link} from "react-router-dom";
import {Card, CardBody, Typography, CardFooter} from "@material-tailwind/react";
import {ArrowLongRightIcon} from "@heroicons/react/24/outline";
import {getAllVideos} from "services";

function VideosCard({video}) {
    return (
        <Card
            key={video.id}
            className="border-2 border-yellow-500">
            <CardBody>
                <div className="col-span-5">
                    <Typography
                        variant="h4"
                        className="normal-case">
                        {video.name}
                    </Typography>
                </div>
            </CardBody>
            <CardFooter className="pt-0">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex justify-end items-center gap-2 hover:text-yellow-700">
                    Watch Now
                    <ArrowLongRightIcon
                        strokeWidth={2}
                        className="w-4 h-4"
                    />
                </Typography>
            </CardFooter>
        </Card>
    );
}

export default function VideosView() {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        setLoading(true);
        getAllVideos()
            .then((res) => {
                setVideos(res);
            })
            .catch((error) => {
                console.error(error);
                showAlert(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, showAlert]);

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="py-10 px-4">
                <div className="container mx-auto mb-4 text-center">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Videos
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto mb-12 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20">
                        We have four 'Reminiscence With Jan' videos on holidays, childcare, ironing and washing day
                        which are excellent to entertain and stimulate discussion. There is also a fascinating
                        collections of old videos on a range of subjects.
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto mb-12 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20">
                        Ideal for reminiscence, starting conversations as well as fun entertainment for individuals.
                    </Typography>
                </div>
                <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-start gap-y-4 gap-x-4 px-4 pt-10 pb-16">
                    {videos &&
                        videos.map((video) => (
                            <Link to={`${video.id}`}>
                                <VideosCard video={video} />
                            </Link>
                        ))}
                </div>
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
