import React from "react";
import PricingSection from "./PricingSection";
import {useNavigate} from "react-router-dom";

export default function SubscriptionUnregistered() {
    const navigate = useNavigate();

    async function onRegister(payload) {
        navigate(`/register`);
    }

    return (
        <>
            <PricingSection onClick={onRegister} />
        </>
    );
}
