import {db} from "../firebase";
import {collection, addDoc, onSnapshot} from "firebase/firestore";

export async function addCheckoutSession(payload, uid, cb) {
    console.log("addCheckoutSession");
    if (typeof cb !== "function") {
        console.error("Error: The callback parameter is not a function");
        return;
    }

    try {
        const priceKey = payload.key;
        const docRef = await addDoc(collection(db, "customers", uid, "checkout_sessions"), {
            price: priceKey,
            success_url: `${window.location.origin}/member/thanksForSubscribing?type=${payload.packageName}`,
            cancel_url: `${window.location.origin}/member/subscriptions`,
        });

        const unsubscribe = onSnapshot(docRef, (docSnap) => {
            const data = docSnap.data();
            cb(data.url, data.error);
        });
        return unsubscribe;
    } catch (error) {
        console.error("There was an error adding the checkout session", error);
        throw error;
    }
}
