import React, {useState, useEffect, useRef} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {Button, Chip, Typography} from "@material-tailwind/react";
import {ref as storageRef, getDownloadURL, getStorage} from "firebase/storage";
import WebViewer from "@pdftron/webviewer";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import {getSensorySparkle} from "services";

export default function SensorySparkleView() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [sensorySparkle, setSensorySparkle] = useState();
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [sensorySparkleURL, setSensorySparkleURL] = useState();
    const viewer = useRef(null);

    useEffect(() => {
        const identifier = params.identifier;
        getSensorySparkle(identifier)
            .then((res) => {
                setSensorySparkle(res);
            })
            .catch((error) => {
                showAlert(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [params.identifier, showAlert, setLoading]);

    useEffect(() => {
        if (sensorySparkle === undefined) {
            return;
        }
        if (sensorySparkle.filePath !== undefined) {
            const storage = getStorage();
            getDownloadURL(storageRef(storage, sensorySparkle.filePath))
                .then((url) => {
                    console.log(`Storage URL: ${url}`);
                    setSensorySparkleURL(url);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [sensorySparkle]);

    useEffect(() => {
        WebViewer(
            {
                licenseKey: "4HDnS8TDhjBVOEhUGaLF",
                path: "/webviewer/lib",
                initialDoc: sensorySparkleURL,
            },
            viewer.current,
        ).then((instance) => {
            // now you can access APIs through the WebViewer instance
            const {Core} = instance;

            // adding an event listener for when a document is loaded
            Core.documentViewer.addEventListener("documentLoaded", () => {
                console.log("document loaded");
            });

            // adding an event listener for when the page number has changed
            Core.documentViewer.addEventListener("pageNumberUpdated", (pageNumber) => {
                console.log(`Page number is: ${pageNumber}`);
            });
        });
    }, [sensorySparkleURL]);

    const onClickDownload = () => {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = sensorySparkleURL;
        a.target = "_blank";
        a.download = "name"; // the filename you want
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(sensorySparkleURL);
    };

    return (
        <>
            {loading && <LoadingComponent />}
            <header className="px-4 pr-4">
                {searchParams.get("preview") && (
                    <div className="w-w-full container mx-auto pt-12 pb-8">
                        <Typography
                            variant="h1"
                            color="red">
                            THIS IS A PREVIEW
                        </Typography>
                    </div>
                )}
                {sensorySparkle && (
                    <>
                        <div className="w-w-full container mx-auto pt-12 pb-8">
                            <Typography
                                variant="h1"
                                color="blue-gray"
                                className="mx-auto mb-8 w-full leading-tight lg:max-w-2xl text-center">
                                {sensorySparkle.name}
                            </Typography>
                            <Typography
                                variant="paragraph"
                                color="blue-gray"
                                className="mx-auto mb-12 w-full px-8 !text-gray-500 lg:w-10/12 lg:px-12 xl:w-8/12 xl:px-20">
                                {sensorySparkle.blurb}
                            </Typography>

                            {sensorySparkle.displayableTags && (
                                <div className="flex flex-row mx-auto gap-2 lg:max-w-2xl mb-8 justify-center">
                                    {sensorySparkle.displayableTags.map((tag) => (
                                        <Chip
                                            color="yellow"
                                            value={tag}
                                        />
                                    ))}
                                </div>
                            )}
                            <div
                                className="webviewer h-600-px mb-4"
                                ref={viewer}></div>
                            <Button
                                color="yellow"
                                onClick={() => onClickDownload()}
                                fullWidth
                                size="lg">
                                Download
                            </Button>
                        </div>
                    </>
                )}
            </header>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
