import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Card, CardBody, Typography, Chip, CardFooter} from "@material-tailwind/react";
import {ArrowLongRightIcon} from "@heroicons/react/24/outline";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {useSnackbar} from "providers/SnackbarContext";
import {SnackbarComponent} from "components/SnackbarComponent";
import {getAllArticles} from "services";

function ArticlesCard({key, tags, title}) {
    return (
        <Card
            key={key}
            className="border-2 border-yellow-500 h-full">
            <CardBody>
                <div className="col-span-5">
                    <Typography
                        variant="h4"
                        className="normal-case">
                        {title}
                    </Typography>
                </div>
                <div className="flex gap-2">
                    {tags.map((tag) => (
                        <Chip
                            color="yellow"
                            key={tag}
                            value={tag}
                        />
                    ))}
                </div>
            </CardBody>
            <CardFooter className="pt-0">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex justify-end items-center gap-2 hover:text-yellow-700">
                    Read Now
                    <ArrowLongRightIcon
                        strokeWidth={2}
                        className="w-4 h-4"
                    />
                </Typography>
            </CardFooter>
        </Card>
    );
}

function RequestYourOwnCard() {
    return (
        <Card
            key="createyourown"
            className="border-2 border-yellow-500">
            <CardBody>
                <div className="col-span-5">
                    <Typography
                        variant="h4"
                        className="normal-case">
                        Request your own, custom article
                    </Typography>
                    <Typography
                        variant="paragraph"
                        className="normal-case">
                        Make your content even more customised by requesting your own article.
                    </Typography>
                </div>
            </CardBody>
            <CardFooter className="pt-0">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex justify-end items-center gap-2 hover:text-yellow-700">
                    Request Now
                    <ArrowLongRightIcon
                        strokeWidth={2}
                        className="w-4 h-4"
                    />
                </Typography>
            </CardFooter>
        </Card>
    );
}

export default function ArticlesView() {
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        setLoading(true);
        getAllArticles()
            .then((res) => {
                setArticles(res);
            })
            .catch((error) => {
                showAlert("Error retrieving articles. Please try again.");
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, showAlert]);

    return (
        <>
            {loading && <LoadingComponent />}
            <section className="py-10 px-4">
                <div className="container mx-auto mb-4 text-center">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        New Articles
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        In this curated selection of articles, we've gathered a treasure trove of nostalgic content
                        designed to ignite cherished memories and spark meaningful conversations.
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        Reminiscence therapy has the power to transport us back to cherished moments, and these articles
                        have been carefully chosen to do just that. Explore a wide range of topics, from iconic movies
                        and TV shows to vintage music and beloved trends of yesteryears.
                    </Typography>
                </div>
                <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-start gap-y-4 gap-x-4 px-4 pt-10 pb-16">
                    <Link to="request">
                        <RequestYourOwnCard />
                    </Link>
                    {articles &&
                        articles.map((article) => (
                            <Link to={article.id}>
                                <ArticlesCard
                                    key={article.id}
                                    tags={article.displayableTags}
                                    title={article.contentObject.title}
                                />
                            </Link>
                        ))}
                </div>
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
