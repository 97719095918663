import React from "react";
import {
    Navbar,
    Typography,
    Button,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Card,
    IconButton,
    Collapse,
} from "@material-tailwind/react";
import {
    UserCircleIcon,
    ChevronDownIcon,
    PowerIcon,
    Bars2Icon,
    QuestionMarkCircleIcon,
    NewspaperIcon,
    ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import {useAuth} from "providers/AuthContext";
import {ProfileAvatar} from "./ProfileAvatar";
import {Link, useNavigate} from "react-router-dom";
import dayjs from "dayjs";

function ProfileMenu() {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const closeMenu = () => setIsMenuOpen(false);
    const {logout} = useAuth();

    const myProfileOnClickAction = async () => {
        navigate("profile");
        closeMenu();
    };

    const logoutOnClickAction = async () => {
        await logout();
        navigate("/login", {replace: true});
        closeMenu();
    };

    const supportClickAction = async () => {
        navigate("contactus");
        closeMenu();
    };

    return (
        <Menu
            open={isMenuOpen}
            handler={setIsMenuOpen}
            placement="bottom-end">
            <MenuHandler>
                <Button
                    variant="text"
                    color="blue-gray"
                    className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto">
                    <ProfileAvatar />
                    <ChevronDownIcon
                        strokeWidth={2.5}
                        className={`h-3 w-3 transition-transform ${isMenuOpen ? "rotate-180" : ""}`}
                    />
                </Button>
            </MenuHandler>
            <MenuList className="p-1">
                <MenuItem
                    key="My Profile"
                    onClick={myProfileOnClickAction}
                    className={`flex items-center gap-2 rounded`}>
                    {React.createElement(UserCircleIcon, {
                        className: `h-4 w-4`,
                        strokeWidth: 2,
                    })}
                    <Typography
                        as="span"
                        variant="small"
                        className="font-normal"
                        color="inherit">
                        My Profile
                    </Typography>
                </MenuItem>
                <MenuItem
                    key="Support"
                    onClick={supportClickAction}
                    className={`flex items-center gap-2 rounded`}>
                    {React.createElement(ChatBubbleLeftRightIcon, {
                        className: `h-4 w-4`,
                        strokeWidth: 2,
                    })}
                    <Typography
                        as="span"
                        variant="small"
                        className="font-normal"
                        color="inherit">
                        Support
                    </Typography>
                </MenuItem>
                <MenuItem
                    key="Log Out"
                    onClick={logoutOnClickAction}
                    className={
                        "flex items-center gap-2 rounded hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
                    }>
                    {React.createElement(PowerIcon, {
                        className: `h-4 w-4 text-red-500`,
                        strokeWidth: 2,
                    })}
                    <Typography
                        as="span"
                        variant="small"
                        className="font-normal"
                        color="red">
                        Log Out
                    </Typography>
                </MenuItem>
            </MenuList>
        </Menu>
    );
}

const weeklyIdentifier = `${dayjs().year().toString()}-${dayjs().week().toString()}`;

const newspaperNavListMenuItems = [
    {
        title: "Weekly Downloads",
        description: "All of your weekly sparkles and downloads in one place.",
        link: `/member/downloads/${weeklyIdentifier}`,
    },
    {
        title: "Future Downloads",
        description: "Find future editions of our Sparkles Newspapers.",
        link: "/member/downloads/future",
    },
    {
        title: "Sensory Sparkles",
        description: "Find the latest Sensory Sparkles.",
        link: "/member/sensorySparkles",
    },
    {
        title: "Large Font Sparkles",
        description: "Find the latest Large Font Sparkles.",
        link: "/member/largeFontSparkles",
    },
];

const requestNavListMenuItems = [
    {
        title: "My Requests",
        description: "See all of your generated requests here",
        link: "/member/articles/request/overview",
    },
    {
        title: "Articles",
        description: "Request your own customised articles here. Curated especially for you.",
        link: "/member/articles/request",
    },
    {
        title: "Quizzes",
        description: "Request your own customised articles here. Curated especially for you.",
        link: "/member/quizzes/request",
    },
];

function DSDropdownMenu({title, dropDownIcon, navListMenuItems}) {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const renderItems = navListMenuItems.map(({title, description, link}) => (
        <Link to={link}>
            <MenuItem>
                <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-1">
                    {title}
                </Typography>
                <Typography
                    variant="small"
                    color="gray"
                    className="font-normal">
                    {description}
                </Typography>
            </MenuItem>
        </Link>
    ));

    return (
        <React.Fragment>
            <Menu
                allowHover
                open={isMenuOpen}
                handler={setIsMenuOpen}>
                <MenuHandler>
                    <Typography
                        variant="small"
                        className="font-normal">
                        <MenuItem className="hidden items-center gap-2 text-blue-gray-900 lg:flex lg:rounded-full">
                            {title}{" "}
                            <ChevronDownIcon
                                strokeWidth={2}
                                className={`h-3 w-3 transition-transform ${isMenuOpen ? "rotate-180" : ""}`}
                            />
                        </MenuItem>
                    </Typography>
                </MenuHandler>
                <MenuList className="hidden w-[36rem] grid-cols-7 gap-3 overflow-visible lg:grid">
                    <Card
                        color="yellow"
                        shadow={false}
                        variant="gradient"
                        className="col-span-3 grid h-full w-full place-items-center rounded-md">
                        {dropDownIcon}
                    </Card>
                    <ul className="col-span-4 flex w-full flex-col gap-1">{renderItems}</ul>
                </MenuList>
            </Menu>
            <MenuItem className="flex items-center gap-2 text-blue-gray-900 lg:hidden">{title}</MenuItem>
            <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden">{renderItems}</ul>
        </React.Fragment>
    );
}

// nav list component
const navListItems = [
    {
        label: "Activity Bank",
        link: "/member/activities",
    },
    {
        label: "Videos",
        link: "/member/videos",
    },
    {
        label: "Guides",
        link: "/member/guides",
    },
];

const mobileNavListItems = [
    {
        label: "Weekly Downloads",
        link: `/member/downloads/${weeklyIdentifier}`,
    },
    {
        label: "Future Downloads",
        link: "/member/downloads/future",
    },
    {
        label: "Sensory Sparkles",
        link: `/member/sensorySparkles`,
    },
    {
        label: "Large Font Sparkles",
        link: `/member/largeFontSparkles`,
    },
    {
        label: "My Requests",
        link: "/member/articles/request/overview",
    },
    {
        label: "Request Articles",
        link: "/member/articles/request",
    },
    {
        label: "Request Quizzes",
        link: "/member/quizzes/request",
    },
    {
        label: "Activity Bank",
        link: "/member/activities",
    },
    {
        label: "Videos",
        link: "/member/videos",
    },
    {
        label: "Guides",
        link: "/member/guides",
    },
];

function NavList() {
    return (
        <ul className="mb-4 mt-2 flex flex-col gap-3 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-8">
            <DSDropdownMenu
                dropDownIcon={
                    <NewspaperIcon
                        color="white"
                        strokeWidth={1}
                        className="h-28 w-28"
                    />
                }
                key={"Newspapers"}
                title={"Newspapers"}
                navListMenuItems={newspaperNavListMenuItems}
            />
            <DSDropdownMenu
                dropDownIcon={
                    <QuestionMarkCircleIcon
                        color="white"
                        strokeWidth={1}
                        className="h-28 w-28"
                    />
                }
                key={"Request-A"}
                title={"Request-A"}
                navListMenuItems={requestNavListMenuItems}
            />
            {navListItems.map(({label, link}, key) => (
                <Link to={link}>
                    <Typography
                        key={label}
                        variant="small"
                        color="blue-gray"
                        className="font-normal">
                        <MenuItem className="flex items-center gap-2 lg:rounded-full">{label}</MenuItem>
                    </Typography>
                </Link>
            ))}
        </ul>
    );
}

function MobileNavList() {
    return (
        <ul className="mb-4 mt-2 flex flex-col gap-3 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-8">
            {mobileNavListItems.map(({label, link}, key) => (
                <Link to={link}>
                    <Typography
                        key={label}
                        variant="small"
                        color="blue-gray"
                        className="font-normal">
                        <MenuItem className="flex items-center gap-2 lg:rounded-full">{label}</MenuItem>
                    </Typography>
                </Link>
            ))}
        </ul>
    );
}

export function ComplexNavbar() {
    const [isNavOpen, setIsNavOpen] = React.useState(false);

    const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);

    React.useEffect(() => {
        window.addEventListener("resize", () => window.innerWidth >= 960 && setIsNavOpen(false));
    }, []);

    return (
        <Navbar
            blurred={false}
            fullWidth={true}
            shadow={false}
            className="mx-auto w-full p-2 border-b-2 border-yellow-500">
            <div className="relative mx-auto flex items-center text-blue-gray-900">
                <Link to="/member/landing">
                    <Typography className="mr-4 ml-2 cursor-pointer py-1.5 font-medium">Daily Sparkle</Typography>
                </Link>
                <div className="absolute top-2/4 left-2/4 hidden -translate-x-2/4 -translate-y-2/4 lg:block">
                    <NavList />
                </div>
                <IconButton
                    size="sm"
                    color="blue-gray"
                    variant="text"
                    onClick={toggleIsNavOpen}
                    className="ml-auto mr-2 lg:hidden">
                    <Bars2Icon className="h-6 w-6" />
                </IconButton>
                <ProfileMenu />
            </div>
            <Collapse
                open={isNavOpen}
                className="overflow-scroll">
                <MobileNavList />
            </Collapse>
        </Navbar>
    );
}
