import React, {useEffect, useState} from "react";
import {useSnackbar} from "providers/SnackbarContext";
import {useLoading} from "providers/LoadingContext";
import {LoadingComponent} from "components/LoadingComponent";
import {SnackbarComponent} from "components/SnackbarComponent";
import dayjs from "dayjs";
import {ref as storageRef, getStorage, getDownloadURL} from "firebase/storage";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {Card, CardBody, Typography, Button, Chip, CardFooter} from "@material-tailwind/react";
import {ArrowLongRightIcon} from "@heroicons/react/24/outline";
import {getWeeklyDownloads} from "services";

var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);

function WeeklyDownloadCard({key, tags, title, desc}) {
    return (
        <Card
            key={key}
            className="border-2 border-yellow-500">
            <CardBody>
                <div className="col-span-5">
                    <Typography
                        variant="h4"
                        className="normal-case">
                        {title}
                    </Typography>
                </div>
                <Typography className="mb-6 font-normal !text-gray-500 line-clamp-6">{desc}</Typography>
                <div className="flex gap-2">
                    {tags.map((tag) => (
                        <Chip
                            color="yellow"
                            key={tag}
                            value={tag}
                        />
                    ))}
                </div>
            </CardBody>
            <CardFooter className="pt-0">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex justify-end items-center gap-2 hover:text-yellow-700">
                    Read Now
                    <ArrowLongRightIcon
                        strokeWidth={2}
                        className="w-4 h-4"
                    />
                </Typography>
            </CardFooter>
        </Card>
    );
}

export default function WeeklyDownloadsView() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const {loading, setLoading} = useLoading();
    const {openSnackbar, showAlert} = useSnackbar();
    const [contents, setContents] = useState();
    const weeklyIdentifier = params.weeklyIdentifier;

    useEffect(() => {
        setLoading(true);
        getWeeklyDownloads(weeklyIdentifier)
            .then((res) => {
                console.info(JSON.stringify(res));
                setContents(res);
            })
            .catch((error) => {
                showAlert(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [weeklyIdentifier, setLoading, showAlert]);

    const onClickDownload = async () => {
        const storage = getStorage();
        setLoading(true);
        getDownloadURL(storageRef(storage, `weeklyDownloads/${weeklyIdentifier}.zip`))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = "document";
                window.location.href = url;
                showAlert("Successfully downloaded");
            })
            .catch((error) => {
                showAlert("Something went wrong. Please try again");
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <>
            {loading && <LoadingComponent />}
            {searchParams.get("preview") && (
                <div class="flex items-stretch flex-wrap p-4 mb-4 gap-4 text-center justify-center">
                    <Typography
                        variant="h1"
                        color="red">
                        THIS IS A PREVIEW
                    </Typography>
                </div>
            )}

            <section className="py-8 px-8">
                <div className="container mx-auto mb-8 text-center">
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4">
                        Weekly Downloads
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 py-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        With Daily Sparkle, you have the ability to download newspapers and carer notes, ensuring you
                        stay informed and equipped to provide the best care possible. We understand the importance of
                        staying up-to-date with the latest news and trends, as well as having access to valuable
                        resources that assist you in your caregiving role.
                    </Typography>
                    <Typography
                        variant="lead"
                        className="mx-auto w-full px-4 !text-gray-500 lg:w-10/12 lg:px-8">
                        By downloading newspapers, you can immerse yourself in a wealth of knowledge from around the
                        world. Stay informed about current events, health advancements, lifestyle tips, and more.
                        Enhance your understanding of the world and discover new insights that can positively impact
                        your caregiving approach.
                    </Typography>
                </div>
                <div className="container mx-auto grid grid-cols-1 items-start gap-y-4 gap-x-4 px-4 pt-10 pb-16 md:grid-cols-2 lg:grid-cols-3">
                    {contents &&
                        contents.map((content) => (
                            <Link
                                to={
                                    searchParams.get("preview")
                                        ? `/admin/newspapers/${content.identifier}?preview=true`
                                        : `/member/newspapers/${content.identifier}`
                                }>
                                <WeeklyDownloadCard
                                    key={content.identifier}
                                    tags={content.tags}
                                    title={content.name}
                                    desc={content.blurb}
                                />
                            </Link>
                        ))}
                </div>
                <div className="w-full text-center px-8">
                    <Button
                        onClick={() => onClickDownload()}
                        color="yellow"
                        fullWidth
                        size="lg">
                        {" "}
                        Download All
                    </Button>
                </div>
            </section>
            {openSnackbar && <SnackbarComponent />}
        </>
    );
}
