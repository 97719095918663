import {Typography} from "@material-tailwind/react";
import React from "react";
import {Link} from "react-router-dom";

export default function SiderbarItem({link, title}) {
    return (
        <>
            <li className="inline-flex">
                <Link to={link}>
                    <Typography
                        variant="small"
                        className={`py-3 uppercase ${
                            window.location.pathname.startsWith(link)
                                ? "text-yellow-600 hover:text-yellow-700 font-bold"
                                : ""
                        }`}>
                        {title}
                    </Typography>
                </Link>
            </li>
        </>
    );
}
