import {SYSTEM_ERROR} from "../config/CONSTANTS";
import {getDatabase, ref, get, child} from "firebase/database";
import {ARTICLES_URL} from "./CONSTANTS";

export const getAllArticles = () => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${ARTICLES_URL}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    const keys = Object.keys(response);
                    const retrievedArticles = keys.map((key) => {
                        const article = response[key];
                        const contentObject = JSON.parse(article.content);
                        article["contentObject"] = contentObject;
                        article["displayableTags"] = Object.keys(article.tags);
                        return article;
                    });
                    resolve(retrievedArticles);
                } else {
                    reject("No articles retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getArticle = (userId, identifier) => {
    const database = getDatabase();
    const dbRef = ref(database);
    return new Promise((resolve, reject) => {
        try {
            get(child(dbRef, `${ARTICLES_URL}/${userId}/${identifier}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    const contentObject = JSON.parse(response.content);
                    response["contentObject"] = contentObject;

                    resolve(response);
                } else {
                    reject("No large font sparkles retrieved. Please try again.");
                }
            });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
